import { Container } from 'unstated';
import storage from 'local-storage-fallback';

const DEFAULT_PAGE_TITLE = 'Mavitra Administration Portal';

export default class AppContainer extends Container {
    state = {
        accessToken: storage.getItem('AccessToken'),
        refreshToken: storage.getItem('RefreshToken'),
        pageTitle: DEFAULT_PAGE_TITLE
    };

    hasToken = () => Boolean(this.state.accessToken);

    getAccessToken() { return this.state.accessToken; }

    signIn = (accessToken, refreshToken) => {
        storage.setItem('AccessToken', accessToken);
        storage.setItem('RefreshToken', refreshToken);

        return this.setState({ accessToken, refreshToken });
    };

    signOut = () => {
        storage.removeItem('AccessToken');
        storage.removeItem('RefreshToken');

        return this.setState({ accessToken: null, refreshToken: null });
    };

    setPageTitle(title) {
        this.setState({ pageTitle: title || DEFAULT_PAGE_TITLE });
    }
}