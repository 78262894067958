import { Container } from 'unstated';

export default class RetailerContainer extends Container {
    constructor(retailerService, storeService) {
        super();

        this.retailerService = retailerService;
        this.storeService = storeService;
    }

    state = {
        retailer: null,
        addresses: [],
        stores: [],
        users: []
    };

    async load(retailerId) {
        const response = await this.retailerService.getById(retailerId);

        if (response.ok) {
            this.setState({ retailer: response.data },
                async () => await Promise.all([
                    this.loadAddresses(),
                    this.loadStores(),
                    this.loadUsers()
                ]));
        }

        return response.ok;
    }

    async loadAddresses() {
        const { retailer } = this.state;
        const response = await this.retailerService.getAddresses(retailer.id);

        if (response.ok) {
            this.setState({ addresses: response.data });
        }

        return response.ok;
    }

    async loadStores() {
        const { retailer } = this.state;
        const response = await this.storeService.getByRetailerId(retailer.id);

        if (response.ok) {
            this.setState({ stores: response.data });
        }

        return response.ok;
    }

    async loadUsers() {
        const { retailer } = this.state;
        const response = await this.retailerService.getUsers(retailer.id);

        if (response.ok) {
            this.setState({ users: response.data });
        }

        return response.ok;
    }

    async update(changes) {
        const { retailer } = this.state;
        const response = await this.retailerService.update(retailer.id, {
            ...changes,
            timestamp: retailer.timestamp
        });

        if (response.ok) {
            this.setState({ retailer: response.data });
        }

        return response.ok;
    }

    async createAddress(retailerAddress) {
        const { retailer } = this.state;
        const response = await this.retailerService.createAddress(retailer.id, retailerAddress);

        if (response.ok) {
            await this.loadAddresses();
        }

        return response.ok;
    }

    async updateAddress(addressId, changes) {
        const { retailer } = this.state;
        const addresses = this.state.addresses.slice();
        const address = addresses.find(a => a.id === addressId);

        const response = await this.retailerService.updateAddress(retailer.id,
            addressId, {
                ...changes,
                timestamp: address.timestamp
            });

        if (response.ok) {
            // Replace old address object with new one.
            const index = addresses.indexOf(address);
            addresses.splice(index, 1, response.data);

            this.setState({ addresses });
        }

        return response.ok;
    }

    async deleteAddress(addressId) {
        const { retailer } = this.state;
        const response = await this.retailerService.deleteAddress(retailer.id, addressId);

        if (response.ok) {
            const addresses = this.state.addresses.slice();
            const index = addresses.findIndex(a => a.id === addressId);

            addresses.splice(index, 1);

            this.setState({ addresses });
        }
    }

    async createStore(store) {
        const { retailer } = this.state;

        const response = await this.storeService.create({
            retailerId: retailer.id,
            ...store
        });

        // if (response.ok) {
        //     await this.loadStores();
        // }

        return response;
    }

    async deleteStore(storeId) {
        const response = await this.storeService.delete(storeId);

        if (response.ok) {
            const stores = this.state.stores.slice();
            const index = stores.findIndex(s => s.id === storeId);

            stores.splice(index, 1);

            this.setState({ stores });
        }
    }

    async createUser(user) {
        const { retailer } = this.state;
        const response = await this.retailerService.createUser(retailer.id, user);

        if (response.ok) {
            await this.loadUsers();
        }

        return response.ok;
    }

    async updateUser(userId, changes) {
        const { retailer } = this.state;
        const users = this.state.users.slice();
        const user = users.find(u => u.id === userId);

        const response = await this.retailerService.updateUser(retailer.id,
            userId, {
                ...changes,
                timestamp: user.timestamp
            });

        if (response.ok) {
            // Replace old user object with new one.
            const index = users.indexOf(user);
            users.splice(index, 1, response.data);

            this.setState({ users });
        }

        return response.ok;
    }

    async deleteUser(userId) {
        const { retailer } = this.state;
        const response = await this.retailerService.deleteUser(retailer.id, userId);

        if (response.ok) {
            const users = this.state.users.slice();
            const index = users.findIndex(u => u.id === userId);

            users.splice(index, 1);

            this.setState({ users });
        }
    }
}