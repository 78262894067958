import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

const styles = theme => ({
    root: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        height: '48px'
    },
    input: {
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    }
});

class SearchInput extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        onSearch: PropTypes.func.isRequired,
        onClear: PropTypes.func
    };

    static defaultProps = {
        autoFocus: true,
        placeholder: '',
        value: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            hasSearchValue: Boolean(props.value)
        };
    }

    clear() {
        this.setState({
            value: '',
            hasSearchValue: false
        }, () => this.input.focus());

        if (this.props.onClear) {
            this.props.onClear();
        }
    }

    submit(event) {
        event.preventDefault();
        event.stopPropagation();

        this.search();
    }

    search() {
        const { onSearch } = this.props;
        const { value } = this.state;

        this.setState({
            hasSearchValue: Boolean(value)
        });

        onSearch(value);
    }

    onKeyDown(keyCode) {
        // Escape key clears input.
        if (keyCode === 27) {
            this.clear();
        }
    }

    render() {
        const { value, hasSearchValue } = this.state;
        const { classes, autoFocus, placeholder } = this.props;

        const startAdornment = hasSearchValue ?
            <InputAdornment position="start">
                <IconButton onClick={() => this.clear()}>
                    <Clear />
                </IconButton>
            </InputAdornment> :
            null;

        const endAdornment = <InputAdornment position="end">
            <IconButton onClick={() => this.search()}>
                <Search />
            </IconButton>
        </InputAdornment>;

        return <form className={classes.root} onSubmit={e => this.submit(e)}>
            <FormControl fullWidth>
                <InputBase id="search"
                    classes={{
                        input: classes.input
                    }}
                    inputProps={{
                        autoComplete: "off",
                        ref: e => this.input = e
                    }}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment}
                    value={value}
                    onChange={e => this.setState({ value: e.target.value })}
                    onKeyDown={e => this.onKeyDown(e.keyCode)} />
            </FormControl>
        </form>;
    }
}

export default withStyles(styles)(SearchInput);