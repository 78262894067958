import ApiService from './ApiService';
import axios from 'axios';
import queryString from 'query-string';

export default class ProductService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async getAll(query, pageNumber, pageSize, sortBy) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products`;

        const request = {
            query,
            pageNumber,
            pageSize,
            sortBy
        };

        const response = await axios.get(url + '?' + queryString.stringify(request), { headers });

        return {
            ok: response.status === 200,
            data: response.data,
            totalCount: parseInt(response.headers['x-total-count'], 10)
        };
    }

    async getByStoreId(storeId, pageNumber, pageSize, sortBy) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/products`;

        const query = {
            pageNumber,
            pageSize,
            sortBy
        };

        const response = await axios.get(url + '?' + queryString.stringify(query), { headers });

        return {
            ok: response.status === 200,
            data: response.data,
            totalCount: parseInt(response.headers['x-total-count'], 10)
        };
    }

    async getById(id) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${id}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async create(product) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products`;
        const response = await axios.post(url, product, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async update(productId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async delete(productId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getImages(productId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/images`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getImageById(productId, imageId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/images/${imageId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async addImage(productId, image) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/images`;
        const response = await axios.post(url, image, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateImage(productId, imageId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/images/${imageId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async removeImage(productId, imageId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/images/${imageId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getVariants(productId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getVariantById(productId, variantId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants/${variantId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async createVariant(productId, variant) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants`;
        const response = await axios.post(url, variant, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    // TODO: Remove
    // async updateVariant(productId, variantId, changes) {
    //     const headers = this.getRequestHeaders(this.getAccessToken());
    //     const url = `${this.apiBaseUrl}/products/${productId}/variants/${variantId}`;
    //     const response = await axios.patch(url, changes, { headers });

    //     return {
    //         ok: response.status === 200,
    //         data: response.data
    //     };
    // }

    async deleteVariant(productId, variantId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants/${variantId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }
}