import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '../../components/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import BusyButton from '../../components/BusyButton';
import Tags from '../../components/Tags';
import ImageSelect from '../../components/ImageSelect';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors } from '../../helpers';
import { actionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    row: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            '&:last-child': {
                marginLeft: theme.spacing(1)
            },
            '&:not(:last-child)': {
                marginRight: theme.spacing(1)
            }
        }
    },
    listHeading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500
    },
    listOption: {
        paddingLeft: theme.spacing(4)
    },
    ...actionsStyle(theme)
});

class UpdateStoreForm extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        blobService: PropTypes.object.isRequired,
        shoppingLocationService: PropTypes.object.isRequired,
        storeTypes: PropTypes.array.isRequired,
        industryAreas: PropTypes.array.isRequired,
        currencies: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired,
        onCopy: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.getShoppingLocationSuggestions = this.getShoppingLocationSuggestions.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.onAddTag = this.onAddTag.bind(this);
        this.onDeleteTag = this.onDeleteTag.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCopyStore = this.onCopyStore.bind(this);

        const { name,
            locationalName,
            storeType,
            industrySector,
            currency,
            description,
            shoppingLocation,
            phoneNumber,
            websiteUrl,
            tags,
            logoImageName,
            logoImageUrl,
            heroImageName,
            heroImageUrl,
            externalImagesVisible,
            isActive,
            isVisibleToPublic
        } = props.store;

        const { id: storeTypeId } = storeType;
        const { id: industrySectorId } = industrySector;
        const { id: currencyId } = currency;

        this.state = {
            name,
            locationalName,
            storeTypeId,
            industrySectorId,
            currencyId,
            description,
            shoppingLocationId: shoppingLocation != null ? shoppingLocation.id : null,
            phoneNumber,
            websiteUrl,
            tags,
            logoImageName,
            logoImageUrl,
            heroImageName,
            heroImageUrl,
            externalImagesVisible,
            isActive,
            isVisibleToPublic,
            errors: {},
            saving: false,
            copying: false
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onAutocompleteSelect = name => option => {
        this.setState({
            [name]: option != null ? option.value : null
        });
    }

    onImageChange = name => async file => {
        if (file != null) {
            const { blobName, blobUrl } = await this.props.blobService.upload('images', file);

            this.setState({
                [name + 'ImageName']: blobName,
                [name + 'ImageUrl']: blobUrl
            });
        }
        else {
            this.setState({
                [name + 'ImageName']: null,
                [name + 'ImageUrl']: null
            });
        }
    }

    onCheckedChange = name => event => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    onAddTag(tag) {
        this.setState(prevState => ({
            tags: prevState.tags.concat(tag)
        }));
    };

    onDeleteTag(tag) {
        this.setState(prevState => {
            const tags = prevState.tags.slice();
            const index = tags.indexOf(tag);

            tags.splice(index, 1);

            return { tags };
        });
    };

    async getShoppingLocationSuggestions(value) {
        const response = await this.props.shoppingLocationService.getSuggestions(value);

        if (response.ok) {
            return response.data.map(s => ({
                label: s.name,
                value: s.id
            }));
        }

        return [];
    }

    async onCopyStore(event) {
        event.preventDefault();
        event.stopPropagation();

        const confirmed = window.confirm(`Create new store based on ${this.props.store.name}?`);

        if (confirmed) {
            this.setState({ copying: true });

            await this.props.onCopy();

            if (!this.unmounted) {
                this.setState({ copying: false });
            }
        }
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;

        const {
            name,
            locationalName,
            storeTypeId,
            industrySectorId,
            currencyId,
            description,
            shoppingLocationId,
            phoneNumber,
            websiteUrl,
            tags,
            logoImageName,
            heroImageName,
            externalImagesVisible,
            isActive,
            isVisibleToPublic
        } = this.state;

        try {
            this.setState({ saving: true });

            await onSave({
                name,
                locationalName,
                storeTypeId,
                industrySectorId,
                currencyId,
                description,
                shoppingLocationId,
                phoneNumber,
                websiteUrl,
                tags,
                logoImageName,
                heroImageName,
                externalImagesVisible,
                isActive,
                isVisibleToPublic
            });

            this.setState({ errors: {} });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({ errors });
            } else {
                throw error;
            }
        }
        finally {
            this.setState({ saving: false });
        }
    }

    render() {
        const { classes, store, storeTypes, industryAreas, currencies } = this.props;
        const { name, locationalName, storeTypeId, industrySectorId, currencyId, description, phoneNumber, websiteUrl, tags, logoImageUrl, heroImageUrl, externalImagesVisible, isActive, isVisibleToPublic, errors, saving, copying } = this.state;
        const { shoppingLocation } = store;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <div className={classes.row}>
                <div className={classes.column}>
                    <FormControl margin="normal">
                        <FormControlLabel control={
                            <Switch color="primary"
                                checked={isActive}
                                onChange={this.onCheckedChange('isActive')} />}
                            label="Is active" />
                        {!isActive && <FormHelperText error>This store is INACTIVE and cannot be accessed publicly.</FormHelperText>}
                    </FormControl>

                    <TextField
                        disabled
                        label="Retailer"
                        margin="normal"
                        value={store.retailer.name} />

                    <TextField
                        autoFocus
                        id="name"
                        label="Name"
                        required
                        margin="normal"
                        value={name}
                        helperText={errors.name}
                        error={Boolean(errors.name)}
                        onChange={this.onChange('name')} />

                    <TextField
                        id="locationalName"
                        label="Locational name"
                        margin="normal"
                        value={locationalName || ''}
                        helperText={errors.locationalName}
                        error={Boolean(errors.locationalName)}
                        onChange={this.onChange('locationalName')} />

                    <TextField
                        id="storeTypeId"
                        label="Store type"
                        select
                        required
                        margin="normal"
                        value={storeTypeId}
                        onChange={this.onChange('storeTypeId')}>
                        {storeTypes.map(rt => <MenuItem key={rt.id} value={rt.id}>{rt.name}</MenuItem>)}
                    </TextField>

                    <TextField
                        id="industrySectorId"
                        label="Industry sector"
                        select
                        required
                        margin="normal"
                        value={industrySectorId}
                        onChange={this.onChange('industrySectorId')}>
                        {industryAreas.map(ia =>
                            [<MenuItem key={ia.id} value={ia.id} className={classes.listHeading} disabled>{ia.name}</MenuItem>].concat(ia.sectors.map(is =>
                                <MenuItem key={is.id} value={is.id} className={classes.listOption}>{is.name}</MenuItem>)))}
                    </TextField>

                    <TextField
                        id="currencyId"
                        label="Currency"
                        select
                        required
                        margin="normal"
                        value={currencyId}
                        onChange={this.onChange('currencyId')}>
                        {currencies.map(c => <MenuItem key={c.id} value={c.id}>{c.description} ({c.name})</MenuItem>)}
                    </TextField>

                    <TextField
                        id="description"
                        label="Description"
                        margin="normal"
                        multiline
                        rows={5}
                        value={description}
                        helperText={errors.description || ''}
                        error={Boolean(errors.description)}
                        onChange={this.onChange('description')} />

                    <Autocomplete
                        label="Shopping location"
                        initialValue={shoppingLocation != null ? shoppingLocation.name : ''}
                        margin="normal"
                        placeholder="Search for a shopping location..."
                        getSuggestions={this.getShoppingLocationSuggestions}
                        onSelect={this.onAutocompleteSelect('shoppingLocationId')} />

                    <TextField
                        id="phoneNumber"
                        label="Phone number"
                        margin="normal"
                        type="tel"
                        value={phoneNumber || ''}
                        helperText={errors.phoneNumber}
                        error={Boolean(errors.phoneNumber)}
                        onChange={this.onChange('phoneNumber')} />

                    <TextField
                        id="websiteUrl"
                        label="Website"
                        margin="normal"
                        value={websiteUrl || ''}
                        helperText={errors.websiteUrl}
                        error={Boolean(errors.websiteUrl)}
                        onChange={this.onChange('websiteUrl')} />

                    <Tags id="tags"
                        tags={tags}
                        margin="normal"
                        onAdd={this.onAddTag}
                        onDelete={this.onDeleteTag} />

                    <FormControl margin="normal">
                        <FormControlLabel control={
                            <Switch color="primary"
                                checked={isVisibleToPublic}
                                onChange={this.onCheckedChange('isVisibleToPublic')} />}
                            label="Show in search results" />
                        <FormHelperText>This store will {!isVisibleToPublic && 'NOT'} appear in search results.</FormHelperText>
                    </FormControl>
                </div>
                <div className={classes.column}>
                    <ImageSelect id="logoFile"
                        label="Logo image"
                        height={250}
                        margin="normal"
                        src={logoImageUrl}
                        onChange={this.onImageChange('logo')} />

                    <ImageSelect id="heroFile"
                        label="Hero image"
                        height={250}
                        margin="normal"
                        src={heroImageUrl}
                        onChange={this.onImageChange('hero')} />
                        
                    <FormControl margin="normal">
                        <FormControlLabel control={
                            <Switch color="primary"
                                checked={externalImagesVisible}
                                onChange={this.onCheckedChange('externalImagesVisible')} />}
                            label="Show external images" />
                        <FormHelperText>External images will {!externalImagesVisible && 'NOT'} be shown in the store's image gallery.</FormHelperText>
                    </FormControl>
                </div>
            </div>

            <div className={classes.actions}>
                <BusyButton busy={saving} variant="contained" color="primary" type="submit">Save</BusyButton>
                <BusyButton busy={copying} variant="contained" onClick={this.onCopyStore}>Copy store</BusyButton>
            </div>
        </form>;
    }
}

export default withStyles(styles)(UpdateStoreForm);