import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import StoresContainer from '../../containers/StoresContainer';
import { Subscribe } from 'unstated';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { inactiveStyle } from '../../styles';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    item: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    loadMore: {
        alignSelf: 'stretch',
        margin: theme.spacing(1)
    },
    ...inactiveStyle(theme)
});

class StoreList extends Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { classes, onSelect, onDelete } = this.props;

        return <div className={classes.root}>
            <Subscribe to={[StoresContainer]}>
                {rc => <Fragment>
                    <List>
                        {rc.state.list.map(r =>
                            <ListItem key={r.id} button divider className={classNames({ [classes.inactive]: !r.isActive })} classes={{ container: classes.item }} onClick={() => onSelect(r)}>
                                <ListItemText primary={r.locationalName || r.name} secondary={r.description} />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => onDelete(r)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                    {rc.hasMore() && <Button color="secondary" className={classes.loadMore} onClick={() => rc.loadMore()}>Show me more</Button>}
                </Fragment>}
            </Subscribe>
        </div>;
    }
}

export default withStyles(styles)(StoreList);