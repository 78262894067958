import { Container } from 'unstated';

const defaultOptions = {
    query: null,
    pageNumber: 1,
    pageSize: 25,
    sortBy: 'name'
};

export default class ListContainer extends Container {
    constructor(service, options) {
        super();

        this.service = service;

        const actualOptions = {
            ...defaultOptions,
            ...options
        };

        this.state = {
            ...actualOptions,
            list: [],
            totalCount: 0
        };
    }

    hasMore() {
        const { list, totalCount } = this.state;
        return list.length < totalCount;
    }

    async load() {
        const { pageNumber, pageSize, sortBy } = this.state;
        await this.loadPage(pageNumber, pageSize, sortBy);
    }

    async loadPage(pageNumber, pageSize, sortBy = this.state.sortBy) {
        const { query } = this.state;
        const response = await this.service.getAll(query, pageNumber, pageSize, sortBy);
        const { data: list, totalCount } = response;

        this.setState({ list, pageNumber, pageSize, totalCount });
    }

    async loadMore() {
        const { query, pageNumber, pageSize, sortBy } = this.state;
        const response = await this.service.getAll(query, pageNumber + 1, pageSize, sortBy);
        const { data } = response;

        this.setState(prevState => ({
            list: prevState.list.concat(data),
            pageNumber: pageNumber + 1
        }));
    }

    async create(value, reload) {
        const response = await this.service.create(value);

        if (response.ok) {
            if (reload) {
                await this.load();
            }

            // TODO: Don't add new item to list as it can cause an exception if the same item
            // is later loaded by clicking "Show me more" as the list will contain a duplicate key.

            // } else {
            //     this.setState(prevState => ({
            //         list: prevState.list.concat([response.data]),
            //         totalCount: prevState.totalCount + 1
            //     }));
            // }
        }

        return response;
    }

    async update(item, changes) {
        const response = await this.service.update(item.id, {
            ...changes,
            timestamp: item.timestamp
        });

        if (response.ok) {
            const list = this.state.list.slice();
            const index = list.indexOf(item);

            list.splice(index, 1, response.data);

            this.setState({ list });
        }

        return response.ok;
    }

    async delete(id) {
        const response = await this.service.delete(id);

        if (response.ok) {
            const list = this.state.list.slice();
            const index = list.findIndex(e => e.id === id);

            list.splice(index, 1);

            this.setState(prevState => ({
                list,
                totalCount: prevState.totalCount - 1
            }));
        }
    }
}