import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Provider, Subscribe } from 'unstated';
import StoreContainer from '../../containers/StoreContainer';
import StoreProductsContainer from '../../containers/StoreProductsContainer';
import LookupsContainer from '../../containers/LookupsContainer';
import Paper from '@material-ui/core/Paper';
import UpdateStoreForm from './UpdateStoreForm';
import CreateAddressForm from './CreateAddressForm';
import UpdateAddressForm from './UpdateAddressForm';
import CreateTradingPeriodForm from './CreateTradingPeriodForm';
import UpdateTradingPeriodForm from './UpdateTradingPeriodForm';
import AddFeatureForm from './AddFeatureForm';
import UpdateFeatureForm from './UpdateFeatureForm';
import UpdateBrandsForm from './UpdateBrandsForm';
import AddCategoryForm from './AddCategoryForm';
import CreateProductForm from './CreateProductForm';
import AddImageForm from './AddImageForm';
import UpdateImageForm from './UpdateImageForm';
import AddressTable from './AddressTable';
import AddressList from './AddressList';
import BrandTable from './BrandTable';
import BrandList from './BrandList';
import CategoryTable from './CategoryTable';
import CategoryList from './CategoryList';
import TradingPeriodTable from './TradingPeriodTable';
import TradingPeriodList from './TradingPeriodList';
import FeatureTable from './FeatureTable';
import FeatureList from './FeatureList';
import ProductTable from './ProductTable';
import ProductList from './ProductList';
import ImageGrid from '../../components/ImageGrid';
import SideDrawer from '../../components/SideDrawer';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { breadcrumbStyle, fabStyle, pageTitleStyle, tabsStyle } from '../../styles';
import { withRouter } from 'react-router-dom';
import { paths } from '../../App';
import { format } from 'date-fns/esm';
import { API_DATE_FORMAT } from '../../constants';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 0
    },
    detailsTab: {
        padding: theme.spacing(2)
    },
    addressesTab: {
        flexGrow: 1,
        position: 'relative'
    },
    hoursTab: {
        flexGrow: 1,
        position: 'relative'
    },
    featuresTab: {
        flexGrow: 1,
        position: 'relative'
    },
    brandsTab: {
        flexGrow: 1,
        position: 'relative'
    },
    categoriesTab: {
        flexGrow: 1,
        position: 'relative'
    },
    productsTab: {
        flexGrow: 1,
        position: 'relative'
    },
    imagesTab: {
        padding: theme.spacing(2),
        flexGrow: 1,
        position: 'relative'
    },
    ...breadcrumbStyle(theme),
    ...fabStyle(theme, theme.spacing(2)),
    ...pageTitleStyle(theme),
    ...tabsStyle(theme)
});

class Store extends Component {
    static propTypes = {
        storeId: PropTypes.string.isRequired,
        blobService: PropTypes.object.isRequired,
        storeService: PropTypes.object.isRequired,
        addressUsageService: PropTypes.object.isRequired,
        storeTypeService: PropTypes.object.isRequired,
        shoppingLocationService: PropTypes.object.isRequired,
        storeFeatureTypeService: PropTypes.object.isRequired,
        tradingPeriodTypeService: PropTypes.object.isRequired,
        industryAreaService: PropTypes.object.isRequired,
        currencyService: PropTypes.object.isRequired,
        brandService: PropTypes.object.isRequired,
        categoryService: PropTypes.object.isRequired,
        productService: PropTypes.object.isRequired,
        stockStatusService: PropTypes.object.isRequired,
        tab: PropTypes.string
    };

    static defaultProps = {
        tab: 'details'
    };

    tabs = {
        'details': 'Details',
        'addresses': 'Addresses',
        'hours': 'Hours',
        'features': 'Features',
        'brands': 'Brands',
        'categories': 'Categories',
        'products': 'Products',
        'images': 'Images'
    };

    constructor(props) {
        super(props);

        const notFound = Object.keys(this.tabs).indexOf(props.tab) < 0;

        this.storeContainer = new StoreContainer(props.storeService);
        this.storeProductsContainer = new StoreProductsContainer(props.storeId, props.productService);
        this.lookupsContainer = new LookupsContainer(props);

        this.state = {
            drawerTitle: '',
            drawerContent: null,
            notification: null,
            notFound
        };

        this.onTabChange = this.onTabChange.bind(this);
        this.openCreateAddressDrawer = this.openCreateAddressDrawer.bind(this);
        this.openCreateTradingPeriodDrawer = this.openCreateTradingPeriodDrawer.bind(this);
        this.openAddFeatureDrawer = this.openAddFeatureDrawer.bind(this);
        this.openUpdateBrandsDrawer = this.openUpdateBrandsDrawer.bind(this);
        this.openAddCategoryDrawer = this.openAddCategoryDrawer.bind(this);
        this.openAddImageDrawer = this.openAddImageDrawer.bind(this);
        this.openCreateProductDrawer = this.openCreateProductDrawer.bind(this);
        this.onSelectProduct = this.onSelectProduct.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.hideNotification = this.hideNotification.bind(this);
    }

    async componentDidMount() {
        const { storeId } = this.props;

        await Promise.all([
            this.storeContainer.load(storeId),
            this.storeProductsContainer.load(),
            this.lookupsContainer.loadAddressUsages(),
            this.lookupsContainer.loadCurrencies(),
            this.lookupsContainer.loadIndustryAreas(),
            this.lookupsContainer.loadStoreTypes(),
            this.lookupsContainer.loadTradingPeriodTypes(),
            this.lookupsContainer.loadStockStatuses(),
            this.lookupsContainer.loadStoreFeatureTypes()
        ]);

        this.setState({ ready: true });
    }

    async updateStore(form) {
        const ok = await this.storeContainer.update(form);

        if (ok) {
            this.showNotification('Store saved');
        } else {
            this.showNotification('Unable to save! Please try again.');
        }
    }

    async copyStore() {
        const newStoreId = await this.storeContainer.copy();

        if (Boolean(newStoreId)) {
            this.props.history.push(`${paths.STORES}/${newStoreId}`);
        } else {
            this.showNotification('Unable to copy! Please try again.');
        }
    }

    async createAddress(form) {
        const created = await this.storeContainer.createAddress(form);

        if (created) {
            this.closeDrawer();
        }
    }

    async updateAddress(addressId, form) {
        const ok = await this.storeContainer.updateAddress(addressId, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteAddress(address) {
        const confirmed = window.confirm(`Delete address ${address.address.formattedAddress}?`);

        if (confirmed) {
            await this.storeContainer.deleteAddress(address.id);
        }
    }

    convertHoursToApiFormat(hours) {
        const { isOpen, openTime, closeTime } = hours;

        return {
            isOpen,
            openTime: format(openTime, 'HH:mm:00'),
            closeTime: format(closeTime, 'HH:mm:00')
        };
    }

    getTradingPeriodFormPayload(form) {
        // Convert the JS dates and times so the API can understand them.
        return {
            ...form,
            startDate: format(form.startDate, API_DATE_FORMAT),
            endDate: form.endDate != null ? format(form.endDate, API_DATE_FORMAT) : null,
            monday: this.convertHoursToApiFormat(form.monday),
            tuesday: this.convertHoursToApiFormat(form.tuesday),
            wednesday: this.convertHoursToApiFormat(form.wednesday),
            thursday: this.convertHoursToApiFormat(form.thursday),
            friday: this.convertHoursToApiFormat(form.friday),
            saturday: this.convertHoursToApiFormat(form.saturday),
            sunday: this.convertHoursToApiFormat(form.sunday)
        };
    }

    async createTradingPeriod(form) {
        const payload = this.getTradingPeriodFormPayload(form);
        const created = await this.storeContainer.createTradingPeriod(payload);

        if (created) {
            this.closeDrawer();
        }
    }

    async updateTradingPeriod(tradingPeriodId, form) {
        const payload = this.getTradingPeriodFormPayload(form);
        const ok = await this.storeContainer.updateTradingPeriod(tradingPeriodId, payload);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteTradingPeriod(tradingPeriod) {
        const confirmed = window.confirm(`Delete trading period ${tradingPeriod.tradingPeriodType.name}?`);

        if (confirmed) {
            await this.storeContainer.deleteTradingPeriod(tradingPeriod.id);
        }
    }

    async addFeature(form) {
        const created = await this.storeContainer.addFeature(form);

        if (created) {
            this.closeDrawer();
        }
    }

    async updateFeature(featureId, form) {
        const ok = await this.storeContainer.updateFeature(featureId, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async removeFeature(feature) {
        const confirmed = window.confirm(`Remove feature ${feature.storeFeatureType.name}?`);

        if (confirmed) {
            await this.storeContainer.removeFeature(feature.id);
        }
    }

    async updateBrands(form) {
        const ok = await this.storeContainer.update(form);

        if (ok) {
            this.closeDrawer();
        }
    }


    addCategory(form) {
        return this.storeContainer.addCategory(form);
    }

    async removeCategory(category) {
        const confirmed = window.confirm(`Remove category ${category.name}?`);

        if (confirmed) {
            await this.storeContainer.removeCategory(category.id);
        }
    }

    async createProduct(form) {
        const ok = await this.storeProductsContainer.create(form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteProduct(product) {
        const confirmed = window.confirm(`Remove product ${product.name}?`);

        if (confirmed) {
            await this.storeProductsContainer.delete(product.id);
        }
    }


    addImage(form) {
        return this.storeContainer.addImage(form);
    }

    async updateImage(imageId, form) {
        const ok = await this.storeContainer.updateImage(imageId, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async removeImage(image) {
        const confirmed = window.confirm(`Remove image ${image.imageName}?`);

        if (confirmed) {
            await this.storeContainer.removeImage(image.id);
        }
    }

    onTabChange(_, value) {
        this.props.history.push(`/stores/${this.props.storeId}/${value}`);
    }

    openCreateAddressDrawer() {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <CreateAddressForm
                addressUsages={lookups.state.addressUsages}
                onSave={form => this.createAddress(form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Create address', drawerContent);
    }

    openUpdateAddressDrawer(storeAddress) {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <UpdateAddressForm
                storeAddress={storeAddress}
                addressUsages={lookups.state.addressUsages}
                onSave={form => this.updateAddress(storeAddress.id, form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Edit address', drawerContent);
    }

    openCreateTradingPeriodDrawer() {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <CreateTradingPeriodForm
                tradingPeriodTypes={lookups.state.tradingPeriodTypes}
                onSave={form => this.createTradingPeriod(form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Create trading period', drawerContent);
    }

    openUpdateTradingPeriodDrawer(storeTradingPeriod) {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <UpdateTradingPeriodForm
                tradingPeriod={storeTradingPeriod}
                tradingPeriodTypes={lookups.state.tradingPeriodTypes}
                onSave={form => this.updateTradingPeriod(storeTradingPeriod.id, form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Edit trading period', drawerContent);
    }

    openUpdateBrandsDrawer() {
        const drawerContent = <UpdateBrandsForm
            brands={this.storeContainer.state.store.brands}
            brandService={this.props.brandService}
            onSave={form => this.updateBrands(form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit brands', drawerContent);
    }

    openAddCategoryDrawer() {
        const drawerContent = <AddCategoryForm
            categoryService={this.props.categoryService}
            onSave={form => this.addCategory(form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Add category', drawerContent);
    }

    openCreateProductDrawer() {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <CreateProductForm
                storeService={this.props.storeService}
                brandService={this.props.brandService}
                blobService={this.props.blobService}
                stockStatuses={lookups.state.stockStatuses}
                onSave={form => this.createProduct(form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Create product', drawerContent);
    }

    openAddFeatureDrawer() {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <AddFeatureForm
                storeFeatureTypes={lookups.state.storeFeatureTypes}
                onSave={form => this.addFeature(form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Add feature', drawerContent);
    }

    openUpdateFeatureDrawer(storeFeature) {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <UpdateFeatureForm
                storeFeature={storeFeature}
                storeFeatureTypes={lookups.state.storeFeatureTypes}
                onSave={form => this.updateFeature(storeFeature.id, form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Edit feature', drawerContent);
    }

    openAddImageDrawer() {
        const drawerContent = <AddImageForm
            blobService={this.props.blobService}
            onSave={form => this.addImage(form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Add image', drawerContent);
    }

    openUpdateImageDrawer(image) {
        const drawerContent = <UpdateImageForm
            image={image}
            blobService={this.props.blobService}
            onSave={form => this.updateImage(image.id, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit image', drawerContent);
    }

    onSelectProduct(product) {
        this.props.history.push(`/products/${product.id}`);
    }

    openDrawer(drawerTitle, drawerContent) {
        this.setState({
            drawerTitle,
            drawerContent
        });
    }

    closeDrawer() {
        this.setState({
            drawerTitle: '',
            drawerContent: null
        });
    }

    showNotification(notification) {
        this.setState({ notification });
    }

    hideNotification() {
        this.setState({ notification: null });
    }

    renderTabContents(tab) {
        switch (tab) {
            case 'details':
                return this.renderDetailsTab();
            case 'addresses':
                return this.renderAddressesTab();
            case 'hours':
                return this.renderHoursTab();
            case 'features':
                return this.renderFeaturesTab();
            case 'brands':
                return this.renderBrandsTab();
            case 'categories':
                return this.renderCategoriesTab();
            case 'products':
                return this.renderProductsTab();
            case 'images':
                return this.renderImagesTab();
            default:
                return "No content";
        }
    }

    renderDetailsTab() {
        const { classes, blobService, shoppingLocationService } = this.props;

        return <div className={classes.detailsTab}>
            <Subscribe to={[this.lookupsContainer]}>
                {lookups =>
                    <UpdateStoreForm
                        store={this.storeContainer.state.store}
                        storeTypes={lookups.state.storeTypes}
                        industryAreas={lookups.state.industryAreas}
                        currencies={lookups.state.currencies}
                        blobService={blobService}
                        shoppingLocationService={shoppingLocationService}
                        onSave={form => this.updateStore(form)}
                        onCopy={() => this.copyStore()} />}
            </Subscribe>
        </div>;
    }

    renderAddressesTab() {
        const { classes } = this.props;

        return <div className={classes.addressesTab}>
            <Hidden smUp implementation="css">
                <AddressList addresses={this.storeContainer.state.addresses}
                    onSelect={a => this.openUpdateAddressDrawer(a)}
                    onDelete={a => this.deleteAddress(a)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <AddressTable addresses={this.storeContainer.state.addresses}
                    onSelect={a => this.openUpdateAddressDrawer(a)}
                    onDelete={a => this.deleteAddress(a)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateAddressDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderHoursTab() {
        const { classes } = this.props;

        return <div className={`${classes.hoursTab} ${classes.fabContainer}`}>
            <Hidden smUp implementation="css">
                <TradingPeriodList tradingPeriods={this.storeContainer.state.tradingPeriods}
                    onSelect={tp => this.openUpdateTradingPeriodDrawer(tp)}
                    onDelete={tp => this.deleteTradingPeriod(tp)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <TradingPeriodTable tradingPeriods={this.storeContainer.state.tradingPeriods}
                    onSelect={tp => this.openUpdateTradingPeriodDrawer(tp)}
                    onDelete={tp => this.deleteTradingPeriod(tp)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateTradingPeriodDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderFeaturesTab() {
        const { classes } = this.props;

        return <div className={`${classes.featuresTab} ${classes.fabContainer}`}>
            <Hidden smUp implementation="css">
                <FeatureList features={this.storeContainer.state.features}
                    onSelect={f => this.openUpdateFeatureDrawer(f)}
                    onDelete={f => this.removeFeature(f)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <FeatureTable features={this.storeContainer.state.features}
                    onSelect={f => this.openUpdateFeatureDrawer(f)}
                    onDelete={f => this.removeFeature(f)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openAddFeatureDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderBrandsTab() {
        const { classes } = this.props;

        return <div className={`${classes.brandsTab} ${classes.fabContainer}`}>
            <Hidden smUp implementation="css">
                <BrandList brands={this.storeContainer.state.store.brands}
                    onSelect={_ => { }} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <BrandTable brands={this.storeContainer.state.store.brands}
                    onSelect={_ => { }} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openUpdateBrandsDrawer}>
                <EditIcon />
            </Fab>}
        </div>;
    }

    renderCategoriesTab() {
        const { classes } = this.props;

        return <div className={`${classes.categoriesTab} ${classes.fabContainer}`}>
            <Hidden smUp implementation="css">
                <CategoryList categories={this.storeContainer.state.categories}
                    onSelect={_ => { }}
                    onDelete={c => this.removeCategory(c)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <CategoryTable categories={this.storeContainer.state.categories}
                    onSelect={_ => { }}
                    onDelete={c => this.removeCategory(c)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openAddCategoryDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderProductsTab() {
        const { classes } = this.props;

        return <div className={`${classes.productsTab} ${classes.fabContainer}`}>
            <Provider inject={[this.storeProductsContainer]}>
                <Hidden smUp implementation="css">
                    <ProductList onSelect={this.onSelectProduct} onDelete={p => this.deleteProduct(p)} />
                </Hidden>
                <Hidden xsDown implementation="css">
                    <ProductTable onSelect={this.onSelectProduct} onDelete={p => this.deleteProduct(p)} />
                </Hidden>
            </Provider>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateProductDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderImagesTab() {
        const { classes } = this.props;

        return <div className={`${classes.imagesTab} ${classes.fabContainer}`}>
            <ImageGrid
                images={this.storeContainer.state.images}
                onSelect={img => this.openUpdateImageDrawer(img)}
                onDelete={img => this.removeImage(img)} />
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openAddImageDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    render() {
        const { classes, history, width, tab } = this.props;
        const { drawerTitle, drawerContent, notification, notFound } = this.state;
        const isDesktop = isWidthUp('lg', width);

        return <div className={classes.root}>
            {!notFound && <Fragment>
                <Subscribe to={[this.storeContainer]}>
                    {sc => sc.state.store && <Fragment>
                        <Typography className={classes.pageTitle} gutterBottom variant="h4">{sc.state.store.locationalName || sc.state.store.name}</Typography>
                        <div className={classes.breadcrumbs}>
                            <Button size="small" onClick={() => history.push(paths.STORES)}>Stores</Button>
                            <span className={classes.breadcrumb}>{sc.state.store.locationalName || sc.state.store.name}</span>
                        </div>
                        <Paper className={classes.tabs}>
                            <AppBar color="secondary" position="static">
                                <Tabs indicatorColor="primary"
                                    centered={isDesktop}
                                    variant={isDesktop ? 'fullWidth' : 'scrollable'}
                                    value={tab}
                                    onChange={this.onTabChange}>
                                    {Object.keys(this.tabs).map(slug =>
                                        <Tab key={slug} label={this.tabs[slug]} value={slug} />)}
                                </Tabs>
                            </AppBar>
                            {this.renderTabContents(tab)}
                        </Paper>
                    </Fragment>}
                </Subscribe>
                <SideDrawer title={drawerTitle}
                    anchor="right"
                    open={Boolean(drawerContent)}
                    onClose={this.closeDrawer}>
                    {drawerContent}
                </SideDrawer>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                    open={Boolean(notification)}
                    autoHideDuration={6000}
                    message={notification}
                    onClose={this.hideNotification} />
            </Fragment>}
            {notFound && "Not found"}
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(Store)));