import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    backdrop: {
        backgroundColor: theme.palette.action.disabledBackground,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndex.drawer
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2)
    },
    item: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    }
});

class PlaceList extends Component {
    static propTypes = {
        placeTypes: PropTypes.array.isRequired,
        defaultPlaceType: PropTypes.string.isRequired,
        loadPlaces: PropTypes.func.isRequired,
        onPlaceTypeChange: PropTypes.func,
        onSelect: PropTypes.func.isRequired
    };

    static defaultProps = {
        defaultPlaceType: 'store'
    };

    constructor(props) {
        super(props);

        this.state = {
            placeType: props.defaultPlaceType,
            places: [],
            loading: true
        };
    }

    async componentDidMount() {
        await this.onPlaceTypeChange(this.props.defaultPlaceType);
    }

    async onPlaceTypeChange(placeType) {
        try {
            this.setState({ loading: true });

            const { loadPlaces } = this.props;
            const places = await loadPlaces(placeType);

            this.setState({
                placeType,
                places: places || []
            });

            if (this.props.onPlaceTypeChange) {
                this.props.onPlaceTypeChange(placeType);
            }
        }
        finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { classes, placeTypes, onSelect } = this.props;
        const { placeType, places, loading } = this.state;

        return <div className={classes.root}>
            {loading && <div className={classes.backdrop}><CircularProgress size={80} /></div>}
            <div className={classes.form}>
                <TextField
                    id="placeType"
                    label="Place type"
                    select
                    required
                    margin="normal"
                    value={placeType}
                    onChange={e => this.onPlaceTypeChange(e.target.value)}>
                    {placeTypes.map(pt => <MenuItem key={pt.id} value={pt.name}>{pt.description}</MenuItem>)}
                </TextField>
            </div>
            <List>
                {places.map(p =>
                    <ListItem key={p.name} button divider classes={{ root: classes.item }} onClick={() => onSelect(p)}>
                        <ListItemText primary={p.name} secondary={p.vicinity} />
                    </ListItem>)}
            </List>
        </div>;
    }
}

export default withStyles(styles)(PlaceList);