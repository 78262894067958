import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
import { actionsColumnStyle, tableRowStyle } from '../../styles';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

const styles = theme => ({
    ...tableRowStyle(theme),
    ...actionsColumnStyle(theme)
});

class TradingPeriodTable extends Component {
    static propTypes = {
        tradingPeriods: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
    }

    onDelete(event, address) {
        event.stopPropagation();
        this.props.onDelete(address);
    }

    render() {
        const { classes, tradingPeriods, onSelect } = this.props;
        const toTimeString = value => format(parse(value, 'HH:mm:ss', new Date()), 'h:mm a');
        const toTimes = hours => hours.isOpen ? `${toTimeString(hours.openTime)} - ${toTimeString(hours.closeTime)}` : 'Closed';

        return <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Trading period type</TableCell>
                    <TableCell>Trading period</TableCell>
                    <Hidden mdDown>
                        <TableCell>Monday</TableCell>
                        <TableCell>Tuesday</TableCell>
                        <TableCell>Wednesday</TableCell>
                        <TableCell>Thursday</TableCell>
                        <TableCell>Friday</TableCell>
                        <TableCell>Saturday</TableCell>
                        <TableCell>Sunday</TableCell>
                    </Hidden>
                    <TableCell className={classes.actionsColumn} />
                </TableRow>
            </TableHead>
            <TableBody>
                {tradingPeriods.map(tp =>
                    <TableRow key={tp.id} className={classes.tableRow} hover onClick={() => onSelect(tp)}>
                        <TableCell>{tp.tradingPeriodType.name}</TableCell>
                        <TableCell>
                            {format(parseISO(tp.startDate), 'd MMM yyyy')} - {tp.endDate != null ? format(parseISO(tp.endDate), 'd MMM yyyy') : 'Ongoing'}
                        </TableCell>
                        <Hidden mdDown>
                            <TableCell>{toTimes(tp.monday)}</TableCell>
                            <TableCell>{toTimes(tp.tuesday)}</TableCell>
                            <TableCell>{toTimes(tp.wednesday)}</TableCell>
                            <TableCell>{toTimes(tp.thursday)}</TableCell>
                            <TableCell>{toTimes(tp.friday)}</TableCell>
                            <TableCell>{toTimes(tp.saturday)}</TableCell>
                            <TableCell>{toTimes(tp.sunday)}</TableCell>
                        </Hidden>
                        <TableCell className={classes.actionsColumn}>
                            <IconButton onClick={e => this.onDelete(e, tp)}>
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>;
    }
}

export default withStyles(styles)(TradingPeriodTable);