import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import { inactiveStyle } from '../styles';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    item: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    noDataMessage: {
        padding: theme.spacing(2)
    },
    ...inactiveStyle(theme)
});


class ListView extends Component {
    static propTypes = {
        items: PropTypes.array.isRequired,
        label: PropTypes.func.isRequired,
        caption: PropTypes.func,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        canDelete: PropTypes.func.isRequired
    };

    static defaultProps = {
        canDelete: () => true
    };

    render() {
        const { classes, items, label, caption, onSelect, onDelete, canDelete } = this.props;

        return <div className={classes.root}>
            {items.length === 0 && <Typography className={classes.noDataMessage}>Nothing to show.</Typography>}
            {items.length > 0 && <List>
                {items.map(i =>
                    <ListItem key={i.id || i} button divider className={classNames({ [classes.inactive]: i.isActive !== undefined && !i.isActive })} classes={{ container: classes.item }} onClick={() => onSelect(i)}>
                        <ListItemText primary={label(i)} secondary={Boolean(caption) ? caption(i) : null} />
                        <ListItemSecondaryAction>
                            {canDelete(i) &&
                                <IconButton onClick={() => onDelete(i)}>
                                    <DeleteIcon />
                                </IconButton>}
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
            </List>}
        </div>;
    }
}

export default withStyles(styles)(ListView);