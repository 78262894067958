import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import SecureRoute from '../components/SecureRoute';
import ImageAnalyses from '../pages/ImageAnalyses';
import ShoppingLocations from '../pages/ShoppingLocations';
import ShoppingLocation from '../pages/ShoppingLocation';
import Retailers from '../pages/Retailers';
import Retailer from '../pages/Retailer';
import Stores from '../pages/Stores';
import Products from '../pages/Products';
import Product from '../pages/Product';
import ProductVariant from '../pages/ProductVariant';
import Store from '../pages/Store';
import Brands from '../pages/Brands';
import Categories from '../pages/Categories';
import BlobService from '../services/BlobService';
import StoreImageAnalysisService from '../services/StoreImageAnalysisService';
import RetailerService from '../services/RetailerService';
import StoreService from '../services/StoreService';
import ProductService from '../services/ProductService';
import ProductVariantService from '../services/ProductVariantService';
import ShoppingLocationService from '../services/ShoppingLocationService';
import AddressUsageService from '../services/AddressUsageService';
import RetailerTypeService from '../services/RetailerTypeService';
import ShoppingLocationTypeService from '../services/ShoppingLocationTypeService';
import ShoppingLocationFeatureTypeService from '../services/ShoppingLocationFeatureTypeService';
import StockStatusService from '../services/StockStatusService';
import PlaceTypeService from '../services/PlaceTypeService';
import StoreTypeService from '../services/StoreTypeService';
import IndustryAreaService from '../services/IndustryAreaService';
import CurrencyService from '../services/CurrencyService';
import StoreFeatureTypeService from '../services/StoreFeatureTypeService';
import TradingPeriodTypeService from '../services/TradingPeriodTypeService';
import BrandService from '../services/BrandService';
import CategoryService from '../services/CategoryService';
import { paths } from '../App';
import queryString from 'query-string';

export default class Routes extends Component {
    static propTypes = {
        config: PropTypes.object.isRequired,
        app: PropTypes.object.isRequired
    };

    render() {
        const { config, app } = this.props;
        const { apiBaseUrl, blobStorageBaseUrl } = config;

        return <Switch>
            <Redirect exact
                from={paths.HOME}
                to={paths.SHOPPING_LOCATIONS} />

            <SecureRoute exact
                path={paths.IMAGE_ANALYSES}
                authorised={() => app.hasToken()}
                render={() => <ImageAnalyses
                    imageAnalysisService={new StoreImageAnalysisService(apiBaseUrl, () => app.getAccessToken())} />} />

            <SecureRoute exact
                path={paths.SHOPPING_LOCATIONS}
                authorised={() => app.hasToken()}
                render={props => <ShoppingLocations
                    key={props.location.search}
                    query={queryString.parse(props.location.search).query}
                    blobService={new BlobService(apiBaseUrl, () => app.getAccessToken(), blobStorageBaseUrl)}
                    shoppingLocationService={new ShoppingLocationService(apiBaseUrl, () => app.getAccessToken())}
                    shoppingLocationTypeService={new ShoppingLocationTypeService(apiBaseUrl, () => app.getAccessToken())} />} />

            <SecureRoute exact
                path={paths.SHOPPING_LOCATION}
                authorised={() => app.hasToken()}
                render={props => <ShoppingLocation
                    tab={props.match.params.tab}
                    shoppingLocationId={props.match.params.shoppingLocationId}
                    blobService={new BlobService(apiBaseUrl, () => app.getAccessToken(), blobStorageBaseUrl)}
                    retailerService={new RetailerService(apiBaseUrl, () => app.getAccessToken())}
                    placeTypeService={new PlaceTypeService(apiBaseUrl, () => app.getAccessToken())}
                    shoppingLocationService={new ShoppingLocationService(apiBaseUrl, () => app.getAccessToken())}
                    storeService={new StoreService(apiBaseUrl, () => app.getAccessToken())}
                    storeTypeService={new StoreTypeService(apiBaseUrl, () => app.getAccessToken())}
                    industryAreaService={new IndustryAreaService(apiBaseUrl, () => app.getAccessToken())}
                    currencyService={new CurrencyService(apiBaseUrl, () => app.getAccessToken())}
                    addressUsageService={new AddressUsageService(apiBaseUrl, () => app.getAccessToken())}
                    shoppingLocationTypeService={new ShoppingLocationTypeService(apiBaseUrl, () => app.getAccessToken())}
                    shoppingLocationFeatureTypeService={new ShoppingLocationFeatureTypeService(apiBaseUrl, () => app.getAccessToken())}
                    tradingPeriodTypeService={new TradingPeriodTypeService(apiBaseUrl, () => app.getAccessToken())} />} />

            <SecureRoute exact
                path={paths.RETAILERS}
                authorised={() => app.hasToken()}
                render={props => <Retailers
                    key={props.location.search}
                    query={queryString.parse(props.location.search).query}
                    blobService={new BlobService(apiBaseUrl, () => app.getAccessToken(), blobStorageBaseUrl)}
                    retailerService={new RetailerService(apiBaseUrl, () => app.getAccessToken())}
                    retailerTypeService={new RetailerTypeService(apiBaseUrl, () => app.getAccessToken())}
                    addressUsageService={new AddressUsageService(apiBaseUrl, () => app.getAccessToken())} />} />

            <SecureRoute exact
                path={paths.RETAILER}
                authorised={() => app.hasToken()}
                render={props => <Retailer
                    tab={props.match.params.tab}
                    retailerId={props.match.params.retailerId}
                    blobService={new BlobService(apiBaseUrl, () => app.getAccessToken(), blobStorageBaseUrl)}
                    shoppingLocationService={new ShoppingLocationService(apiBaseUrl, () => app.getAccessToken())}
                    retailerService={new RetailerService(apiBaseUrl, () => app.getAccessToken())}
                    storeService={new StoreService(apiBaseUrl, () => app.getAccessToken())}
                    addressUsageService={new AddressUsageService(apiBaseUrl, () => app.getAccessToken())}
                    retailerTypeService={new RetailerTypeService(apiBaseUrl, () => app.getAccessToken())}
                    storeTypeService={new StoreTypeService(apiBaseUrl, () => app.getAccessToken())}
                    industryAreaService={new IndustryAreaService(apiBaseUrl, () => app.getAccessToken())}
                    currencyService={new CurrencyService(apiBaseUrl, () => app.getAccessToken())} />} />

            <SecureRoute exact
                path={paths.STORES}
                authorised={() => app.hasToken()}
                render={props => <Stores
                    key={props.location.search}
                    query={queryString.parse(props.location.search).query}
                    blobService={new BlobService(apiBaseUrl, () => app.getAccessToken(), blobStorageBaseUrl)}
                    storeService={new StoreService(apiBaseUrl, () => app.getAccessToken())}
                    storeTypeService={new StoreTypeService(apiBaseUrl, () => app.getAccessToken())}
                    industryAreaService={new IndustryAreaService(apiBaseUrl, () => app.getAccessToken())}
                    currencyService={new CurrencyService(apiBaseUrl, () => app.getAccessToken())}
                    addressUsageService={new AddressUsageService(apiBaseUrl, () => app.getAccessToken())}
                    retailerService={new RetailerService(apiBaseUrl, () => app.getAccessToken())}
                    shoppingLocationService={new ShoppingLocationService(apiBaseUrl, () => app.getAccessToken())} />} />

            <SecureRoute exact
                path={paths.STORE}
                authorised={() => app.hasToken()}
                render={props => <Store
                    tab={props.match.params.tab}
                    key={props.match.params.storeId}
                    storeId={props.match.params.storeId}
                    blobService={new BlobService(apiBaseUrl, () => app.getAccessToken(), blobStorageBaseUrl)}
                    storeService={new StoreService(apiBaseUrl, () => app.getAccessToken())}
                    addressUsageService={new AddressUsageService(apiBaseUrl, () => app.getAccessToken())}
                    storeTypeService={new StoreTypeService(apiBaseUrl, () => app.getAccessToken())}
                    industryAreaService={new IndustryAreaService(apiBaseUrl, () => app.getAccessToken())}
                    currencyService={new CurrencyService(apiBaseUrl, () => app.getAccessToken())}
                    shoppingLocationService={new ShoppingLocationService(apiBaseUrl, () => app.getAccessToken())}
                    storeFeatureTypeService={new StoreFeatureTypeService(apiBaseUrl, () => app.getAccessToken())}
                    tradingPeriodTypeService={new TradingPeriodTypeService(apiBaseUrl, () => app.getAccessToken())}
                    brandService={new BrandService(apiBaseUrl, () => app.getAccessToken())}
                    categoryService={new CategoryService(apiBaseUrl, () => app.getAccessToken())}
                    productService={new ProductService(apiBaseUrl, () => app.getAccessToken())}
                    stockStatusService={new StockStatusService(apiBaseUrl, () => app.getAccessToken())} />} />

            <SecureRoute exact
                path={paths.PRODUCTS}
                authorised={() => app.hasToken()}
                render={props => <Products
                    key={props.location.search}
                    query={queryString.parse(props.location.search).query}
                    blobService={new BlobService(apiBaseUrl, () => app.getAccessToken(), blobStorageBaseUrl)}
                    productService={new ProductService(apiBaseUrl, () => app.getAccessToken())}
                    brandService={new BrandService(apiBaseUrl, () => app.getAccessToken())}
                    storeService={new StoreService(apiBaseUrl, () => app.getAccessToken())}
                    stockStatusService={new StockStatusService(apiBaseUrl, () => app.getAccessToken())} />} />

            <SecureRoute exact
                path={paths.PRODUCT}
                authorised={() => app.hasToken()}
                render={props => <Product
                    tab={props.match.params.tab}
                    productId={props.match.params.productId}
                    blobService={new BlobService(apiBaseUrl, () => app.getAccessToken(), blobStorageBaseUrl)}
                    productService={new ProductService(apiBaseUrl, () => app.getAccessToken())}
                    brandService={new BrandService(apiBaseUrl, () => app.getAccessToken())}
                    stockStatusService={new StockStatusService(apiBaseUrl, () => app.getAccessToken())} />} />

            <SecureRoute exact
                path={paths.PRODUCT_VARIANT}
                authorised={() => app.hasToken()}
                render={props => <ProductVariant
                    tab={props.match.params.tab}
                    productId={props.match.params.productId}
                    productVariantId={props.match.params.productVariantId}
                    blobService={new BlobService(apiBaseUrl, () => app.getAccessToken(), blobStorageBaseUrl)}
                    productService={new ProductService(apiBaseUrl, () => app.getAccessToken())}
                    productVariantService={new ProductVariantService(apiBaseUrl, () => app.getAccessToken())}
                    stockStatusService={new StockStatusService(apiBaseUrl, () => app.getAccessToken())} />} />

            <SecureRoute exact
                path={paths.BRANDS}
                authorised={() => app.hasToken()}
                render={props => <Brands
                    key={props.location.search}
                    query={queryString.parse(props.location.search).query}
                    brandService={new BrandService(apiBaseUrl, () => app.getAccessToken())}
                    blobService={new BlobService(apiBaseUrl, () => app.getAccessToken(), blobStorageBaseUrl)} />} />

            <SecureRoute exact
                path={paths.CATEGORIES}
                authorised={() => app.hasToken()}
                render={props => <Categories
                    key={props.location.search}
                    query={queryString.parse(props.location.search).query}
                    categoryService={new CategoryService(apiBaseUrl, () => app.getAccessToken())} />} />

            <Redirect to={paths.NOT_FOUND} />
        </Switch>;
    }
}