import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'unstated';
import { Switch, Redirect } from 'react-router-dom';
import AppContainer from './containers/AppContainer';
import AuthService from './services/AuthService';
import SignIn from './pages/SignIn';
import Layout from './pages/Layout';
import NotFound from './pages/NotFound';
import axios from 'axios';

export const paths = {
    HOME: '/',
    IMAGE_ANALYSES: '/image-analyses',
    SHOPPING_LOCATIONS: '/shopping-locations',
    SHOPPING_LOCATION: '/shopping-locations/:shoppingLocationId/:tab?',
    RETAILERS: '/retailers',
    RETAILER: '/retailers/:retailerId/:tab?',
    STORES: '/stores',
    STORE: '/stores/:storeId/:tab?',
    PRODUCTS: '/products',
    PRODUCT: '/products/:productId/:tab?',
    PRODUCT_VARIANT: '/products/:productId/variants/:productVariantId/:tab?',
    BRANDS: '/brands',
    CATEGORIES: '/categories',
    SIGN_IN: '/sign-in',
    NOT_FOUND: '/not-found'
};

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e74c3c'
        },
        secondary: {
            main: '#3f3a40'
        }
    }
});

const history = createBrowserHistory();

export default class App extends Component {
    static propTypes = {
        config: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.appContainer = new AppContainer();
        this.authService = new AuthService(props.config.apiBaseUrl);

        // Response error interceptor.
        axios.interceptors.response.use(null, error => this.handleErrorResponse(error));
    }

    async handleErrorResponse(error) {
        const { config, status, headers } = error.response;
        const { apiBaseUrl } = this.props.config;

        const unauthorized = status === 401;

        const tokenNeedsRefreshing = headers['token-expired'] &&
            !config._isRetry && // Not a retry request.
            config.url !== apiBaseUrl + '/auth/refresh'; // Not a refresh request.

        if (unauthorized) {
            if (tokenNeedsRefreshing) {
                let resp;

                if (this.refresh) {
                    // Refresh request already in flight. Wait until it's done then we'll retry this request.
                    resp = await this.refresh;
                } else {
                    const { accessToken, refreshToken } = this.appContainer.state;

                    try {
                        this.refresh = this.authService.refreshToken(accessToken, refreshToken);
                        resp = await this.refresh;
                    } catch {
                        // Failed to refresh token. Force sign out.
                        config.headers.Authorization = null;
                        await this.appContainer.signOut();
                        return axios(config);
                    } finally {
                        this.refresh = null;
                    }

                    await this.appContainer.signIn(resp.data.accessToken, resp.data.refreshToken);
                }

                // Retry original request with new access token.
                config._isRetry = true;
                config.headers.Authorization = `Bearer ${resp.data.accessToken}`;

                return axios(config);
            } else {
                await this.appContainer.signOut();

                // Token couldn't be refreshed. Allow pending state transitions to settle then redirect to sign in.
                setTimeout(() => history.push({
                    pathname: '/sign-in',
                    state: {
                        from: history.location
                    }
                }));
            }
        }

        return Promise.reject(error);
    }

    render() {
        const { config } = this.props;

        // Top-level routes. All secure routes are defined in the Routes component.
        const routes = <Switch>
            <Route exact
                path={paths.SIGN_IN}
                render={() => !this.appContainer.hasToken()
                    ? <SignIn app={this.appContainer} authService={this.authService} googleClientId={config.googleClientId} />
                    : <Redirect to={paths.HOME} />} />

            <Route exact path={paths.NOT_FOUND} component={NotFound} />

            <Route render={() => <Layout config={config} app={this.appContainer} />} />
        </Switch>;

        return <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Provider inject={[this.appContainer]}>
                    <Router history={history}>
                        {routes}
                    </Router>
                </Provider>
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>;
    }
}