import { Container } from 'unstated';

export default class ProductVariantContainer extends Container {
    constructor(productService, productVariantService) {
        super();

        this.productService = productService;
        this.productVariantService = productVariantService;
    }

    state = {
        product: null,
        variant: null,
        images: []
    };

    async load(productId, productVariantId) {
        const [getProduct, getProductVariant] = await Promise.all([
            this.productService.getById(productId),
            this.productVariantService.getById(productId, productVariantId)
        ]);

        const ok = getProduct.ok && getProductVariant.ok;

        if (ok) {
            this.setState({
                product: getProduct.data,
                variant: getProductVariant.data
            }, async () => await Promise.all([
                this.loadImages()
            ]));
        }

        return ok;
    }

    async loadImages() {
        const { product, variant } = this.state;
        const response = await this.productVariantService.getImages(product.id, variant.id);

        if (response.ok) {
            this.setState({ images: response.data });
        }

        return response.ok;
    }

    async update(changes) {
        const { product, variant } = this.state;
        const response = await this.productVariantService.update(product.id, variant.id, {
            ...changes,
            timestamp: variant.timestamp
        });

        if (response.ok) {
            this.setState({ variant: response.data });
        }

        return response.ok;
    }

    async addImage(productImage, reload) {
        const { product, variant } = this.state;
        const response = await this.productVariantService.addImage(product.id, variant.id, productImage);

        if (response.ok) {
            await this.loadImages();
        }

        return response.ok;
    }

    async updateImage(imageId, changes) {
        const { product, variant } = this.state;
        const images = this.state.images.slice();
        const image = images.find(i => i.id === imageId);

        const response = await this.productVariantService.updateImage(product.id,
            variant.id,
            imageId, {
            ...changes,
            timestamp: image.timestamp
        });

        if (response.ok) {
            // Replace old image object with new one.
            const index = images.indexOf(image);
            images.splice(index, 1, response.data);

            this.setState({ images });
        }

        return response.ok;
    }

    async removeImage(imageId) {
        const { product, variant } = this.state;
        const response = await this.productVariantService.removeImage(product.id, variant.id, imageId);

        if (response.ok) {
            const images = this.state.images.slice();
            const index = images.findIndex(i => i.id === imageId);

            images.splice(index, 1);

            this.setState({ images });
        }
    }
}