import ApiService from './ApiService';
import axios from 'axios';

export default class StoreTypeService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async getAll() {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/store-types`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }
}