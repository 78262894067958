import ListContainer from './ListContainer';

export default class StoreImageAnalysesContainer extends ListContainer {
    async apply(imageAnalysisId, data, reload) {
        const response = await this.service.apply(imageAnalysisId, data);

        if (response.ok && reload) {
            await this.load();
        }

        return response.ok;
    }
}