import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListView from '../../components/ListView';

export default class BrandList extends Component {
    static propTypes = {
        brands: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired
    };

    render() {
        const { brands, onSelect } = this.props;

        return <ListView items={brands}
            label={b => b}
            canDelete={() => false}
            onSelect={onSelect}
            onDelete={() => { }} />
    }
}
