import React, { Fragment } from 'react';
import { GoogleMap, Polygon, withGoogleMap } from 'react-google-maps';
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager';

const google = window.google;

function Map(props) {
    const { lat, lng, draw, polygon, onPolygonComplete } = props;

    return <Fragment>
        <GoogleMap
            zoom={15}
            center={{ lat, lng }}>
            <DrawingManager
                defaultDrawingMode={draw ? google.maps.drawing.OverlayType.POLYGON : null}
                defaultOptions={{
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [
                            // Can also use CIRCLE, RECTANGLE, POLYLINE but we currently only support polygons.
                            google.maps.drawing.OverlayType.POLYGON
                        ]
                    }
                }}
                onPolygonComplete={onPolygonComplete} />
            {polygon && <Polygon path={polygon.getPath()}></Polygon>}
        </GoogleMap>
        {props.children}
    </Fragment>;
}

export default withGoogleMap(Map);