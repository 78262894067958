import ApiService from './ApiService';
import axios from 'axios';
import queryString from 'query-string';

export default class ShoppingLocationService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async getSuggestions(partial) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/suggestions`;
        const query = { partial };
        const response = await axios.get(url + '?' + queryString.stringify(query), { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getAll(query, pageNumber, pageSize, sortBy) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations`;

        const request = {
            query,
            pageNumber,
            pageSize,
            sortBy
        };

        const response = await axios.get(url + '?' + queryString.stringify(request), { headers });

        return {
            ok: response.status === 200,
            data: response.data,
            totalCount: parseInt(response.headers['x-total-count'], 10)
        };
    }

    async getById(id) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${id}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async create(shoppingLocation) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations`;
        const response = await axios.post(url, shoppingLocation, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async update(shoppingLocationId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async delete(shoppingLocationId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getAddresses(shoppingLocationId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/addresses`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getAddressById(shoppingLocationId, addressId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/addresses/${addressId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async createAddress(shoppingLocationId, address) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/addresses`;
        const response = await axios.post(url, address, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateAddress(shoppingLocationId, addressId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/addresses/${addressId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async deleteAddress(shoppingLocationId, addressId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/addresses/${addressId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getTradingPeriods(shoppingLocationId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/trading-periods`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getTradingPeriodById(shoppingLocationId, tradingPeriodId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/trading-periods/${tradingPeriodId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async createTradingPeriod(shoppingLocationId, tradingPeriod) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/trading-periods`;
        const response = await axios.post(url, tradingPeriod, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }


    async updateTradingPeriod(shoppingLocationId, tradingPeriodId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/trading-periods/${tradingPeriodId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async deleteTradingPeriod(shoppingLocationId, tradingPeriodId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/trading-periods/${tradingPeriodId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getFeatures(shoppingLocationId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/features`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getFeatureById(shoppingLocationId, featureId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/features/${featureId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async addFeature(shoppingLocationId, feature) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/features`;
        const response = await axios.post(url, feature, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateFeature(shoppingLocationId, featureId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/features/${featureId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async removeFeature(shoppingLocationId, featureId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/features/${featureId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getImages(shoppingLocationId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/images`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getImageById(shoppingLocationId, imageId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/images/${imageId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async addImage(shoppingLocationId, image) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/images`;
        const response = await axios.post(url, image, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateImage(shoppingLocationId, imageId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/images/${imageId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async removeImage(shoppingLocationId, imageId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/images/${imageId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    /**
     * Get Google place suggestions for the shopping location.
     * @param {string} shoppingLocationId 
     */
    async getPlaces(shoppingLocationId, type) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/places?type=${type}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    /**
     * Get the details of a Google place.
     * @param {string} placeId 
     */
    async getPlace(shoppingLocationId, placeId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/places/${placeId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }
}