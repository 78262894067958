import { Container } from 'unstated';

export default class ProductContainer extends Container {
    constructor(productService) {
        super();

        this.productService = productService;
    }

    state = {
        product: null,
        images: [],
        variants: []
    };

    async load(productId) {
        const response = await this.productService.getById(productId);

        if (response.ok) {
            this.setState({ product: response.data },
                async () => await Promise.all([
                    this.loadImages(),
                    this.loadVariants()
                ]));
        }

        return response.ok;
    }

    async loadImages() {
        const { product } = this.state;
        const response = await this.productService.getImages(product.id);

        if (response.ok) {
            this.setState({ images: response.data });
        }

        return response.ok;
    }

    async loadVariants() {
        const { product } = this.state;
        const response = await this.productService.getVariants(product.id);

        if (response.ok) {
            this.setState({ variants: response.data });
        }

        return response.ok;
    }

    async update(changes) {
        const { product } = this.state;
        const response = await this.productService.update(product.id, {
            ...changes,
            timestamp: product.timestamp
        });

        if (response.ok) {
            this.setState({ product: response.data });
        }

        return response.ok;
    }

    async addImage(productImage, reload) {
        const { product } = this.state;
        const response = await this.productService.addImage(product.id, productImage);

        if (response.ok) {
            await this.loadImages();
        }

        return response.ok;
    }

    async updateImage(imageId, changes) {
        const { product } = this.state;
        const images = this.state.images.slice();
        const image = images.find(i => i.id === imageId);

        const response = await this.productService.updateImage(product.id,
            imageId, {
                ...changes,
                timestamp: image.timestamp
            });

        if (response.ok) {
            // Replace old image object with new one.
            const index = images.indexOf(image);
            images.splice(index, 1, response.data);

            this.setState({ images });
        }

        return response.ok;
    }

    async removeImage(imageId) {
        const { product } = this.state;
        const response = await this.productService.removeImage(product.id, imageId);

        if (response.ok) {
            const images = this.state.images.slice();
            const index = images.findIndex(i => i.id === imageId);

            images.splice(index, 1);

            this.setState({ images });
        }
    }

    async createVariant(variant) {
        const { product } = this.state;
        const response = await this.productService.createVariant(product.id, variant);

        if (response.ok) {
            await this.loadVariants();
        }

        return response.ok;
    }

    async updateVariant(variantId, changes) {
        const { product } = this.state;
        const variants = this.state.variants.slice();
        const variant = variants.find(v => v.id === variantId);

        const response = await this.productService.updateVariant(product.id,
            variantId, {
                ...changes,
                timestamp: variant.timestamp
            });

        if (response.ok) {
            // Replace old variant object with new one.
            const index = variants.indexOf(variant);
            variants.splice(index, 1, response.data);

            this.setState({ variants });
        }

        return response.ok;
    }

    async deleteVariant(variantId) {
        const { product } = this.state;
        const response = await this.productService.deleteVariant(product.id, variantId);

        if (response.ok) {
            const variants = this.state.variants.slice();
            const index = variants.findIndex(v => v.id === variantId);

            variants.splice(index, 1);

            this.setState({ variants });
        }
    }
}