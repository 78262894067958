import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import RetailerContainer from '../../containers/RetailerContainer';
import LookupsContainer from '../../containers/LookupsContainer';
import Paper from '@material-ui/core/Paper';
import UpdateRetailerForm from './UpdateRetailerForm';
import CreateAddressForm from './CreateAddressForm';
import UpdateAddressForm from './UpdateAddressForm';
import CreateStoreForm from './CreateStoreForm';
import CreateUserForm from './CreateUserForm';
import UpdateUserForm from './UpdateUserForm';
import AddressTable from './AddressTable';
import AddressList from './AddressList';
import StoreTable from './StoreTable';
import StoreList from './StoreList';
import UserTable from './UserTable';
import UserList from './UserList';
import SideDrawer from '../../components/SideDrawer';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { breadcrumbStyle, fabStyle, pageTitleStyle, tabsStyle } from '../../styles';
import { withRouter } from 'react-router-dom';
import { paths } from '../../App';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 0
    },
    detailsTab: {
        padding: theme.spacing(2)
    },
    addressesTab: {
        flexGrow: 1,
        position: 'relative'
    },
    storesTab: {
        flexGrow: 1,
        position: 'relative'
    },
    usersTab: {
        flexGrow: 1,
        position: 'relative'
    },
    ...breadcrumbStyle(theme),
    ...fabStyle(theme, theme.spacing(2)),
    ...pageTitleStyle(theme),
    ...tabsStyle(theme)
});

class Retailer extends Component {
    static propTypes = {
        retailerId: PropTypes.string.isRequired,
        blobService: PropTypes.object.isRequired,
        retailerService: PropTypes.object.isRequired,
        storeService: PropTypes.object.isRequired,
        addressUsageService: PropTypes.object.isRequired,
        retailerTypeService: PropTypes.object.isRequired,
        storeTypeService: PropTypes.object.isRequired,
        industryAreaService: PropTypes.object.isRequired,
        currencyService: PropTypes.object.isRequired,
        shoppingLocationService: PropTypes.object.isRequired,
        tab: PropTypes.string
    };

    static defaultProps = {
        tab: 'details'
    };

    tabs = {
        'details': 'Details',
        'addresses': 'Addresses',
        'stores': 'Stores',
        'users': 'Users'
    };

    constructor(props) {
        super(props);

        const notFound = Object.keys(this.tabs).indexOf(props.tab) < 0;

        this.retailerContainer = new RetailerContainer(props.retailerService, props.storeService);
        this.lookupsContainer = new LookupsContainer(props);

        this.state = {
            drawerTitle: '',
            drawerContent: null,
            notification: null,
            notFound,
            ready: false
        };

        this.onTabChange = this.onTabChange.bind(this);
        this.openCreateAddressDrawer = this.openCreateAddressDrawer.bind(this);
        this.openCreateStoreDrawer = this.openCreateStoreDrawer.bind(this);
        this.openCreateUserDrawer = this.openCreateUserDrawer.bind(this);
        this.onSelectStore = this.onSelectStore.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.hideNotification = this.hideNotification.bind(this);
    }

    async componentDidMount() {
        const { retailerId } = this.props;

        await Promise.all([
            this.retailerContainer.load(retailerId),
            this.lookupsContainer.loadAddressUsages(),
            this.lookupsContainer.loadCurrencies(),
            this.lookupsContainer.loadIndustryAreas(),
            this.lookupsContainer.loadRetailerTypes(),
            this.lookupsContainer.loadStoreTypes()
        ]);

        this.setState({ ready: true });
    }

    async updateRetailer(form) {
        const ok = await this.retailerContainer.update(form);

        if (ok) {
            this.showNotification('Retailer saved');
        } else {
            this.showNotification('Unable to save! Please try again.');
        }
    }

    async createAddress(form) {
        const created = await this.retailerContainer.createAddress(form);

        if (created) {
            this.closeDrawer();
        }
    }

    async updateAddress(addressId, form) {
        const ok = await this.retailerContainer.updateAddress(addressId, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteAddress(address) {
        const confirmed = window.confirm(`Delete address ${address.address.formattedAddress}?`);

        if (confirmed) {
            await this.retailerContainer.deleteAddress(address.id);
        }
    }

    async createStore(form) {
        const response = await this.retailerContainer.createStore(form);

        if (response.ok) {
            this.props.history.push(`/stores/${response.data.id}`);
        }
    }

    async deleteStore(store) {
        const confirmed = window.confirm(`Delete store ${store.name}?`);

        if (confirmed) {
            await this.retailerContainer.deleteStore(store.id);
        }
    }

    async createUser(form) {
        const created = await this.retailerContainer.createUser(form);

        if (created) {
            this.closeDrawer();
        }
    }

    async updateUser(userId, form) {
        const ok = await this.retailerContainer.updateUser(userId, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteUser(user) {
        const confirmed = window.confirm(`Delete user ${user.emailAddress}?`);

        if (confirmed) {
            try {
                await this.retailerContainer.deleteUser(user.id);
            } catch (err) {
                alert(err.response.data);
            }
        }
    }

    onTabChange(_, value) {
        this.props.history.push(`/retailers/${this.props.retailerId}/${value}`);
    }

    openCreateAddressDrawer() {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <CreateAddressForm
                addressUsages={lookups.state.addressUsages}
                onSave={form => this.createAddress(form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Create address', drawerContent);
    }

    openUpdateAddressDrawer(retailerAddress) {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <UpdateAddressForm
                retailerAddress={retailerAddress}
                addressUsages={lookups.state.addressUsages}
                onSave={form => this.updateAddress(retailerAddress.id, form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Edit address', drawerContent);
    }

    openCreateStoreDrawer() {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <CreateStoreForm
                blobService={this.props.blobService}
                shoppingLocationService={this.props.shoppingLocationService}
                storeTypes={lookups.state.storeTypes}
                industryAreas={lookups.state.industryAreas}
                currencies={lookups.state.currencies}
                addressUsages={lookups.state.addressUsages}
                onSave={form => this.createStore(form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Create store', drawerContent);
    }

    openCreateUserDrawer() {
        const drawerContent = <CreateUserForm
            onSave={form => this.createUser(form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Create user', drawerContent);
    }

    openUpdateUserDrawer(user) {
        const drawerContent = <UpdateUserForm
            user={user}
            onSave={form => this.updateUser(user.id, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit user', drawerContent);
    }

    onSelectStore(store) {
        this.props.history.push(`/stores/${store.id}`);
    }

    openDrawer(drawerTitle, drawerContent) {
        this.setState({
            drawerTitle,
            drawerContent
        });
    }

    closeDrawer() {
        this.setState({
            drawerTitle: '',
            drawerContent: null
        });
    }

    showNotification(notification) {
        this.setState({ notification });
    }

    hideNotification() {
        this.setState({ notification: null });
    }

    renderTabContents(tab) {
        switch (tab) {
            case 'details':
                return this.renderDetailsTab();
            case 'addresses':
                return this.renderAddressesTab();
            case 'stores':
                return this.renderStoresTab();
            case 'users':
                return this.renderUsersTab();
            default:
                return "No content";
        }
    }

    renderDetailsTab() {
        const { classes, blobService } = this.props;

        return <div className={classes.detailsTab}>
            <Subscribe to={[this.lookupsContainer]}>
                {lookups =>
                    <UpdateRetailerForm
                        retailer={this.retailerContainer.state.retailer}
                        retailerTypes={lookups.state.retailerTypes}
                        blobService={blobService}
                        onSave={form => this.updateRetailer(form)} />}
            </Subscribe>
        </div>;
    }

    renderAddressesTab() {
        const { classes } = this.props;

        return <div className={classes.addressesTab}>
            <Hidden smUp implementation="css">
                <AddressList addresses={this.retailerContainer.state.addresses}
                    onSelect={a => this.openUpdateAddressDrawer(a)}
                    onDelete={a => this.deleteAddress(a)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <AddressTable addresses={this.retailerContainer.state.addresses}
                    onSelect={a => this.openUpdateAddressDrawer(a)}
                    onDelete={a => this.deleteAddress(a)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateAddressDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderStoresTab() {
        const { classes } = this.props;

        return <div className={classes.storesTab}>
            <Hidden smUp implementation="css">
                <StoreList stores={this.retailerContainer.state.stores}
                    onSelect={this.onSelectStore}
                    onDelete={s => this.deleteStore(s)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <StoreTable stores={this.retailerContainer.state.stores}
                    onSelect={this.onSelectStore}
                    onDelete={s => this.deleteStore(s)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateStoreDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderUsersTab() {
        const { classes } = this.props;

        return <div className={classes.usersTab}>
            <Hidden smUp implementation="css">
                <UserList users={this.retailerContainer.state.users}
                    onSelect={u => this.openUpdateUserDrawer(u)}
                    onDelete={u => this.deleteUser(u)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <UserTable users={this.retailerContainer.state.users}
                    onSelect={u => this.openUpdateUserDrawer(u)}
                    onDelete={u => this.deleteUser(u)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateUserDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    render() {
        const { classes, history, width, tab } = this.props;
        const { drawerTitle, drawerContent, notification, notFound } = this.state;
        const isDesktop = isWidthUp('lg', width);

        return <div className={classes.root}>
            {!notFound && <Fragment>
                <Subscribe to={[this.retailerContainer]}>
                    {rc => rc.state.retailer && <Fragment>
                        <Typography className={classes.pageTitle} gutterBottom variant="h4">{rc.state.retailer.name}</Typography>
                        <div className={classes.breadcrumbs}>
                            <Button size="small" onClick={() => history.push(paths.RETAILERS)}>Retailers</Button> >
                        <span className={classes.breadcrumb}>{rc.state.retailer.name}</span>
                        </div>
                        <Paper className={classes.tabs}>
                            <AppBar color="secondary" position="static">
                                <Tabs indicatorColor="primary"
                                    centered={isDesktop}
                                    variant={isDesktop ? 'fullWidth' : 'scrollable'}
                                    value={tab}
                                    onChange={this.onTabChange}>
                                    {Object.keys(this.tabs).map(slug =>
                                        <Tab key={slug} label={this.tabs[slug]} value={slug} />)}
                                </Tabs>
                            </AppBar>
                            {this.renderTabContents(tab)}
                        </Paper>
                    </Fragment>}
                </Subscribe>
                <SideDrawer title={drawerTitle}
                    anchor="right"
                    open={Boolean(drawerContent)}
                    onClose={this.closeDrawer}>
                    {drawerContent}
                </SideDrawer>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                    open={Boolean(notification)}
                    autoHideDuration={6000}
                    message={notification}
                    onClose={this.hideNotification} />
            </Fragment>}
            {notFound && "Not found"}
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(Retailer)));