import { Container } from 'unstated';

export default class LookupsContainer extends Container {
    constructor(services) {
        super();
        this.addressUsageService = services.addressUsageService;
        this.currencyService = services.currencyService;
        this.industryAreaService = services.industryAreaService;
        this.retailerTypeService = services.retailerTypeService;
        this.placeTypeService = services.placeTypeService;
        this.shoppingLocationFeatureTypeService = services.shoppingLocationFeatureTypeService;
        this.shoppingLocationTypeService = services.shoppingLocationTypeService;
        this.stockStatusService = services.stockStatusService;
        this.storeFeatureTypeService = services.storeFeatureTypeService;
        this.storeTypeService = services.storeTypeService;
        this.tradingPeriodTypeService = services.tradingPeriodTypeService;
    }

    state = {
        addressUsages: [],
        currencies: [],
        industryAreas: [],
        retailerTypes: [],
        placeTypes: [],
        shoppingLocationFeatureTypes: [],
        shoppingLocationTypes: [],
        stockStatuses: [],
        storeFeatureTypes: [],
        storeTypes: [],
        tradingPeriodTypes: []
    };

    sort(lookups) {
        return lookups.sort((a, b) => a.displayOrder - b.displayOrder || a.name.localeCompare(b.name));
    }

    async loadAddressUsages(force = false) {
        if (this.state.addressUsages.length === 0 || force) {
            if (!Boolean(this.addressUsageService)) {
                throw new Error('Tried to load address usages from uninitialised service.');
            }

            const response = await this.addressUsageService.getAll();

            if (response.ok) {
                this.setState({ addressUsages: this.sort(response.data) });
            }
        }
    }

    addAddressUsage(addressUsage) {
        this.setState(prevState => ({
            addressUsages: this.sort(prevState.addressUsages.concat(addressUsage))
        }));
    }

    async loadCurrencies(force = false) {
        if (this.state.currencies.length === 0 || force) {
            if (!Boolean(this.currencyService)) {
                throw new Error('Tried to load currencies from uninitialised service.');
            }

            const response = await this.currencyService.getAll();

            if (response.ok) {
                this.setState({ currencies: this.sort(response.data) });
            }
        }
    }

    addCurrency(currency) {
        this.setState(prevState => ({
            currencies: this.sort(prevState.currencies.concat(currency))
        }));
    }

    async loadIndustryAreas(force = false) {
        if (this.state.industryAreas.length === 0 || force) {
            if (!Boolean(this.industryAreaService)) {
                throw new Error('Tried to load industry areas from uninitialised service.');
            }

            const response = await this.industryAreaService.getAll();

            if (response.ok) {
                this.setState({ industryAreas: this.sort(response.data) });
            }
        }
    }

    addIndustryArea(industryArea) {
        this.setState(prevState => ({
            industryAreas: this.sort(prevState.industryAreas.concat(industryArea))
        }));
    }

    async loadRetailerTypes(force = false) {
        if (this.state.retailerTypes.length === 0 || force) {
            if (!Boolean(this.retailerTypeService)) {
                throw new Error('Tried to load retailer types from uninitialised service.');
            }

            const response = await this.retailerTypeService.getAll();

            if (response.ok) {
                this.setState({ retailerTypes: this.sort(response.data) });
            }
        }
    }

    addRetailerType(retailerType) {
        this.setState(prevState => ({
            retailerTypes: this.sort(prevState.retailerTypes.concat(retailerType))
        }));
    }

    async loadShoppingLocationFeatureTypes(force = false) {
        if (this.state.shoppingLocationFeatureTypes.length === 0 || force) {
            if (!Boolean(this.shoppingLocationFeatureTypeService)) {
                throw new Error('Tried to load shopping location feature types from uninitialised service.');
            }

            const response = await this.shoppingLocationFeatureTypeService.getAll();

            if (response.ok) {
                this.setState({ shoppingLocationFeatureTypes: this.sort(response.data) });
            }
        }
    }

    addShoppingLocationFeatureType(shoppingLocationFeatureType) {
        this.setState(prevState => ({
            shoppingLocationFeatureTypes: this.sort(prevState.shoppingLocationFeatureTypes.concat(shoppingLocationFeatureType))
        }));
    }

    async loadShoppingLocationTypes(force = false) {
        if (this.state.shoppingLocationTypes.length === 0 || force) {
            if (!Boolean(this.shoppingLocationTypeService)) {
                throw new Error('Tried to load shopping location types from uninitialised service.');
            }

            const response = await this.shoppingLocationTypeService.getAll();

            if (response.ok) {
                this.setState({ shoppingLocationTypes: this.sort(response.data) });
            }
        }
    }

    addShoppingLocationType(shoppingLocationType) {
        this.setState(prevState => ({
            shoppingLocationTypes: this.sort(prevState.shoppingLocationTypes.concat(shoppingLocationType))
        }));
    }

    async loadStockStatuses(force = false) {
        if (this.state.stockStatuses.length === 0 || force) {
            if (!Boolean(this.stockStatusService)) {
                throw new Error('Tried to load stock statues from uninitialised service.');
            }

            const response = await this.stockStatusService.getAll();

            if (response.ok) {
                this.setState({ stockStatuses: this.sort(response.data) });
            }
        }
    }

    addStockStatus(stockStatus) {
        this.setState(prevState => ({
            stockStatuses: this.sort(prevState.stockStatuses.concat(stockStatus))
        }));
    }

    async loadStoreTypes(force = false) {
        if (this.state.storeTypes.length === 0 || force) {
            if (!Boolean(this.storeTypeService)) {
                throw new Error('Tried to load store types from uninitialised service.');
            }

            const response = await this.storeTypeService.getAll();

            if (response.ok) {
                this.setState({ storeTypes: this.sort(response.data) });
            }
        }
    }

    addStoreType(storeType) {
        this.setState(prevState => ({
            storeTypes: this.sort(prevState.storeTypes.concat(storeType))
        }));
    }

    async loadStoreFeatureTypes(force = false) {
        if (this.state.storeFeatureTypes.length === 0 || force) {
            if (!Boolean(this.storeFeatureTypeService)) {
                throw new Error('Tried to load store feature types from uninitialised service.');
            }

            const response = await this.storeFeatureTypeService.getAll();

            if (response.ok) {
                this.setState({ storeFeatureTypes: this.sort(response.data) });
            }
        }
    }

    addStoreFeatureType(storeFeatureType) {
        this.setState(prevState => ({
            storeFeatureTypes: this.sort(prevState.storeFeatureTypes.concat(storeFeatureType))
        }));
    }

    async loadTradingPeriodTypes(force = false) {
        if (this.state.tradingPeriodTypes.length === 0 || force) {
            if (!Boolean(this.tradingPeriodTypeService)) {
                throw new Error('Tried to load trading period types from uninitialised service.');
            }

            const response = await this.tradingPeriodTypeService.getAll();

            if (response.ok) {
                this.setState({ tradingPeriodTypes: this.sort(response.data) });
            }
        }
    }

    addTradingPeriodType(tradingPeriodType) {
        this.setState(prevState => ({
            tradingPeriodTypes: this.sort(prevState.tradingPeriodTypes.concat(tradingPeriodType))
        }));
    }

    async loadPlaceTypes(force = false) {
        if (this.state.placeTypes.length === 0 || force) {
            if (!Boolean(this.placeTypeService)) {
                throw new Error('Tried to load place types from uninitialised service.');
            }

            const response = await this.placeTypeService.getAll();

            if (response.ok) {
                this.setState({ placeTypes: this.sort(response.data) });
            }
        }
    }

    addPlaceType(placeType) {
        this.setState(prevState => ({
            placeTypes: this.sort(prevState.placeTypes.concat(placeType))
        }));
    }
}