import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import PaginationActions from './PaginationActions';

export default class Pagination extends Component {
    static propTypes = {
        pageNumber: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        totalCount: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired
    };

    render() {
        const { pageNumber, pageSize, totalCount, onChange, ...rest } = this.props;

        return <TablePagination
            labelRowsPerPage="Page size:"
            labelDisplayedRows={({ from, to, count }) => `${from} - ${to} of ${count}`}
            count={totalCount}
            page={pageNumber - 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[25, 50, 75, 100]}
            onChangePage={(_, p) => onChange({ pageNumber: p + 1, pageSize })}
            onChangeRowsPerPage={e => onChange({ pageNumber: 1, pageSize: e.target.value })}
            ActionsComponent={PaginationActions}
            {...rest} />;
    }
}