import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '../../components/Autocomplete';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import BusyButton from '../../components/BusyButton';
import Button from '@material-ui/core/Button';
import ImageSelect from '../../components/ImageSelect';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors, hasAddressError, getAddressError } from '../../helpers';
import { drawerActionsStyle } from '../../styles';
import { EMPTY_GUID } from '../../constants';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class CreateShoppingLocationForm extends Component {
    static propTypes = {
        blobService: PropTypes.object.isRequired,
        shoppingLocationService: PropTypes.object.isRequired,
        shoppingLocationTypes: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onImageChange = this.onImageChange.bind(this);
        this.getShoppingLocationSuggestions = this.getShoppingLocationSuggestions.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        name: '',
        shoppingLocationTypeId: this.props.shoppingLocationTypes[0].id,
        parentShoppingLocationId: null,
        description: '',
        address: null,
        phoneNumber: '',
        websiteUrl: '',
        logoImageName: '',
        logoImageUrl: '',
        heroImageName: '',
        heroImageUrl: '',
        errors: {},
        busy: false
    }

    onAutocompleteSelect = name => option => {
        this.setState({
            [name]: option != null ? option.value : EMPTY_GUID
        });
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onImageChange = name => async file => {
        if (file != null) {
            const { blobName, blobUrl } = await this.props.blobService.upload('images', file);

            this.setState({
                [name + 'ImageName']: blobName,
                [name + 'ImageUrl']: blobUrl
            });
        }
        else {
            this.setState({
                [name + 'ImageName']: null,
                [name + 'ImageUrl']: null
            });
        }
    }

    async getShoppingLocationSuggestions(value) {
        const response = await this.props.shoppingLocationService.getSuggestions(value);

        if (response.ok) {
            return response.data.map(s => ({
                label: s.name,
                value: s.id
            }));
        }

        return [];
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;

        const { name,
            shoppingLocationTypeId,
            parentShoppingLocationId,
            description,
            address,
            phoneNumber,
            websiteUrl,
            logoImageName,
            heroImageName
        } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                name,
                shoppingLocationTypeId,
                parentShoppingLocationId,
                description,
                address,
                phoneNumber,
                websiteUrl,
                logoImageName,
                heroImageName
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors, busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, shoppingLocationTypes, onCancel } = this.props;
        const { name, shoppingLocationTypeId, description, address, phoneNumber, websiteUrl, logoImageUrl, heroImageUrl, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <TextField
                autoFocus
                id="name"
                label="Name"
                required
                margin="normal"
                value={name}
                helperText={errors.name}
                error={Boolean(errors.name)}
                onChange={this.onChange('name')} />

            <TextField
                id="shoppingLocationTypeId"
                label="Shopping location type"
                select
                required
                margin="normal"
                value={shoppingLocationTypeId}
                onChange={this.onChange('shoppingLocationTypeId')}>
                {shoppingLocationTypes.map(slt => <MenuItem key={slt.id} value={slt.id}>{slt.name}</MenuItem>)}
            </TextField>

            <Autocomplete
                label="Parent shopping location"
                margin="normal"
                placeholder="Search for a shopping location..."
                getSuggestions={this.getShoppingLocationSuggestions}
                onSelect={this.onAutocompleteSelect('parentShoppingLocationId')} />

            <TextField
                id="description"
                label="Description"
                margin="normal"
                multiline
                rows={5}
                value={description}
                helperText={errors.description}
                error={Boolean(errors.description)}
                onChange={this.onChange('description')} />

            <AddressAutocomplete
                label="Address"
                required
                fullWidth
                margin="normal"
                value={(Boolean(address) ? address.formattedAddress : '') || ''}
                helperText={getAddressError(errors)}
                error={hasAddressError(errors)}
                onChange={address => this.setState({ address })} />

            <TextField
                id="phoneNumber"
                label="Phone number"
                margin="normal"
                type="tel"
                value={phoneNumber}
                helperText={errors.phoneNumber}
                error={Boolean(errors.phoneNumber)}
                onChange={this.onChange('phoneNumber')} />

            <TextField
                id="websiteUrl"
                label="Website"
                margin="normal"
                value={websiteUrl}
                helperText={errors.websiteUrl}
                error={Boolean(errors.websiteUrl)}
                onChange={this.onChange('websiteUrl')} />

            <ImageSelect id="logoFile"
                label="Logo image"
                height={200}
                margin="normal"
                src={logoImageUrl}
                onChange={this.onImageChange('logo')} />

            <ImageSelect id="heroFile"
                label="Hero image"
                height={200}
                margin="normal"
                src={heroImageUrl}
                onChange={this.onImageChange('hero')} />

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(CreateShoppingLocationForm);