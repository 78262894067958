import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import Button from '@material-ui/core/Button';
import BusyButton from '../../components/BusyButton';
import { withStyles } from '@material-ui/core';
import { readErrors, hasAddressError, getAddressError } from '../../helpers';
import { drawerActionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class CreateAddressForm extends Component {
    static propTypes = {
        addressUsages: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        address: null,
        addressUsageId: this.props.addressUsages[0].id,
        errors: {},
        busy: false
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { address, addressUsageId } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                address,
                addressUsageId
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, addressUsages, onCancel } = this.props;
        const { address, addressUsageId, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <AddressAutocomplete
                autoFocus
                label="Address"
                required
                fullWidth
                margin="normal"
                value={(Boolean(address) ? address.formattedAddress : '') || ''}
                helperText={getAddressError(errors)}
                error={hasAddressError(errors)}
                onChange={address => this.setState({ address })} />

            <TextField
                id="addressUsageId"
                label="Address usage"
                select
                required
                margin="normal"
                value={addressUsageId}
                onChange={this.onChange('addressUsageId')}>
                {addressUsages.map(au => <MenuItem key={au.id} value={au.id}>{au.name}</MenuItem>)}
            </TextField>

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(CreateAddressForm);