import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { DatePicker } from '@material-ui/pickers';
import TradingHoursForm from './TradingHoursForm';
import Button from '@material-ui/core/Button';
import BusyButton from '../../components/BusyButton';
import { withStyles } from '@material-ui/core';
import { readErrors } from '../../helpers';
import { drawerActionsStyle } from '../../styles';
import { parse, startOfDay } from 'date-fns';
import { API_TIME_FORMAT } from '../../constants';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    selectListItem: {
        padding: theme.spacing(2)
    },
    ...drawerActionsStyle(theme)
});

class CreateTradingPeriodForm extends Component {
    static propTypes = {
        tradingPeriodTypes: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        tradingPeriodTypeId: this.props.tradingPeriodTypes[0].id,
        startDate: startOfDay(new Date()),
        endDate: null,
        monday: this.getDefaultHours(),
        tuesday: this.getDefaultHours(),
        wednesday: this.getDefaultHours(),
        thursday: this.getDefaultHours(),
        friday: this.getDefaultHours(),
        saturday: this.getDefaultHours(false),
        sunday: this.getDefaultHours(false),
        notes: '',
        errors: {},
        busy: false
    }

    getDefaultHours(isOpen = true) {
        // Default trading hours (9am - 5pm).
        return {
            isOpen,
            openTime: parse('09:00:00', API_TIME_FORMAT, startOfDay(new Date())),
            closeTime: parse('17:00:00', API_TIME_FORMAT, startOfDay(new Date()))
        };
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onDateChange = name => date => {
        this.setState({
            [name]: date
        });
    }

    onHoursChange = name => hours => {
        this.setState({ [name]: hours });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;

        const {
            tradingPeriodTypeId,
            startDate,
            endDate,
            notes,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday
        } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                tradingPeriodTypeId,
                startDate,
                endDate,
                notes,
                monday,
                tuesday,
                wednesday,
                thursday,
                friday,
                saturday,
                sunday
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, tradingPeriodTypes, onCancel } = this.props;

        const { tradingPeriodTypeId,
            startDate,
            endDate,
            notes,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            errors,
            busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <TextField
                autoFocus
                id="tradingPeriodTypeId"
                label="Trading period type"
                select
                required
                margin="normal"
                value={tradingPeriodTypeId}
                helperText={errors.tradingPeriodTypeId}
                error={Boolean(errors.tradingPeriodTypeId)}
                onChange={this.onChange('tradingPeriodTypeId')}>
                {tradingPeriodTypes.map(tpt => <MenuItem className={classes.selectListItem} key={tpt.id} value={tpt.id}>
                    <ListItemText primary={tpt.name} secondary={tpt.description} />
                </MenuItem>)}
            </TextField>

            <DatePicker
                autoOk
                required
                label="Start date"
                format="d MMMM yyyy"
                margin="normal"
                value={startDate}
                onChange={this.onDateChange('startDate')} />

            <DatePicker
                autoOk
                clearable
                label="End date"
                format="d MMMM yyyy"
                margin="normal"
                value={endDate}
                helperText={errors.endDate}
                error={Boolean(errors.endDate)}
                onChange={this.onDateChange('endDate')} />

            <TradingHoursForm label="Monday" hours={monday} errors={errors.monday || {}} onChange={this.onHoursChange('monday')} />
            <TradingHoursForm label="Tuesday" hours={tuesday} errors={errors.tuesday || {}} onChange={this.onHoursChange('tuesday')} />
            <TradingHoursForm label="Wednesday" hours={wednesday} errors={errors.wednesday || {}} onChange={this.onHoursChange('wednesday')} />
            <TradingHoursForm label="Thursday" hours={thursday} errors={errors.thursday || {}} onChange={this.onHoursChange('thursday')} />
            <TradingHoursForm label="Friday" hours={friday} errors={errors.friday || {}} onChange={this.onHoursChange('friday')} />
            <TradingHoursForm label="Saturday" hours={saturday} errors={errors.saturday || {}} onChange={this.onHoursChange('saturday')} />
            <TradingHoursForm label="Sunday" hours={sunday} errors={errors.sunday || {}} onChange={this.onHoursChange('sunday')} />

            <TextField
                id="notes"
                label="Notes"
                margin="normal"
                value={notes}
                multiline={true}
                rows={3}
                helperText={errors.notes}
                error={Boolean(errors.notes)}
                onChange={this.onChange('notes')} />

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(CreateTradingPeriodForm);