import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StoreImageAnalysesContainer from '../../containers/StoreImageAnalysesContainer';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import ImageAnalysisList from './ImageAnalysisList';
import ImageAnalysisTable from './ImageAnalysisTable';
import SideDrawer from '../../components/SideDrawer';
import ApplyImageAnalysisForm from './ApplyImageAnalysisForm';
import { Provider } from 'unstated';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { pageTitleStyle } from '../../styles';

const styles = theme => ({
    root: {
        width: '100%'
    },
    ...pageTitleStyle(theme)
});

class ImageAnalyses extends Component {
    static propTypes = {
        imageAnalysisService: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.imageAnalysesContainer = new StoreImageAnalysesContainer(props.imageAnalysisService, { sortBy: 'imageAnalysis.dateAnalysed' });

        this.closeDrawer = this.closeDrawer.bind(this);
    }

    state = {
        drawerContent: null
    };

    async componentDidMount() {
        await this.imageAnalysesContainer.load();
    }

    async applyAnalysis(imageAnalysisId, form) {
        const applied = await this.imageAnalysesContainer.apply(imageAnalysisId, form, true);

        if (applied) {
            this.closeDrawer();
        }
    }

    async deleteImageAnalysis(imageAnalysis) {
        const confirmed = window.confirm('Delete this image analysis?');

        if (confirmed) {
            await this.imageAnalysesContainer.delete(imageAnalysis.id);
        }
    }

    openApplyDrawer(imageAnalysis) {
        const drawerContent = <ApplyImageAnalysisForm
            imageAnalysis={imageAnalysis}
            onApply={form => this.applyAnalysis(imageAnalysis.id, form)}
            onCancel={this.closeDrawer} />;

        this.setState({ drawerContent });
    }

    closeDrawer() {
        this.setState({ drawerContent: null });
    }

    render() {
        const { classes } = this.props;
        const { drawerContent } = this.state;

        return <div className={classes.root}>
            <Typography className={classes.pageTitle} gutterBottom variant="h4">Image analyses</Typography>
            <Provider inject={[this.imageAnalysesContainer]}>
                <Hidden smUp implementation="css">
                    <ImageAnalysisList onSelect={ia => this.openApplyDrawer(ia)}
                        onDelete={ia => this.deleteImageAnalysis(ia)} />
                </Hidden>
                <Hidden xsDown implementation="css">
                    <ImageAnalysisTable onSelect={ia => this.openApplyDrawer(ia)}
                        onDelete={ia => this.deleteImageAnalysis(ia)} />
                </Hidden>
                <SideDrawer title="Review image analysis"
                    anchor="right"
                    open={Boolean(drawerContent)}
                    onClose={this.closeDrawer}>
                    {drawerContent}
                </SideDrawer>
            </Provider>
        </div>;
    }
}

export default withRouter(withStyles(styles)(ImageAnalyses));