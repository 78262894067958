import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import { paths } from '../App';

export default class SecureRoute extends Component {
    static propTypes = {
        authorised: PropTypes.func.isRequired,
        redirect: PropTypes.string
    };

    render() {
        const { authorised, redirect, ...rest } = this.props;
        const { location } = this.props;

        if (authorised()) {
            return <Route {...rest} />;
        }

        const to = {
            pathname: redirect || paths.SIGN_IN,
            state: {
                from: location.pathname
            }
        };

        return <Redirect to={to} />
    }
}