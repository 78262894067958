import ApiService from './ApiService';
import axios from 'axios';
import queryString from 'query-string';

export default class StoreService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async getAll(query, pageNumber, pageSize, sortBy) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores`;

        const request = {
            query,
            pageNumber,
            pageSize,
            sortBy
        };

        const response = await axios.get(url + '?' + queryString.stringify(request), { headers });

        return {
            ok: response.status === 200,
            data: response.data,
            totalCount: parseInt(response.headers['x-total-count'], 10)
        };
    }

    async getByRetailerId(retailerId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/stores`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getByShoppingLocationId(shoppingLocationId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/shopping-locations/${shoppingLocationId}/stores`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getSuggestions(partial) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/suggestions`;
        const query = { partial };
        const response = await axios.get(url + '?' + queryString.stringify(query), { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }


    async getById(storeId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async create(store) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores`;
        const response = await axios.post(url, store, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async copy(storeId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores`;
        const response = await axios.post(url, { copyFromStoreId: storeId }, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async update(storeId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async delete(storeId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getAddresses(storeId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/addresses`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getAddressById(storeId, addressId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/addresses/${addressId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async createAddress(storeId, address) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/addresses`;
        const response = await axios.post(url, address, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateAddress(storeId, addressId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/addresses/${addressId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async deleteAddress(storeId, addressId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/addresses/${addressId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getTradingPeriods(storeId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/trading-periods`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getTradingPeriodById(storeId, tradingPeriodId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/trading-periods/${tradingPeriodId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async createTradingPeriod(storeId, tradingPeriod) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/trading-periods`;
        const response = await axios.post(url, tradingPeriod, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }


    async updateTradingPeriod(storeId, tradingPeriodId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/trading-periods/${tradingPeriodId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async deleteTradingPeriod(storeId, tradingPeriodId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/trading-periods/${tradingPeriodId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getFeatures(storeId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/features`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getFeatureById(storeId, featureId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/features/${featureId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async addFeature(storeId, feature) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/features`;
        const response = await axios.post(url, feature, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateFeature(storeId, featureId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/features/${featureId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async removeFeature(storeId, featureId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/features/${featureId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getBrands(storeId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/brands`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async addBrand(storeId, brand) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/brands`;
        const response = await axios.post(url, brand, { headers });

        return {
            // Response code can be either OK or CREATED, depending on whether or not
            // the store and brand combination already existed.
            ok: response.status === 200 || response.status === 201,
            data: response.data
        };
    }

    async removeBrand(storeId, brandId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/brands/${brandId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getCategories(storeId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/categories`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async addCategory(storeId, category) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/categories`;
        const response = await axios.post(url, category, { headers });

        return {
            // Response code can be either OK or CREATED, depending on whether or not
            // the store and category combination already existed.
            ok: response.status === 200 || response.status === 201,
            data: response.data
        };
    }

    async removeCategory(storeId, categoryId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/categories/${categoryId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getImages(storeId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/images`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getImageById(storeId, imageId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/images/${imageId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async addImage(storeId, image) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/images`;
        const response = await axios.post(url, image, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateImage(storeId, imageId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/images/${imageId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async removeImage(storeId, imageId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/stores/${storeId}/images/${imageId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }
}