import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import Button from '@material-ui/core/Button';
import BusyButton from '../../components/BusyButton';
import { withStyles } from '@material-ui/core';
import { readErrors, hasAddressError, getAddressError } from '../../helpers';
import { drawerActionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class CreateAddressForm extends Component {
    static propTypes = {
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        address: null,
        errors: {},
        busy: false
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { address } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({ address });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, onCancel } = this.props;
        const { address, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <AddressAutocomplete
                autoFocus
                label="Address"
                required
                fullWidth
                margin="normal"
                value={(Boolean(address) ? address.formattedAddress : '') || ''}
                helperText={getAddressError(errors)}
                error={hasAddressError(errors)}
                onChange={address => this.setState({ address })} />

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(CreateAddressForm);