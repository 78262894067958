import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import BusyButton from '../../components/BusyButton';
import Button from '@material-ui/core/Button';
import ImageSelect from '../../components/ImageSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors } from '../../helpers';
import { drawerActionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class AddImageForm extends Component {
    static propTypes = {
        blobService: PropTypes.object.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.onImageChange = this.onImageChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        imageName: '',
        imageUrl: '',
        description: '',
        isFeatured: false,
        analyse: false,
        errors: {},
        busy: false
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onImageChange = async file => {
        if (file != null) {
            const { blobName, blobUrl } = await this.props.blobService.upload('images', file);

            this.setState({
                imageName: blobName,
                imageUrl: blobUrl
            });
        }
        else {
            this.setState({
                imageName: null,
                imageUrl: null
            });
        }
    }

    onCheckedChange = name => event => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { imageName, description, isFeatured, analyse } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                imageName,
                description,
                isFeatured,
                analyse
            });

            this.clearImage();

            this.setState({
                imageName: '',
                imageUrl: '',
                description: '',
                isFeatured: false,
                analyse: false,
                errors: {},
                busy: false
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors, busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, onCancel } = this.props;
        const { imageUrl, description, isFeatured, analyse, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <ImageSelect id="imageFile"
                label="Image"
                autoOpen
                height={200}
                margin="normal"
                src={imageUrl}
                error={errors.imageName}
                onChange={this.onImageChange}
                clearRef={clearFn => this.clearImage = clearFn} />

            <TextField
                id="description"
                label="Description"
                margin="normal"
                autoFocus
                multiline
                rows={5}
                value={description}
                helperText={errors.description}
                error={Boolean(errors.description)}
                onChange={this.onChange('description')} />

            <FormControlLabel control={
                <Switch color="primary"
                    checked={isFeatured}
                    onChange={this.onCheckedChange('isFeatured')} />}
                label="Featured image" />

            <FormControlLabel control={
                <Switch color="primary"
                    checked={analyse}
                    onChange={this.onCheckedChange('analyse')} />}
                label="Analyse image for store metadata" />

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Add</BusyButton>
                <Button onClick={onCancel}>Close</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(AddImageForm);