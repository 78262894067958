import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import withStyles from '@material-ui/core/styles/withStyles';
import Pagination from '../../components/Pagination';
import StoreProductsContainer from '../../containers/StoreProductsContainer';
import { Subscribe } from 'unstated';
import { actionsColumnStyle, tableRowStyle } from '../../styles';

const styles = theme => ({
    root: {
        width: '100%'
    },
    center: {
        textAlign: 'center'
    },
    ...tableRowStyle(theme),
    ...actionsColumnStyle(theme)
});

class ProductTable extends Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
    }

    onDelete(event, store) {
        event.stopPropagation();
        this.props.onDelete(store);
    }

    render() {
        const { classes, onSelect } = this.props;

        return <div className={classes.root}>
            <Subscribe to={[StoreProductsContainer]}>
                {pc => <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell className={classes.center}>Variants</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Stock</TableCell>
                            <TableCell className={classes.actionsColumn} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pc.state.list.map(p =>
                            <TableRow key={p.id} className={classes.tableRow} hover onClick={() => onSelect(p)}>
                                <TableCell>{p.name}</TableCell>
                                <TableCell className={classes.center}>
                                    {p.hasVariants && <DoneIcon />}
                                </TableCell>
                                {p.hasVariants && <TableCell>{Boolean(p.minPrice) && Boolean(p.maxPrice) && `${p.currency.symbol}${p.minPrice.toFixed(2)} - ${p.currency.symbol}${p.maxPrice.toFixed(2)} (${p.currency.name})`}</TableCell>}
                                {!p.hasVariants && <TableCell>{Boolean(p.price) && `${p.currency.symbol}${p.price.toFixed(2)} (${p.currency.name})`}</TableCell>}
                                <TableCell>{p.stockStatus.name} ({p.stockLevel})</TableCell>
                                <TableCell className={classes.actionsColumn}>
                                    <IconButton onClick={e => this.onDelete(e, p)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <Pagination colSpan={5}
                                pageNumber={pc.state.pageNumber}
                                pageSize={pc.state.pageSize}
                                totalCount={pc.state.totalCount}
                                onChange={({ pageNumber, pageSize }) => pc.loadPage(pageNumber, pageSize)} />
                        </TableRow>
                    </TableFooter>
                </Table>}
            </Subscribe>
        </div>;
    }
}

export default withStyles(styles)(ProductTable);