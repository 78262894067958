import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableView from '../../components/TableView';

export default class FeatureTable extends Component {
    static propTypes = {
        features: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { features, onSelect, onDelete } = this.props;

        const columns = [{
            name: 'Name',
            value: f => f.storeFeatureType.name
        }, {
            name: 'Description',
            value: f => f.storeFeatureType.description
        }, {
            name: 'Notes',
            value: f => f.notes
        }];

        return <TableView columns={columns}
            rows={features}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}
