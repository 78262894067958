import ApiService from './ApiService';
import axios from 'axios';
import queryString from 'query-string';

export default class StoreImageAnalysisService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async getAll(query, pageNumber, pageSize, sortBy) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/store-image-analyses`;

        const request = {
            query,
            pageNumber,
            pageSize,
            sortBy
        };

        const response = await axios.get(url + '?' + queryString.stringify(request), { headers });

        return {
            ok: response.status === 200,
            data: response.data,
            totalCount: parseInt(response.headers['x-total-count'], 10)
        };
    }

    async getById(imageAnalysisId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/store-image-analyses/${imageAnalysisId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async apply(imageAnalysisId, data) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/store-image-analyses/${imageAnalysisId}`;
        const response = await axios.put(url, data, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async delete(imageAnalysisId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/store-image-analyses/${imageAnalysisId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }
}