import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListView from '../../components/ListView';

export default class AddressList extends Component {
    static propTypes = {
        addresses: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { addresses, onSelect, onDelete } = this.props;

        return <ListView items={addresses}
            label={a => a.address.formattedAddress}
            caption={a => a.addressUsage.name}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}
