import { Container } from 'unstated';

const defaultOptions = {
    pageNumber: 1,
    pageSize: 25,
    sortBy: 'name'
};

export default class StoreProductsContainer extends Container {
    constructor(storeId, service, options) {
        super();

        this.storeId = storeId;
        this.service = service;

        const actualOptions = {
            ...defaultOptions,
            ...options
        };

        this.state = {
            ...actualOptions,
            list: [],
            totalCount: 0
        };
    }

    hasMore() {
        const { list, totalCount } = this.state;
        return list.length < totalCount;
    }

    async load() {
        const { pageNumber, pageSize, sortBy } = this.state;
        await this.loadPage(pageNumber, pageSize, sortBy);
    }

    async loadPage(pageNumber, pageSize, sortBy = this.state.sortBy) {
        const response = await this.service.getByStoreId(this.storeId, pageNumber, pageSize, sortBy);
        const { data: list, totalCount } = response;

        this.setState({ list, pageNumber, pageSize, totalCount });
    }

    async loadMore() {
        const { pageNumber, pageSize, sortBy } = this.state;
        const response = await this.service.getByStoreId(this.storeId, pageNumber + 1, pageSize, sortBy);
        const { data } = response;

        this.setState(prevState => ({
            list: prevState.list.concat(data),
            pageNumber: pageNumber + 1
        }));
    }

    async create(value, reload) {
        const response = await this.service.create({
            ...value,
            storeId: this.storeId
        });

        if (response.ok) {
            if (reload) {
                await this.load();
            } else {
                this.setState(prevState => ({
                    list: prevState.list.concat([response.data]),
                    totalCount: prevState.totalCount + 1
                }));
            }
        }

        return response.ok;
    }

    async delete(id) {
        const response = await this.service.delete(id);

        if (response.ok) {
            const list = this.state.list.slice();
            const index = list.findIndex(e => e.id === id);

            list.splice(index, 1);

            this.setState(prevState => ({
                list,
                totalCount: prevState.totalCount - 1
            }));
        }
    }
}