import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import ProductVariantContainer from '../../containers/ProductVariantContainer';
import LookupsContainer from '../../containers/LookupsContainer';
import AddImageForm from './AddImageForm';
import UpdateImageForm from './UpdateImageForm';
import UpdateVariantForm from './UpdateVariantForm';
import SideDrawer from '../../components/SideDrawer';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImageGrid from '../../components/ImageGrid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import AddIcon from '@material-ui/icons/Add';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { breadcrumbStyle, fabStyle, pageTitleStyle, tabsStyle } from '../../styles';
import { withRouter } from 'react-router-dom';
import { paths } from '../../App';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 0
    },
    detailsTab: {
        padding: theme.spacing(2)
    },
    imagesTab: {
        padding: theme.spacing(2),
        flexGrow: 1,
        position: 'relative'
    },
    ...breadcrumbStyle(theme),
    ...fabStyle(theme, theme.spacing(2)),
    ...pageTitleStyle(theme),
    ...tabsStyle(theme)
});

class ProductVariant extends Component {
    static propTypes = {
        productId: PropTypes.string.isRequired,
        productVariantId: PropTypes.string.isRequired,
        blobService: PropTypes.object.isRequired,
        productService: PropTypes.object.isRequired,
        productVariantService: PropTypes.object.isRequired,
        stockStatusService: PropTypes.object.isRequired,
        tab: PropTypes.string
    };

    static defaultProps = {
        tab: 'details'
    };

    tabs = {
        'details': 'Details',
        'images': 'Images'
    };

    constructor(props) {
        super(props);

        const notFound = Object.keys(this.tabs).indexOf(props.tab) < 0;

        this.productVariantContainer = new ProductVariantContainer(props.productService, props.productVariantService);
        this.lookupsContainer = new LookupsContainer(props);

        this.state = {
            drawerTitle: '',
            drawerContent: null,
            notification: null,
            notFound,
            ready: false
        };

        this.onTabChange = this.onTabChange.bind(this);
        this.openAddImageDrawer = this.openAddImageDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.hideNotification = this.hideNotification.bind(this);
    }

    async componentDidMount() {
        const { productId, productVariantId } = this.props;

        await Promise.all([
            this.productVariantContainer.load(productId, productVariantId),
            this.lookupsContainer.loadStockStatuses()
        ]);

        this.setState({ ready: true });
    }

    async updateVariant(form) {
        const ok = await this.productVariantContainer.update(form);

        if (ok) {
            this.showNotification('Variant saved');
        } else {
            this.showNotification('Unable to save! Please try again.');
        }
    }

    addImage(form) {
        return this.productVariantContainer.addImage(form);
    }

    async updateImage(imageId, form) {
        const ok = await this.productVariantContainer.updateImage(imageId, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async removeImage(image) {
        const confirmed = window.confirm(`Remove image ${image.imageName}?`);

        if (confirmed) {
            await this.productVariantContainer.removeImage(image.id);
        }
    }

    openAddImageDrawer() {
        const drawerContent = <AddImageForm
            blobService={this.props.blobService}
            onSave={form => this.addImage(form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Add image', drawerContent);
    }

    openUpdateImageDrawer(image) {
        const drawerContent = <UpdateImageForm
            image={image}
            blobService={this.props.blobService}
            onSave={form => this.updateImage(image.id, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit image', drawerContent);
    }

    onTabChange(_, value) {
        const { productId, productVariantId } = this.props;
        this.props.history.push(`/products/${productId}/variants/${productVariantId}/${value}`);
    }

    showNotification(notification) {
        this.setState({ notification });
    }

    hideNotification() {
        this.setState({ notification: null });
    }

    openDrawer(drawerTitle, drawerContent) {
        this.setState({
            drawerTitle,
            drawerContent
        });
    }

    closeDrawer() {
        this.setState({
            drawerTitle: '',
            drawerContent: null
        });
    }

    renderTabContents(tab) {
        switch (tab) {
            case 'details':
                return this.renderDetailsTab();
            case 'images':
                return this.renderImagesTab();
            default:
                return "No content";
        }
    }

    renderDetailsTab() {
        const { classes, blobService } = this.props;
        const { product, variant } = this.productVariantContainer.state;

        return <div className={classes.detailsTab}>
            <Subscribe to={[this.lookupsContainer]}>
                {lookups =>
                    <UpdateVariantForm
                        variant={variant}
                        attributes={product.attributes}
                        blobService={blobService}
                        stockStatuses={lookups.state.stockStatuses}
                        readOnly={product.isExternal}
                        onSave={form => this.updateVariant(form)} />}
            </Subscribe>
        </div>;
    }

    renderImagesTab() {
        const { classes } = this.props;

        return <div className={`${classes.imagesTab} ${classes.fabContainer}`}>
            <ImageGrid
                images={this.productVariantContainer.state.images}
                onSelect={img => this.openUpdateImageDrawer(img)}
                onDelete={img => this.removeImage(img)} />
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openAddImageDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    render() {
        const { classes, history, width, tab } = this.props;
        const { drawerTitle, drawerContent, notification, notFound } = this.state;
        const isDesktop = isWidthUp('lg', width);

        return <div className={classes.root}>
            {!notFound && <Fragment>
                <Subscribe to={[this.productVariantContainer]}>
                    {pvc => pvc.state.variant && <Fragment>
                        <Typography className={classes.pageTitle} gutterBottom variant="h4">{pvc.state.variant.attributeValuesDescription}</Typography>
                        <div className={classes.breadcrumbs}>
                            <Button size="small" onClick={() => history.push(paths.PRODUCTS)}>Products</Button> >
                            <Button size="small" onClick={() => history.push(`${paths.PRODUCTS}/${pvc.state.product.id}/variants`)}>{pvc.state.product.name}</Button> >
                            <span className={classes.breadcrumb}>{pvc.state.variant.sku}</span>
                        </div>
                        <Paper className={classes.tabs}>
                            <AppBar color="secondary" position="static">
                                <Tabs indicatorColor="primary"
                                    centered={isDesktop}
                                    variant={isDesktop ? 'fullWidth' : 'scrollable'}
                                    value={tab}
                                    onChange={this.onTabChange}>
                                    {Object.keys(this.tabs).map(slug =>
                                        <Tab key={slug} label={this.tabs[slug]} value={slug} />)}
                                </Tabs>
                            </AppBar>
                            {this.renderTabContents(tab)}
                        </Paper>
                    </Fragment>}
                </Subscribe>
                <SideDrawer title={drawerTitle}
                    anchor="right"
                    open={Boolean(drawerContent)}
                    onClose={this.closeDrawer}>
                    {drawerContent}
                </SideDrawer>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                    open={Boolean(notification)}
                    autoHideDuration={6000}
                    message={notification}
                    onClose={this.hideNotification} />
            </Fragment>}
            {notFound && "Not found"}
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(ProductVariant)));