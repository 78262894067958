import ApiService from './ApiService';
import axios from 'axios';
import queryString from 'query-string';

export default class RetailerService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async getSuggestions(partial) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/suggestions`;
        const query = { partial };
        const response = await axios.get(url + '?' + queryString.stringify(query), { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getAll(query, pageNumber, pageSize, sortBy) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers`;

        const request = {
            query,
            pageNumber,
            pageSize,
            sortBy
        };

        const response = await axios.get(url + '?' + queryString.stringify(request), { headers });

        return {
            ok: response.status === 200,
            data: response.data,
            totalCount: parseInt(response.headers['x-total-count'], 10)
        };
    }

    async getById(id) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${id}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async create(retailer) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers`;
        const response = await axios.post(url, retailer, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async update(retailerId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async delete(retailerId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getAddresses(retailerId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/addresses`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getAddressById(retailerId, addressId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/addresses/${addressId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async createAddress(retailerId, address) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/addresses`;
        const response = await axios.post(url, address, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateAddress(retailerId, addressId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/addresses/${addressId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async deleteAddress(retailerId, addressId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/addresses/${addressId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getUsers(retailerId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getUserById(retailerId, userId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users/${userId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async createUser(retailerId, user) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users`;
        const response = await axios.post(url, user, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateUser(retailerId, userId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users/${userId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async deleteUser(retailerId, userId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/retailers/${retailerId}/users/${userId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }
}