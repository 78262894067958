import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import Pagination from '../../components/Pagination';
import StoresContainer from '../../containers/StoresContainer';
import { Subscribe } from 'unstated';
import { actionsColumnStyle, tableRowStyle, inactiveStyle } from '../../styles';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        width: '100%'
    },
    center: {
        textAlign: 'center'
    },
    ...inactiveStyle(theme),
    ...tableRowStyle(theme),
    ...actionsColumnStyle(theme)
});

class StoreTable extends Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
    }

    onDelete(event, store) {
        event.stopPropagation();
        this.props.onDelete(store);
    }

    render() {
        const { classes, onSelect } = this.props;

        return <Paper className={classes.root}>
            <Subscribe to={[StoresContainer]}>
                {sc => <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Retailer</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Shopping location</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell className={classes.center}>Searchable</TableCell>
                            <TableCell className={classes.actionsColumn} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sc.state.list.map(s =>
                            <TableRow key={s.id} className={classNames(classes.tableRow, { [classes.inactive]: !s.isActive })} hover onClick={() => onSelect(s)}>
                                <TableCell>{s.retailer.name}</TableCell>
                        <TableCell>{s.locationalName || s.name}</TableCell>
                        <TableCell>{s.description}</TableCell>
                        <TableCell>{s.shoppingLocation != null && s.shoppingLocation.name}</TableCell>
                        <TableCell>{s.storeType.name}</TableCell>
                        <TableCell className={classes.center}>
                            {s.isVisibleToPublic && <DoneIcon />}
                        </TableCell>
                        <TableCell className={classes.actionsColumn}>
                            <IconButton onClick={e => this.onDelete(e, s)}>
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                            </TableRow>
                    )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <Pagination colSpan={7}
                                pageNumber={sc.state.pageNumber}
                                pageSize={sc.state.pageSize}
                                totalCount={sc.state.totalCount}
                                onChange={({ pageNumber, pageSize }) => sc.loadPage(pageNumber, pageSize)} />
                        </TableRow>
                    </TableFooter>
                </Table>}
            </Subscribe>
        </Paper >;
    }
}

export default withStyles(styles)(StoreTable);