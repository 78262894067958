import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        '& > :first-child': {
            flexGrow: 1
        }
    },
    hours: {
        display: 'flex',
        flexGrow: 1,
        '& > div': {
            flex: 1,
            '&:not(:first-child)': {
                marginLeft: theme.spacing(1)
            }
        },
        marginBottom: theme.spacing(1)
    },
    open: {
        // color: green[700]
    },
    closed: {
        // color: red[700],
        // textDecoration: 'line-through'
    }
});

class TradingHoursForm extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        hours: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired
    };

    onCheckedChange = name => event => {
        this.props.onChange({ ...this.props.hours, [name]: event.target.checked });
    };

    onTimeChange = name => time => {
        this.props.onChange({ ...this.props.hours, [name]: time });
    }

    render() {
        const { classes, label, hours, errors } = this.props;
        const { isOpen, openTime, closeTime } = hours;

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={isOpen ? classes.open : classes.closed} variant="subtitle1">{label}</Typography>
                <FormControlLabel control={
                    <Switch color="primary"
                        checked={isOpen}
                        onChange={this.onCheckedChange('isOpen')} />}
                    label={isOpen ? 'Open' : 'Closed'}
                    labelPlacement="start" />
            </div>
            <Grow in={isOpen} unmountOnExit={true}>
                <div className={classes.hours}>
                    <TimePicker
                        autoOk
                        label="Opens"
                        value={openTime}
                        format="h:mm a"
                        helperText={errors.openTime}
                        error={Boolean(errors.openTime)}
                        onChange={this.onTimeChange('openTime')} />
                    <TimePicker
                        autoOk
                        label="Closes"
                        value={closeTime}
                        format="h:mm a"
                        helperText={errors.closeTime}
                        error={Boolean(errors.closeTime)}
                        onChange={this.onTimeChange('closeTime')} />
                </div>
            </Grow>
        </div>;
    }
}

export default withStyles(styles)(TradingHoursForm);