import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CategoriesContainer from '../../containers/CategoriesContainer';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import CategoryList from './CategoryList';
import CategoryTable from './CategoryTable';
import SideDrawer from '../../components/SideDrawer';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CreateCategoryForm from './CreateCategoryForm';
import UpdateCategoryForm from './UpdateCategoryForm';
import { Provider } from 'unstated';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { fabStyle, pageTitleStyle, searchBoxStyle } from '../../styles';
import Paper from '@material-ui/core/Paper';
import SearchInput from '../../components/SearchInput';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    title: {
        flex: 1.5
    },
    ...searchBoxStyle(theme),
    ...pageTitleStyle(theme),
    ...fabStyle(theme)
});

class Categories extends Component {
    static propTypes = {
        categoryService: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.categoriesContainer = new CategoriesContainer(props.categoryService);

        this.onSelectCategory = this.onSelectCategory.bind(this);
        this.openCreateDrawer = this.openCreateDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    state = {
        drawerTitle: '',
        drawerContent: null,
        ready: false
    };

    async componentDidMount() {
        const { query } = this.props;

        await this.categoriesContainer.setState({ query });
        await this.categoriesContainer.load();

        this.setState({ ready: true });
    }

    onSelectCategory(category) {
        this.props.history.push(`/categories/${category.id}`);
    }

    async createCategory(form, reload) {
        const { ok } = await this.categoriesContainer.create(form, reload);

        if (ok) {
            this.closeDrawer();
        }
    }

    async updateCategory(category, form) {
        const ok = await this.categoriesContainer.update(category, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteCategory(category) {
        const confirmed = window.confirm(`Delete category ${category.name}?`);

        if (confirmed) {
            await this.categoriesContainer.delete(category.id);
        }
    }

    openCreateDrawer() {
        const reloadAfterCreate = isWidthUp('sm', this.props.width);

        const drawerContent = <CreateCategoryForm
            onSave={form => this.createCategory(form, reloadAfterCreate)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Create category', drawerContent);
    }

    openUpdateDrawer(category) {
        const drawerContent = <UpdateCategoryForm
            category={category}
            onSave={form => this.updateCategory(category, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit category', drawerContent);
    }

    openDrawer(drawerTitle, drawerContent) {
        this.setState({
            drawerTitle,
            drawerContent
        });
    }

    closeDrawer() {
        this.setState({
            drawerTitle: '',
            drawerContent: null
        });
    }

    render() {
        const { classes, query } = this.props;
        const { drawerTitle, drawerContent, ready } = this.state;

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classNames(classes.pageTitle, classes.title)} variant="h4">Categories</Typography>
                <Paper className={classes.searchBox}>
                    <SearchInput
                        value={query}
                        onSearch={query => this.props.history.push(`/categories?query=${query}`)}
                        onClear={() => this.props.history.push('/categories')} />
                </Paper>
            </div>
            <Provider inject={[this.categoriesContainer]}>
                <Hidden smUp implementation="css">
                    <CategoryList onSelect={b => this.openUpdateDrawer(b)}
                        onDelete={c => this.deleteCategory(c)} />
                </Hidden>
                <Hidden xsDown implementation="css">
                    <CategoryTable onSelect={b => this.openUpdateDrawer(b)}
                        onDelete={c => this.deleteCategory(c)} />
                </Hidden>
                <SideDrawer title={drawerTitle}
                    anchor="right"
                    open={Boolean(drawerContent)}
                    onClose={this.closeDrawer}>
                    {drawerContent}
                </SideDrawer>
                {ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateDrawer}>
                    <AddIcon />
                </Fab>}
            </Provider>
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(Categories)));