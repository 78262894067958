import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BusyButton from '../../components/BusyButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors } from '../../helpers';
import { drawerActionsStyle } from '../../styles';
import difference from 'lodash/difference';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    image: {
        alignSelf: 'center',
        maxHeight: '150px',
        maxWidth: '100%',
        marginBottom: theme.spacing(2)
    },
    paper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto'
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    ignoredListSection: {
        backgroundColor: red[200],
    },
    ignore: {
        backgroundColor: red[200],
    },
    listActions: {
        alignSelf: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& > button:not(:last-child)': {
            marginRight: theme.spacing(1)
        }
    },
    ...drawerActionsStyle(theme)
});

class ApplyImageAnalysisForm extends Component {
    static propTypes = {
        imageAnalysis: PropTypes.object.isRequired,
        onApply: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        const { imageAnalysis } = props;

        const brands = [...imageAnalysis.brands];
        const uniqueSet = new Set(imageAnalysis.objects.concat(imageAnalysis.tags));
        const tags = [...uniqueSet];

        brands.sort();
        tags.sort();

        this.state = {
            brands,
            tags,
            ignore: [],
            selected: [],
            errors: {},
            busy: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onApply } = this.props;
        const { brands, tags } = this.state;

        try {
            this.setState({ busy: true });

            await onApply({
                brands,
                tags
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors, busy: false
                });
            } else {
                throw error;
            }
        }
    }

    onSelect(element) {
        const selected = this.state.selected.slice();
        const index = selected.indexOf(element);

        if (index >= 0) {
            selected.splice(index, 1);
        } else {
            selected.push(element);
        }

        selected.sort();

        this.setState({ selected });
    }

    addBrands() {
        this.setState(prevState => {
            const nextState = {
                brands: [...new Set(prevState.brands.concat(prevState.selected))],
                tags: difference(prevState.tags, prevState.selected),
                ignore: difference(prevState.ignore, prevState.selected),
                selected: []
            };

            nextState.ignore.sort();
            nextState.brands.sort();
            nextState.tags.sort();

            return nextState;
        });
    }

    addTags() {
        this.setState(prevState => {
            const nextState = {
                tags: [...new Set(prevState.tags.concat(prevState.selected))],
                brands: difference(prevState.brands, prevState.selected),
                ignore: difference(prevState.ignore, prevState.selected),
                selected: []
            };

            nextState.ignore.sort();
            nextState.brands.sort();
            nextState.tags.sort();

            return nextState;
        });
    }

    ignore() {
        this.setState(prevState => {
            const nextState = {
                ignore: [...new Set(prevState.ignore.concat(prevState.selected))],
                brands: difference(prevState.brands, prevState.selected),
                tags: difference(prevState.tags, prevState.selected),
                selected: []
            };

            nextState.ignore.sort();
            nextState.brands.sort();
            nextState.tags.sort();

            return nextState;
        });
    }

    render() {
        const { classes, imageAnalysis, onCancel } = this.props;
        const { brands, tags, ignore, selected, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <img className={classes.image} src={imageAnalysis.imageUrl} alt={`${imageAnalysis.storeName} meta`} />

            <Paper className={classes.paper}>
                <List className={classes.list} subheader={<li />} dense>
                    <li className={classes.listSection}>
                        <ul className={classes.ul}>
                            <ListSubheader>Brands ({brands.length})</ListSubheader>
                            {brands.map(b => <ListItem key={b}
                                button
                                selected={selected.includes(b)}
                                onClick={() => this.onSelect(b)}>
                                <ListItemText primary={b} />
                            </ListItem>)}
                        </ul>
                    </li>
                    <li className={classes.listSection}>
                        <ul className={classes.ul}>
                            <ListSubheader>Tags ({tags.length})</ListSubheader>
                            {tags.map(t => <ListItem key={t}
                                button
                                selected={selected.includes(t)}
                                onClick={() => this.onSelect(t)}>
                                <ListItemText primary={t} />
                            </ListItem>)}
                        </ul>
                    </li>
                    <li className={classes.ignoredListSection}>
                        <ul className={classes.ul}>
                            <ListSubheader>Ignored ({ignore.length})</ListSubheader>
                            {ignore.map(i => <ListItem key={i}
                                button
                                selected={selected.includes(i)}
                                onClick={() => this.onSelect(i)}>
                                <ListItemText primary={i} />
                            </ListItem>)}
                        </ul>
                    </li>
                </List>
                <Divider />
                <div className={classes.listActions}>
                    <Button onClick={() => this.addBrands()}>Brands</Button>
                    <Button onClick={() => this.addTags()}>Tags</Button>
                    <Button onClick={() => this.ignore()} className={classes.ignore}>Ignore</Button>
                </div>
            </Paper>

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Apply</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(ApplyImageAnalysisForm);