import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListView from '../../components/ListView';

export default class CategoryList extends Component {
    static propTypes = {
        categories: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { categories, onSelect, onDelete } = this.props;

        return <ListView items={categories}
            label={b => b.name}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}
