import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import BusyButton from '../../components/BusyButton';
import ImageSelect from '../../components/ImageSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors } from '../../helpers';
import { actionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    row: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            '&:last-child': {
                marginLeft: theme.spacing(1)
            },
            '&:not(:last-child)': {
                marginRight: theme.spacing(1)
            }
        }
    },
    ...actionsStyle(theme)
});

class UpdateRetailerForm extends Component {
    static propTypes = {
        retailer: PropTypes.object.isRequired,
        blobService: PropTypes.object.isRequired,
        retailerTypes: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.onLogoChange = this.onLogoChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        const { name,
            retailerType,
            description,
            phoneNumber,
            websiteUrl,
            logoImageName,
            logoImageUrl,
            isVisibleToPublic
        } = props.retailer;

        const { id: retailerTypeId } = retailerType;

        this.state = {
            name,
            retailerTypeId,
            description,
            phoneNumber,
            websiteUrl,
            logoImageName,
            logoImageUrl,
            isVisibleToPublic,
            errors: {},
            busy: false
        }
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onLogoChange = async file => {
        if (file != null) {
            const { blobName, blobUrl } = await this.props.blobService.upload('images', file);

            this.setState({
                logoImageName: blobName,
                logoImageUrl: blobUrl
            });
        }
        else {
            this.setState({
                logoImageName: null,
                logoImageUrl: null
            });
        }
    }

    onCheckedChange = name => event => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;

        const {
            name,
            retailerTypeId,
            description,
            phoneNumber,
            websiteUrl,
            logoImageName,
            isVisibleToPublic,
        } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                name,
                retailerTypeId,
                description,
                phoneNumber,
                websiteUrl,
                logoImageName,
                isVisibleToPublic
            });

            this.setState({ errors: {} });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({ errors });
            } else {
                throw error;
            }
        }
        finally {
            this.setState({ busy: false });
        }
    }

    render() {
        const { classes, retailerTypes } = this.props;
        const { name, retailerTypeId, description, phoneNumber, websiteUrl, logoImageUrl, isVisibleToPublic, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <div className={classes.row}>
                <div className={classes.column}>
                    <TextField
                        autoFocus
                        id="name"
                        label="Name"
                        required
                        margin="normal"
                        value={name}
                        helperText={errors.name}
                        error={Boolean(errors.name)}
                        onChange={this.onChange('name')} />

                    <TextField
                        id="retailerTypeId"
                        label="Retailer type"
                        select
                        required
                        margin="normal"
                        value={retailerTypeId}
                        onChange={this.onChange('retailerTypeId')}>
                        {retailerTypes.map(rt => <MenuItem key={rt.id} value={rt.id}>{rt.name}</MenuItem>)}
                    </TextField>

                    <TextField
                        id="description"
                        label="Description"
                        margin="normal"
                        multiline
                        rows={5}
                        value={description || ''}
                        helperText={errors.description || ''}
                        error={Boolean(errors.description)}
                        onChange={this.onChange('description')} />

                    <TextField
                        id="phoneNumber"
                        label="Phone number"
                        margin="normal"
                        type="tel"
                        value={phoneNumber || ''}
                        helperText={errors.phoneNumber}
                        error={Boolean(errors.phoneNumber)}
                        onChange={this.onChange('phoneNumber')} />

                    <TextField
                        id="websiteUrl"
                        label="Website"
                        margin="normal"
                        value={websiteUrl || ''}
                        helperText={errors.websiteUrl}
                        error={Boolean(errors.websiteUrl)}
                        onChange={this.onChange('websiteUrl')} />

                    <FormControl margin="normal">
                        <FormControlLabel control={
                            <Switch color="primary"
                                checked={isVisibleToPublic}
                                onChange={this.onCheckedChange('isVisibleToPublic')} />}
                            label="Show stores in search results" />
                    </FormControl>
                </div>
                <div className={classes.column}>
                    <ImageSelect id="logoFile"
                        label="Logo image"
                        height={250}
                        margin="normal"
                        src={logoImageUrl}
                        onChange={this.onLogoChange} />
                </div>
            </div>

            <div className={classes.actions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
            </div>
        </form>;
    }
}

export default withStyles(styles)(UpdateRetailerForm);