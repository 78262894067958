import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import BusyButton from '../../components/BusyButton';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors } from '../../helpers';
import { drawerActionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class CreateCategoryForm extends Component {
    static propTypes = {
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        name: '',
        description: '',
        isNearbyStoreCategory: false,
        errors: {},
        busy: false
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onCheckedChange = name => event => {
        this.setState({
            [name]: event.target.checked
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { name, description, isNearbyStoreCategory } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                name,
                description,
                isNearbyStoreCategory
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors, busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, onCancel } = this.props;
        const { name, description, isNearbyStoreCategory, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <TextField
                autoFocus
                id="name"
                label="Name"
                required
                margin="normal"
                value={name}
                helperText={errors.name}
                error={Boolean(errors.name)}
                onChange={this.onChange('name')} />

            <TextField
                id="description"
                label="Description"
                margin="normal"
                multiline
                rows={5}
                value={description}
                helperText={errors.description}
                error={Boolean(errors.description)}
                onChange={this.onChange('description')} />

            <FormControl margin="normal">
                <FormControlLabel control={
                    <Switch color="primary"
                        checked={isNearbyStoreCategory}
                        onChange={this.onCheckedChange('isNearbyStoreCategory')} />}
                    label="Show stores in this category as nearby" />
            </FormControl>

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(CreateCategoryForm);