import ApiService from './ApiService';
import axios from 'axios';

export default class ProductVariantService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async getById(productId, id) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants/${id}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async update(productId, id, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants/${id}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getImages(productId, id) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants/${id}/images`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getImageById(productId, productVariantId, imageId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants/${productVariantId}/images/${imageId}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async addImage(productId, productVariantId, image) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants/${productVariantId}/images`;
        const response = await axios.post(url, image, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateImage(productId, productVariantId, imageId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants/${productVariantId}/images/${imageId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async removeImage(productId, productVariantId, imageId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/products/${productId}/variants/${productVariantId}/images/${imageId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }
}