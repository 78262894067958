import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '../../components/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import BusyButton from '../../components/BusyButton';
import ImageSelect from '../../components/ImageSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors } from '../../helpers';
import { actionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    row: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            '&:last-child': {
                marginLeft: theme.spacing(1)
            },
            '&:not(:last-child)': {
                marginRight: theme.spacing(1)
            }
        }
    },
    ...actionsStyle(theme)
});

class UpdateShoppingLocationForm extends Component {
    static propTypes = {
        shoppingLocation: PropTypes.object.isRequired,
        blobService: PropTypes.object.isRequired,
        shoppingLocationService: PropTypes.object.isRequired,
        shoppingLocationTypes: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.getShoppingLocationSuggestions = this.getShoppingLocationSuggestions.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        const { name,
            shoppingLocationType,
            parentShoppingLocationId,
            parentShoppingLocationName,
            description,
            phoneNumber,
            websiteUrl,
            logoImageName,
            logoImageUrl,
            heroImageName,
            heroImageUrl,
            includeInSitemap
        } = props.shoppingLocation;

        const { id: shoppingLocationTypeId } = shoppingLocationType;

        this.state = {
            name,
            shoppingLocationTypeId,
            parentShoppingLocationId,
            parentShoppingLocationName,
            description,
            phoneNumber,
            websiteUrl,
            logoImageName,
            logoImageUrl,
            heroImageName,
            heroImageUrl,
            includeInSitemap,
            errors: {},
            busy: false
        }
    }

    onAutocompleteSelect = name => option => {
        this.setState({
            [name]: option != null ? option.value : null
        });
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onImageChange = name => async file => {
        if (file != null) {
            const { blobName, blobUrl } = await this.props.blobService.upload('images', file);

            this.setState({
                [name + 'ImageName']: blobName,
                [name + 'ImageUrl']: blobUrl
            });
        }
        else {
            this.setState({
                [name + 'ImageName']: null,
                [name + 'ImageUrl']: null
            });
        }
    }

    onCheckedChange = name => event => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    async getShoppingLocationSuggestions(value) {
        const response = await this.props.shoppingLocationService.getSuggestions(value);

        if (response.ok) {
            return response.data.map(s => ({
                label: s.name,
                value: s.id
            }));
        }

        return [];
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;

        const {
            name,
            shoppingLocationTypeId,
            parentShoppingLocationId,
            description,
            phoneNumber,
            websiteUrl,
            logoImageName,
            heroImageName,
            includeInSitemap
        } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                name,
                shoppingLocationTypeId,
                parentShoppingLocationId,
                description,
                phoneNumber,
                websiteUrl,
                logoImageName,
                heroImageName,
                includeInSitemap
            });

            this.setState({ errors: {} });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({ errors });
            } else {
                throw error;
            }
        }
        finally {
            this.setState({ busy: false });
        }
    }

    render() {
        const { classes, shoppingLocationTypes } = this.props;
        const { name, shoppingLocationTypeId, parentShoppingLocationName, description, phoneNumber, websiteUrl, logoImageUrl, heroImageUrl, includeInSitemap, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <div className={classes.row}>
                <div className={classes.column}>
                    <TextField
                        autoFocus
                        id="name"
                        label="Name"
                        required
                        margin="normal"
                        value={name}
                        helperText={errors.name}
                        error={Boolean(errors.name)}
                        onChange={this.onChange('name')} />

                    <TextField
                        id="shoppingLocationTypeId"
                        label="Shopping location type"
                        select
                        required
                        margin="normal"
                        value={shoppingLocationTypeId}
                        onChange={this.onChange('shoppingLocationTypeId')}>
                        {shoppingLocationTypes.map(slt => <MenuItem key={slt.id} value={slt.id}>{slt.name}</MenuItem>)}
                    </TextField>

                    <Autocomplete
                        label="Parent shopping location"
                        initialValue={parentShoppingLocationName}
                        margin="normal"
                        placeholder="Search for a shopping location..."
                        getSuggestions={this.getShoppingLocationSuggestions}
                        onSelect={this.onAutocompleteSelect('parentShoppingLocationId')} />

                    <TextField
                        id="description"
                        label="Description"
                        margin="normal"
                        multiline
                        rows={5}
                        value={description}
                        helperText={errors.description || ''}
                        error={Boolean(errors.description)}
                        onChange={this.onChange('description')} />

                    <TextField
                        id="phoneNumber"
                        label="Phone number"
                        margin="normal"
                        type="tel"
                        value={phoneNumber || ''}
                        helperText={errors.phoneNumber}
                        error={Boolean(errors.phoneNumber)}
                        onChange={this.onChange('phoneNumber')} />

                    <TextField
                        id="websiteUrl"
                        label="Website"
                        margin="normal"
                        value={websiteUrl || ''}
                        helperText={errors.websiteUrl}
                        error={Boolean(errors.websiteUrl)}
                        onChange={this.onChange('websiteUrl')} />
                </div>
                <div className={classes.column}>
                    <ImageSelect id="logoFile"
                        label="Logo image"
                        height={250}
                        margin="normal"
                        src={logoImageUrl}
                        onChange={this.onImageChange('logo')} />

                    <ImageSelect id="heroFile"
                        label="Hero image"
                        height={250}
                        margin="normal"
                        src={heroImageUrl}
                        onChange={this.onImageChange('hero')} />

                    <FormControlLabel control={
                        <Switch color="primary"
                            checked={includeInSitemap}
                            onChange={this.onCheckedChange('includeInSitemap')} />}
                        label="Include in sitemap" />
                </div>
            </div>

            <div className={classes.actions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
            </div>
        </form>;
    }
}

export default withStyles(styles)(UpdateShoppingLocationForm);