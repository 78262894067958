import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListView from '../../components/ListView';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

export default class TradingPeriodList extends Component {
    static propTypes = {
        tradingPeriods: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { tradingPeriods, onSelect, onDelete } = this.props;
        const renderDates = tp => `${format(parseISO(tp.startDate), 'd MMM yyyy')} - ${tp.endDate != null ? format(parseISO(tp.endDate), 'd MMM yyyy') : 'Ongoing'}`;

        return <ListView items={tradingPeriods}
            label={tp => tp.tradingPeriodType.name}
            caption={renderDates}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}