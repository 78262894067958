import React, { Component } from 'react';
import Map from './Map';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import BusyButton from '../../components/BusyButton';
import { readErrors } from '../../helpers';
import withStyles from '@material-ui/core/styles/withStyles';

const google = window.google;

const styles = theme => ({
    mapContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    map: {
        flex: 1
    },
    actions: {
        padding: theme.spacing(2),
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1)
        }
    }
})

class UpdatePolygonForm extends Component {
    static propTypes = {
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        polygon: PropTypes.array,
        onSave: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            polygon: this.pointsToPolygon(props.polygon),
            busy: false
        };

        this.onSave = this.onSave.bind(this);
    }

    pointsToPolygon(points) {
        return points
            ? new google.maps.Polyline({
                path: points.map(pt => new google.maps.LatLng({
                    lat: pt.latitude,
                    lng: pt.longitude
                }))
            })
            : null;
    }

    polygonToPoints(polygon) {
        return polygon
            ? polygon
                .getPath()
                .getArray()
                // Convert points to API model format.
                .map(pt => ({
                    latitude: pt.lat(),
                    longitude: pt.lng()
                }))
            : null;
    }

    setPolygon(polygon) {
        this.setState(prevState => {
            // Clear any existing polygon.
            if (prevState.polygon) {
                prevState.polygon.setMap(null);
            }

            return { polygon };
        });
    }

    async onSave() {
        const { onSave } = this.props;
        const { polygon } = this.state;

        try {
            this.setState({ busy: true });
            await onSave(this.polygonToPoints(polygon));
            this.setState({ errors: {} });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                alert('Error saving polygon data. ' + errors.polygon);
            } else {
                throw error;
            }
        }
        finally {
            this.setState({ busy: false });
        }
    }

    render() {
        const { classes, lat, lng } = this.props;
        const { polygon, busy } = this.state;

        return <Map containerElement={<div className={classes.mapContainer} />}
            mapElement={<div className={classes.map} />}
            lat={lat}
            lng={lng}
            draw={!polygon}
            polygon={polygon}
            onPolygonComplete={p => this.setPolygon(p)}>
            <div className={classes.actions}>
                <BusyButton busy={busy} variant="contained" color="primary" onClick={this.onSave}>Save</BusyButton>
                <Button disabled={polygon == null} onClick={() => this.setPolygon(null)}>Clear</Button>
            </div>
        </Map>;
    }
}

export default withStyles(styles)(UpdatePolygonForm);