import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip'
import Autocomplete from './Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutline from '@material-ui/icons/AddCircle';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: `${theme.spacing(3)}px`
    },
    chip: {
        margin: theme.spacing(0.5)
    },
    input: {
        marginTop: theme.spacing(2)
    }
});

class Tags extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        tags: PropTypes.array.isRequired,
        hyphenate: PropTypes.bool.isRequired,
        getSuggestions: PropTypes.func.isRequired,
        onAdd: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    static defaultProps = {
        autoFocus: false,
        label: 'Tags',
        placeholder: 'Add a new tag',
        hyphenate: true,
        getSuggestions: () => null
    };

    constructor(props) {
        super(props);

        this.state = {
            tagToAdd: ''
        };

        this.getTagSuggestions = this.getTagSuggestions.bind(this);
        this.onAutocompleteSelect = this.onAutocompleteSelect.bind(this);
    }

    onAutocompleteSelect(option) {
        if (Boolean(option)) {
            this.onAdd(option.value);
        }
    }

    onAdd(tag, focus = true) {
        const { tags, hyphenate, onAdd } = this.props;
        tag = tag.trim();

        if (hyphenate) {
            tag = tag.replace(/\s/g, '-');
        }

        const normalisedTags = tags.map(t => t.toUpperCase());

        if (tag.length > 0 && !normalisedTags.includes(tag.toUpperCase())) {
            onAdd(tag);
        }

        this.clear(focus);
    }

    onKeyPress(event, tag) {
        if (event.key === 'Enter') {
            this.onAdd(tag);

            event.preventDefault();
            event.stopPropagation();
        }
    }

    onBlur(tag) {
        this.onAdd(tag, false);
    }

    async getTagSuggestions(value) {
        const suggestions = await this.props.getSuggestions(value);

        if (Boolean(suggestions)) {
            return suggestions.map(s => ({
                label: s,
                value: s
            }));
        }

        return [];
    }

    render() {
        const { classes, autoFocus, tags, label, placeholder, hyphenate, getSuggestions, onAdd, onDelete, ...rest } = this.props;
        const { tagToAdd } = this.state;

        return <FormControl {...rest}>
            <InputLabel className={classes.label} shrink>
                {label}
            </InputLabel>
            {tags.length > 0 &&
                <div className={classes.chips}>
                    {tags.map((t, i) => <Chip className={classes.chip}
                        key={i}
                        label={t}
                        onDelete={() => onDelete(t)} />
                    )}
                </div>}
            <div>
                <Autocomplete autoFocus={autoFocus}
                    fullWidth
                    name="addTagField"
                    className={classes.input}
                    placeholder={placeholder}
                    clearRef={fn => this.clear = fn}
                    value={tagToAdd}
                    getSuggestions={this.getTagSuggestions}
                    onChange={value => this.setState({ tagToAdd: value })}
                    onSelect={this.onAutocompleteSelect}
                    onKeyPress={e => this.onKeyPress(e, tagToAdd)}
                    onBlur={() => this.onBlur(tagToAdd)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton name="addTagButton" onClick={() => this.onAdd(tagToAdd)}>
                                <AddCircleOutline />
                            </IconButton>
                        </InputAdornment>
                    }} />
            </div>
        </FormControl>;
    }
}

export default withStyles(styles)(Tags);