import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Subscribe } from 'unstated';
import AppContainer from '../containers/AppContainer';
import { withRouter } from 'react-router-dom';
import { paths } from '../App';

class SignOut extends Component {
    onGoogleSignOut(app) {
        const { history } = this.props;
        app.signOut().then(() => history.push(paths.HOME));
    }

    render() {
        return <Subscribe to={[AppContainer]}>
            {app => <ListItem button onClick={() => this.onGoogleSignOut(app)}>
                <ListItemIcon>
                    <LockOpenIcon />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
            </ListItem>}
        </Subscribe>;
    }
}

export default withRouter(SignOut);