import React, { Fragment } from 'react';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';

function Map(props) {
    const { lat, lng } = props;

    return <Fragment>
        <GoogleMap
            zoom={18}
            center={{ lat, lng }}>
            <Marker position={{ lat, lng }} />
        </GoogleMap>
        {props.children}
    </Fragment>;
}

export default withGoogleMap(Map);