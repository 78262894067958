import { Container } from 'unstated';

export default class ShoppingLocationContainer extends Container {
    constructor(shoppingLocationService, storeService) {
        super();

        this.shoppingLocationService = shoppingLocationService;
        this.storeService = storeService;
    }

    state = {
        shoppingLocation: null,
        addresses: [],
        stores: [],
        tradingPeriods: [],
        features: [],
        images: []
    };

    async load(shoppingLocationId) {
        const response = await this.shoppingLocationService.getById(shoppingLocationId);

        if (response.ok) {
            this.setState({ shoppingLocation: response.data },
                async () => await Promise.all([
                    this.loadAddresses(),
                    this.loadStores(),
                    this.loadTradingPeriods(),
                    this.loadFeatures(),
                    this.loadImages()
                ]));
        }

        return response.ok;
    }

    async loadAddresses() {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.getAddresses(shoppingLocation.id);

        if (response.ok) {
            this.setState({ addresses: response.data });
        }

        return response.ok;
    }

    async loadStores() {
        const { shoppingLocation } = this.state;
        const response = await this.storeService.getByShoppingLocationId(shoppingLocation.id);

        if (response.ok) {
            this.setState({ stores: response.data });
        }

        return response.ok;
    }

    async loadTradingPeriods() {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.getTradingPeriods(shoppingLocation.id);

        if (response.ok) {
            this.setState({ tradingPeriods: response.data });
        }

        return response.ok;
    }

    async loadFeatures() {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.getFeatures(shoppingLocation.id);

        if (response.ok) {
            this.setState({ features: response.data });
        }

        return response.ok;
    }

    async loadImages() {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.getImages(shoppingLocation.id);

        if (response.ok) {
            this.setState({ images: response.data });
        }

        return response.ok;
    }

    async update(changes) {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.update(shoppingLocation.id, {
            ...changes,
            timestamp: shoppingLocation.timestamp
        });

        if (response.ok) {
            this.setState({ shoppingLocation: response.data });
        }

        return response.ok;
    }

    async createAddress(shoppingLocationAddress) {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.createAddress(shoppingLocation.id, shoppingLocationAddress);

        if (response.ok) {
            await this.loadAddresses();
        }

        return response.ok;
    }

    async updateAddress(addressId, changes) {
        const { shoppingLocation } = this.state;
        const addresses = this.state.addresses.slice();
        const address = addresses.find(a => a.id === addressId);

        const response = await this.shoppingLocationService.updateAddress(shoppingLocation.id,
            addressId, {
                ...changes,
                timestamp: address.timestamp
            });

        if (response.ok) {
            // Reload address and image data as the primary address (including Google place ID) may have changed.
            await this.loadAddresses();
            await this.loadImages();
        }

        return response.ok;
    }

    async deleteAddress(addressId) {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.deleteAddress(shoppingLocation.id, addressId);

        if (response.ok) {
            const addresses = this.state.addresses.slice();
            const index = addresses.findIndex(a => a.id === addressId);

            addresses.splice(index, 1);

            this.setState({ addresses });
        }
    }

    async updatePolygon(polygon) {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.update(shoppingLocation.id, {
            polygon,
            timestamp: shoppingLocation.timestamp
        });

        if (response.ok) {
            this.setState({ shoppingLocation: response.data });
        }

        return response.ok;
    }

    async createStore(store) {
        const { shoppingLocation } = this.state;

        const response = await this.storeService.create({
            shoppingLocationId: shoppingLocation.id,
            ...store
        });

        // if (response.ok) {
        //     await this.loadStores();
        // }

        return response;
    }


    async deleteStore(storeId) {
        const response = await this.storeService.delete(storeId);

        if (response.ok) {
            const stores = this.state.stores.slice();
            const index = stores.findIndex(s => s.id === storeId);

            stores.splice(index, 1);

            this.setState({ stores });
        }
    }

    async createTradingPeriod(shoppingLocationTradingPeriod) {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.createTradingPeriod(shoppingLocation.id, shoppingLocationTradingPeriod);

        if (response.ok) {
            await this.loadTradingPeriods();
        }

        return response.ok;
    }

    async updateTradingPeriod(tradingPeriodId, changes) {
        const { shoppingLocation } = this.state;
        const tradingPeriods = this.state.tradingPeriods.slice();
        const tradingPeriod = tradingPeriods.find(tp => tp.id === tradingPeriodId);

        const response = await this.shoppingLocationService.updateTradingPeriod(shoppingLocation.id,
            tradingPeriodId, {
                ...changes,
                timestamp: tradingPeriod.timestamp
            });

        if (response.ok) {
            // Replace old trading period object with new one.
            const index = tradingPeriods.indexOf(tradingPeriod);
            tradingPeriods.splice(index, 1, response.data);

            this.setState({ tradingPeriods });
        }

        return response.ok;
    }

    async deleteTradingPeriod(tradingPeriodId) {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.deleteTradingPeriod(shoppingLocation.id, tradingPeriodId);

        if (response.ok) {
            const tradingPeriods = this.state.tradingPeriods.slice();
            const index = tradingPeriods.findIndex(tp => tp.id === tradingPeriodId);

            tradingPeriods.splice(index, 1);

            this.setState({ tradingPeriods });
        }
    }

    async addFeature(shoppingLocationFeature, reload) {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.addFeature(shoppingLocation.id, shoppingLocationFeature);

        if (response.ok) {
            await this.loadFeatures();
        }

        return response.ok;
    }

    async updateFeature(featureId, changes) {
        const { shoppingLocation } = this.state;
        const features = this.state.features.slice();
        const feature = features.find(f => f.id === featureId);

        const response = await this.shoppingLocationService.updateFeature(shoppingLocation.id,
            featureId, {
                ...changes,
                timestamp: feature.timestamp
            });

        if (response.ok) {
            // Replace old feature object with new one.
            const index = features.indexOf(feature);
            features.splice(index, 1, response.data);

            this.setState({ features });
        }

        return response.ok;
    }

    async removeFeature(featureId) {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.removeFeature(shoppingLocation.id, featureId);

        if (response.ok) {
            const features = this.state.features.slice();
            const index = features.findIndex(f => f.id === featureId);

            features.splice(index, 1);

            this.setState({ features });
        }
    }

    async addImage(shoppingLocationImage, reload) {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.addImage(shoppingLocation.id, shoppingLocationImage);

        if (response.ok) {
            await this.loadImages();
        }

        return response.ok;
    }

    async updateImage(imageId, changes) {
        const { shoppingLocation } = this.state;
        const images = this.state.images.slice();
        const image = images.find(i => i.id === imageId);

        const response = await this.shoppingLocationService.updateImage(shoppingLocation.id,
            imageId, {
                ...changes,
                timestamp: image.timestamp
            });

        if (response.ok) {
            // Replace old image object with new one.
            const index = images.indexOf(image);
            images.splice(index, 1, response.data);

            this.setState({ images });
        }

        return response.ok;
    }

    async removeImage(imageId) {
        const { shoppingLocation } = this.state;
        const response = await this.shoppingLocationService.removeImage(shoppingLocation.id, imageId);

        if (response.ok) {
            const images = this.state.images.slice();
            const index = images.findIndex(i => i.id === imageId);

            images.splice(index, 1);

            this.setState({ images });
        }
    }
}