import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BrandsContainer from '../../containers/BrandsContainer';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import BrandList from './BrandList';
import BrandTable from './BrandTable';
import SideDrawer from '../../components/SideDrawer';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CreateBrandForm from './CreateBrandForm';
import UpdateBrandForm from './UpdateBrandForm';
import { Provider } from 'unstated';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { fabStyle, pageTitleStyle, searchBoxStyle } from '../../styles';
import Paper from '@material-ui/core/Paper';
import SearchInput from '../../components/SearchInput';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    title: {
        flex: 1.5
    },
    ...searchBoxStyle(theme),
    ...pageTitleStyle(theme),
    ...fabStyle(theme)
});

class Brands extends Component {
    static propTypes = {
        brandService: PropTypes.object.isRequired,
        blobService: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.brandsContainer = new BrandsContainer(props.brandService);

        this.onSelectBrand = this.onSelectBrand.bind(this);
        this.openCreateDrawer = this.openCreateDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    state = {
        drawerTitle: '',
        drawerContent: null,
        ready: false
    };

    async componentDidMount() {
        const { query } = this.props;

        await this.brandsContainer.setState({ query });
        await this.brandsContainer.load();

        this.setState({ ready: true });
    }

    onSelectBrand(brand) {
        this.props.history.push(`/brands/${brand.id}`);
    }

    async createBrand(form, reload) {
        const { ok } = await this.brandsContainer.create(form, reload);

        if (ok) {
            this.closeDrawer();
        }
    }

    async updateBrand(brand, form) {
        const ok = await this.brandsContainer.update(brand, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteBrand(brand) {
        const confirmed = window.confirm(`Delete brand ${brand.name}?`);

        if (confirmed) {
            await this.brandsContainer.delete(brand.id);
        }
    }

    openCreateDrawer() {
        const reloadAfterCreate = isWidthUp('sm', this.props.width);

        const drawerContent = <CreateBrandForm
            blobService={this.props.blobService}
            onSave={form => this.createBrand(form, reloadAfterCreate)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Create brand', drawerContent);
    }

    openUpdateDrawer(brand) {
        const drawerContent = <UpdateBrandForm
            brand={brand}
            blobService={this.props.blobService}
            onSave={form => this.updateBrand(brand, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit brand', drawerContent);
    }

    openDrawer(drawerTitle, drawerContent) {
        this.setState({
            drawerTitle,
            drawerContent
        });
    }

    closeDrawer() {
        this.setState({
            drawerTitle: '',
            drawerContent: null
        });
    }

    render() {
        const { classes, query } = this.props;
        const { drawerTitle, drawerContent, ready } = this.state;

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classNames(classes.pageTitle, classes.title)} variant="h4">Brands</Typography>
                <Paper className={classes.searchBox}>
                    <SearchInput
                        value={query}
                        onSearch={query => this.props.history.push(`/brands?query=${query}`)}
                        onClear={() => this.props.history.push('/brands')} />
                </Paper>
            </div>
            <Provider inject={[this.brandsContainer]}>
                <Hidden smUp implementation="css">
                    <BrandList onSelect={b => this.openUpdateDrawer(b)}
                        onDelete={b => this.deleteBrand(b)} />
                </Hidden>
                <Hidden xsDown implementation="css">
                    <BrandTable onSelect={b => this.openUpdateDrawer(b)}
                        onDelete={b => this.deleteBrand(b)} />
                </Hidden>
                <SideDrawer title={drawerTitle}
                    anchor="right"
                    open={Boolean(drawerContent)}
                    onClose={this.closeDrawer}>
                    {drawerContent}
                </SideDrawer>
                {ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateDrawer}>
                    <AddIcon />
                </Fab>}
            </Provider>
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(Brands)));