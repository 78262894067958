import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import CategoriesContainer from '../../containers/CategoriesContainer';
import { Subscribe } from 'unstated';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    item: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    loadMore: {
        alignSelf: 'stretch',
        margin: theme.spacing(1)
    }
});

class CategoryList extends Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { classes, onSelect, onDelete } = this.props;

        return <div className={classes.root}>
            <Subscribe to={[CategoriesContainer]}>
                {cc => <Fragment>
                    <List>
                        {cc.state.list.map(c =>
                            <ListItem key={c.id} button divider classes={{container: classes.item}} onClick={() => onSelect(c)}>
                                <ListItemText primary={c.name} secondary={c.description} />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => onDelete(c)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                    {cc.hasMore() && <Button color="secondary" className={classes.loadMore} onClick={() => cc.loadMore()}>Show me more</Button>}
                </Fragment>}
            </Subscribe>
        </div>;
    }
}

export default withStyles(styles)(CategoryList);