import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableView from '../../components/TableView';

export default class VariantTable extends Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        variants: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { product, variants, onSelect, onDelete } = this.props;
        const { currency } = product;

        const columns = [{
            name: 'Description',
            value: v => v.attributeValuesDescription
        }, {
            name: 'Price',
            value: v => Boolean(v.price) && `${currency.symbol}${v.price.toFixed(2)} (${currency.name})`
        }, {
            name: 'Stock',
            value: v => `${v.stockStatus.name} (${v.stockLevel})`
        }];

        return <TableView columns={columns}
            rows={variants}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}