import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import CloudIcon from '@material-ui/icons/Cloud';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const styles = theme => ({
    image: {
        cursor: 'pointer'
    },
    imageTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    indicator: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        zIndex: 1
    },
    icon: {
        color: theme.palette.common.white
    }
});

class ImageGrid extends Component {
    static propTypes = {
        images: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    onDelete(e, img) {
        e.preventDefault();
        e.stopPropagation();

        this.props.onDelete(img);
    }

    onSelect(img) {
        this.props.onSelect(img);
    }

    render() {
        const { classes, width, images } = this.props;

        const cols = (() => {
            if (isWidthUp('lg', width)) return 6;
            if (isWidthUp('md', width)) return 4;
            if (isWidthUp('sm', width)) return 2;
            return 1;
        })();

        return <GridList cols={cols} spacing={8}>
            {images.map(img => <GridListTile key={img.id} className={classes.image} onClick={() => this.onSelect(img)}>
                {img.isFeatured && <StarIcon className={classes.indicator} color="inherit" />}
                {img.isExternal && <CloudIcon className={classes.indicator} color="inherit" />}
                <img src={img.imageUrl} alt={img.description} />
                <GridListTileBar classes={{ title: classes.imageTitle }}
                    title={<Fragment>
                        {img.imageName}
                    </Fragment>}
                    actionIcon={img.isExternal ? null : <IconButton onClick={e => this.onDelete(e, img)}>
                        <DeleteIcon className={classes.icon} />
                    </IconButton>} />
            </GridListTile>)}
        </GridList>;
    }
}

export default withStyles(styles)(withWidth()(ImageGrid));