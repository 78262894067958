import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import MainMenu from '../components/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Routes from '../components/Routes';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchService from '../services/SearchService';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        height: '100vh',
        overflow: 'hidden',
        position: 'relative',
        width: '100%'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            marginRight: 4
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    title: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    toolbar: theme.mixins.toolbar,
    content: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3)
        }
    }
});

class Layout extends Component {
    static propTypes = {
        config: PropTypes.object.isRequired,
        app: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.searchService = new SearchService(props.config.apiBaseUrl, () => props.app.getAccessToken());

        this.toggleMenu = this.toggleMenu.bind(this);
        this.openSettings = this.openSettings.bind(this);
        this.closeSettings = this.closeSettings.bind(this);
        this.navigate = this.navigate.bind(this);
        this.recreateSearchIndices = this.recreateSearchIndices.bind(this);
    }

    state = {
        mobileOpen: false,
        settingsAnchorEl: null
    };

    toggleMenu() {
        this.setState(prevState => ({
            mobileOpen: !prevState.mobileOpen
        }));
    }

    openSettings(e) {
        this.setState({ settingsAnchorEl: e.currentTarget });
    }

    closeSettings() {
        this.setState({ settingsAnchorEl: null });
    }

    navigate(item) {
        this.setState({ mobileOpen: false }, () => this.props.history.push(item.path));
    }

    async recreateSearchIndices() {
        this.closeSettings();

        const confirmed = window.confirm('All search indices will be recreated. Continue?');

        if (confirmed) {
            const { ok } = await this.searchService.createOrUpdateIndices();

            if (ok) {
                window.alert('Search indices successfully recreated. Indexing now in progress.')
            }
        }
    }

    render() {
        const { config, app, classes } = this.props;
        const { mobileOpen, settingsAnchorEl } = this.state;

        return <div className={classes.root}>
            <AppBar position="absolute" className={classes.appBar}>
                <Toolbar>
                    <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.toggleMenu}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                        {app.state.pageTitle}
                    </Typography>
                    <IconButton color="inherit" onClick={this.openSettings}>
                        <SettingsIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <MainMenu isOpen={mobileOpen}
                onClick={this.navigate}
                onClose={this.toggleMenu} />
            <Menu anchorEl={settingsAnchorEl}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(settingsAnchorEl)}
                onClose={this.closeSettings}>
                <MenuItem onClick={this.recreateSearchIndices}>Recreate search indices</MenuItem>
            </Menu>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Routes config={config} app={app} />
            </main>
        </div>;
    }
}

export default withRouter(withStyles(styles)(Layout));