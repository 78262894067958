import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import withStyles from '@material-ui/core/styles/withStyles';
import deburr from 'lodash/deburr';

const styles = theme => ({
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    }
});

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return <MenuItem selected={isHighlighted} component="div">
        <div>
            {parts.map((part, index) => {
                return part.highlight ?
                    <span key={String(index)} style={{ fontWeight: 500 }}>
                        {part.text}
                    </span> :
                    <strong key={String(index)} style={{ fontWeight: 300 }}>
                        {part.text}
                    </strong>;
            })}
        </div>
    </MenuItem>;
}

function getSuggestionValue(suggestion) {
    return suggestion.label;
}

class Autocomplete extends Component {
    static propTypes = {
        minLength: PropTypes.number.isRequired,
        delay: PropTypes.number.isRequired,
        initialValue: PropTypes.string,
        onChange: PropTypes.func,
        getSuggestions: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        clearRef: PropTypes.func
    };

    static defaultProps = {
        minLength: 2,
        delay: 300
    };

    timeout = null;

    constructor(props) {
        super(props);

        this.state = {
            value: props.initialValue || '',
            suggestions: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.onClear = this.onClear.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
    }

    componentDidMount() {
        if (Boolean(this.props.clearRef)) {
            this.props.clearRef(this.onClear);
        }
    }

    renderInputComponent(inputProps) {
        const { classes, inputRef, ...other } = inputProps;

        const clearButtonAdornment = <InputAdornment position="end">
            <IconButton aria-label="Clear" onClick={this.onClear}>
                <Clear />
            </IconButton>
        </InputAdornment>;

        return <TextField
            fullWidth
            inputRef={inputRef}
            InputProps={{
                classes: {
                    input: classes.input,
                },
                endAdornment: Boolean(this.state.value) ? clearButtonAdornment : null
            }}
            {...other} />;
    }

    async getSuggestions(value, load) {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength < this.props.minLength) {
            return [];
        }

        if (this.timeout != null) {
            clearTimeout(this.timeout);
        }

        // Only fetch suggestions if no input has been received for delay ms.
        const suggestions = await new Promise(res => this.timeout = setTimeout(() => load(value).then(res), this.props.delay));

        this.timeout = null;

        return suggestions;
    }

    onClear(focus = true) {
        this.setState({ value: '' });

        if (this.props.onChange) {
            this.props.onChange('');
        }

        this.props.onSelect(null);

        if (focus) {
            this.inputEl.focus();
        }
    }

    handleSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason === 'input-focused') {
            // Don't fetch suggestions when field is just focused.
            return;
        }

        const suggestions = await this.getSuggestions(value, this.props.getSuggestions)
        this.setState({ suggestions });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    handleChange = (_, { newValue }) => {
        this.setState({ value: newValue });

        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
    };

    handleSuggestionSelected = (_, { suggestion }) => {
        this.props.onSelect(suggestion);
        this.inputEl.focus();
    };

    render() {
        const { classes, minLength, delay, initialValue, getSuggestions, onChange, onSelect, clearRef, ...rest } = this.props;

        const autosuggestProps = {
            renderInputComponent: this.renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            onSuggestionSelected: this.handleSuggestionSelected,
            renderSuggestion
        };

        return <Autosuggest
            {...autosuggestProps}
            inputProps={{
                classes,
                inputRef: el => this.inputEl = el,
                value: this.state.value,
                onChange: this.handleChange,
                ...rest
            }}
            theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} square>
                    {options.children}
                </Paper>
            )}
        />;
    }
}

export default withStyles(styles)(Autocomplete);