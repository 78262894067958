export const actionsColumnStyle = theme => ({
    actionsColumn: {
        padding: theme.spacing(0.5),
        width: '1%',
        whiteSpace: 'nowrap'
    }
});

export const actionsStyle = theme => ({
    actions: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            alignSelf: 'center'
        },
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1)
        }
    }
});

export const breadcrumbStyle = theme => ({
    breadcrumbs: {
        marginBottom: theme.spacing(1),
        opacity: 0.5,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        '& > button:not(:first-child)': {
            marginLeft: theme.spacing(0.5)
        }
    },
    breadcrumb: {
        ...theme.typography.button,
        fontSize: '0.8125rem',
        padding: theme.spacing(1),
    }
});

export const drawerActionsStyle = theme => ({
    drawerActions: {
        alignSelf: 'center',
        marginTop: theme.spacing(2),
        '& > button:not(:last-child)': {
            marginRight: theme.spacing(1)
        }
    }
});

export const fabStyle = (theme, offset = 0) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3) + offset,
        right: theme.spacing(3) + offset,
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        }
    },
    fabContainer: {
        paddingBottom: theme.spacing(13),
        [theme.breakpoints.down('xs')]: {
            // Appropriate padding for Fab, less padding already on ul element (inherited from List).
            paddingBottom: theme.spacing(10)
        }
    }
});

export const inactiveStyle = theme => ({
    inactive: {
        textDecoration: 'line-through'
    }
});

export const pageTitleStyle = theme => ({
    pageTitle: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
});

export const searchBoxStyle = theme => ({
    searchBox: {
        flex: 1,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(2)
        }
    }
});

export const tableRowStyle = theme => ({
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        },
        cursor: 'pointer'
    }
})

export const tabsStyle = theme => ({
    tabs: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 0
    },
});