import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StoresContainer from '../../containers/StoresContainer';
import LookupsContainer from '../../containers/LookupsContainer';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import StoreList from './StoreList';
import StoreTable from './StoreTable';
import SideDrawer from '../../components/SideDrawer';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CreateStoreForm from './CreateStoreForm';
import { Provider, Subscribe } from 'unstated';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { fabStyle, pageTitleStyle, searchBoxStyle } from '../../styles';
import Paper from '@material-ui/core/Paper';
import SearchInput from '../../components/SearchInput';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    title: {
        flex: 1.5
    },
    ...searchBoxStyle(theme),
    ...pageTitleStyle(theme),
    ...fabStyle(theme)
});

class Stores extends Component {
    static propTypes = {
        query: PropTypes.string,
        storeService: PropTypes.object.isRequired,
        storeTypeService: PropTypes.object.isRequired,
        industryAreaService: PropTypes.object.isRequired,
        currencyService: PropTypes.object.isRequired,
        addressUsageService: PropTypes.object.isRequired,
        blobService: PropTypes.object.isRequired,
        retailerService: PropTypes.object.isRequired,
        shoppingLocationService: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.storesContainer = new StoresContainer(props.storeService);
        this.lookupsContainer = new LookupsContainer(props);

        this.onSelectStore = this.onSelectStore.bind(this);
        this.openCreateDrawer = this.openCreateDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    state = {
        drawerContent: null,
        ready: false
    };

    async componentDidMount() {
        const { query } = this.props;

        await this.storesContainer.setState({ query });

        await Promise.all([
            this.storesContainer.load(),
            this.lookupsContainer.loadAddressUsages(),
            this.lookupsContainer.loadCurrencies(),
            this.lookupsContainer.loadIndustryAreas(),
            this.lookupsContainer.loadStoreTypes()
        ]);

        this.setState({ ready: true });
    }

    onSelectStore(store) {
        this.props.history.push(`/stores/${store.id}`);
    }

    async createStore(form, reload) {
        const response = await this.storesContainer.create(form, reload);

        if (response.ok) {
            this.props.history.push(`/stores/${response.data.id}`);
        }
    }

    async deleteStore(store) {
        const confirmed = window.confirm(`Delete store ${store.name}?`);

        if (confirmed) {
            await this.storesContainer.delete(store.id);
        }
    }

    openCreateDrawer() {
        const reloadAfterCreate = isWidthUp('sm', this.props.width);

        const drawerContent = <Subscribe to={[LookupsContainer]}>
            {lookups => <CreateStoreForm
                blobService={this.props.blobService}
                retailerService={this.props.retailerService}
                shoppingLocationService={this.props.shoppingLocationService}
                storeTypes={lookups.state.storeTypes}
                industryAreas={lookups.state.industryAreas}
                currencies={lookups.state.currencies}
                addressUsages={lookups.state.addressUsages}
                onSave={form => this.createStore(form, reloadAfterCreate)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.setState({ drawerContent });
    }

    closeDrawer() {
        this.setState({ drawerContent: null });
    }

    render() {
        const { classes, query } = this.props;
        const { drawerContent, ready } = this.state;

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classNames(classes.pageTitle, classes.title)} variant="h4">Stores</Typography>
                <Paper className={classes.searchBox}>
                    <SearchInput
                        value={query}
                        onSearch={query => this.props.history.push(`/stores?query=${query}`)}
                        onClear={() => this.props.history.push('/stores')} />
                </Paper>
            </div>
            <Provider inject={[this.storesContainer, this.lookupsContainer]}>
                <Hidden smUp implementation="css">
                    <StoreList onSelect={this.onSelectStore}
                        onDelete={s => this.deleteStore(s)} />
                </Hidden>
                <Hidden xsDown implementation="css">
                    <StoreTable onSelect={this.onSelectStore}
                        onDelete={s => this.deleteStore(s)} />
                </Hidden>
                <SideDrawer title="Create store"
                    anchor="right"
                    open={Boolean(drawerContent)}
                    onClose={this.closeDrawer}>
                    {drawerContent}
                </SideDrawer>
                {ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateDrawer}>
                    <AddIcon />
                </Fab>}
            </Provider>
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(Stores)));