import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListView from '../../components/ListView';

export default class StoreList extends Component {
    static propTypes = {
        stores: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { stores, onSelect, onDelete } = this.props;

        return <ListView items={stores}
            label={s => s.name}
            caption={s => s.description}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}