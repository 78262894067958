import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ConfigService from './services/ConfigService';
import * as serviceWorker from './serviceWorker';

const runApp = config => {
    ReactDOM.render(<App config={config} />, document.getElementById('root'));
    serviceWorker.unregister();
};

// Load app config then spin up app.
new ConfigService()
    .load('/config.json')
    .then(runApp)
    .catch(error => console.log('Failed to load app config.', error.message));
