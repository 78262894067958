import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import BusyButton from '../../components/BusyButton';
import { withStyles } from '@material-ui/core';
import { readErrors } from '../../helpers';
import { drawerActionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    selectListItem: {
        padding: theme.spacing(2)
    },
    ...drawerActionsStyle(theme)
});

class UpdateFeatureForm extends Component {
    static propTypes = {
        shoppingLocationFeature: PropTypes.object.isRequired,
        shoppingLocationFeatureTypes: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        const { shoppingLocationFeatureType, value } = props.shoppingLocationFeature;
        const { id: shoppingLocationFeatureTypeId } = shoppingLocationFeatureType;

        this.state = {
            shoppingLocationFeatureTypeId,
            value,
            errors: {},
            busy: false
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { shoppingLocationFeatureTypeId, value } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                shoppingLocationFeatureTypeId,
                value
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, shoppingLocationFeatureTypes, onCancel } = this.props;
        const { shoppingLocationFeatureTypeId, value, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <TextField
                autoFocus
                id="shoppingLocationFeatureTypeId"
                label="Feature type"
                select
                required
                margin="normal"
                value={shoppingLocationFeatureTypeId}
                onChange={this.onChange('shoppingLocationFeatureTypeId')}>
                {shoppingLocationFeatureTypes.map(slft => <MenuItem  className={classes.selectListItem} key={slft.id} value={slft.id}>
                    <ListItemText primary={slft.name} secondary={slft.description} />
                </MenuItem>)}
            </TextField>

            <TextField
                id="value"
                label="Value"
                margin="normal"
                value={value}
                helperText={errors.value}
                error={Boolean(errors.value)}
                onChange={this.onChange('value')} />

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(UpdateFeatureForm);