export function valueOrDefault(value, defaultValue = null) {
    return Boolean(value) ? value : defaultValue;
}

export function readErrors(response) {
    if (!Boolean(response) || response.status !== 400 || typeof response.data !== 'object') {
        return null;
    }

    const { data } = response;
    const errors = {};

    for (const key in data) {
        errors[key] = data[key][0];
    }

    return errors;
}

export function getAddressError(errors) {
    return errors.address ||
        errors['address.unitNumber'] ||
        errors['address.streetNumber'] ||
        errors['address.streetName'] ||
        errors['address.suburb'] ||
        errors['address.state'] ||
        errors['address.stateName'] ||
        errors['address.postcode'] ||
        errors['address.country'] ||
        errors['address.countryCode'] ||
        errors['address.formattedAddress'] ||
        errors['address.latitude'] ||
        errors['address.longitude'] ||
        errors['address.googlePlaceId'];
}

export function hasAddressError(errors) {
    return Boolean(errors.address) ||
        Boolean(errors['address.unitNumber']) ||
        Boolean(errors['address.streetNumber']) ||
        Boolean(errors['address.streetName']) ||
        Boolean(errors['address.suburb']) ||
        Boolean(errors['address.state']) ||
        Boolean(errors['address.stateName']) ||
        Boolean(errors['address.postcode']) ||
        Boolean(errors['address.country']) ||
        Boolean(errors['address.countryCode']) ||
        Boolean(errors['address.formattedAddress']) ||
        Boolean(errors['address.latitude']) ||
        Boolean(errors['address.longitude']) ||
        Boolean(errors['address.googlePlaceId']);
}