import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import SignOut from './SignOut';
import { withStyles } from '@material-ui/core';
import menu from '../menu';

const drawerWidth = 270;

const styles = theme => ({
    drawer: {
        height: '100%'
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth
    },
    toolbar: theme.mixins.toolbar
});

class Menu extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired
    };

    render() {
        const { classes, isOpen, onClick, onClose } = this.props;

        const list = <div>
            <List component="nav">
                {menu.map((item, index) => item.divider ?
                    <Divider key={index} /> :
                    <ListItem key={index} button onClick={_ => onClick(item)}>
                        <ListItemIcon>
                            <Icon>{item.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>)}
                <Divider />
                <SignOut />
            </List>
        </div>;

        return <Fragment>
            <Hidden mdUp implementation="css">
                <Drawer variant="temporary"
                    open={isOpen}
                    onClose={onClose}
                    classes={{ paper: classes.drawerPaper }}
                    ModalProps={{ keepMounted: true }}>
                    {list}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer variant="permanent"
                    open
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}>
                    <div className={classes.toolbar} />
                    {list}
                </Drawer>
            </Hidden>
        </Fragment>;
    }
}

export default withStyles(styles)(Menu);