import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(4)
    },
    button: {
        flex: 1
    },
    media: {
        backgroundSize: 'contain',
        margin: theme.spacing(2)
    },
    input: {
        display: 'none'
    },
    backdrop: {
        backgroundColor: theme.palette.action.disabledBackground,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }
});

class ImageSelect extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        height: PropTypes.number,
        src: PropTypes.string,
        maxFileSize: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        autoOpen: PropTypes.bool.isRequired,
        changeable: PropTypes.bool.isRequired,
        removable: PropTypes.bool.isRequired,
        error: PropTypes.string,
        clearRef: PropTypes.func
    };

    static defaultProps = {
        autoOpen: false,
        height: 300,
        maxFileSize: 4096,
        changeable: true,
        removable: true
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onFileSelected = this.onFileSelected.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    state = {
        file: null,
        busy: false
    };

    componentDidMount() {
        if (Boolean(this.props.clearRef)) {
            this.props.clearRef(this.onClear);
        }

        if (this.props.autoOpen) {
            this.input.click();
        }
    }

    async onFileSelected(event) {
        const { files } = event.target;

        if (files.length > 0) {
            const maxFileSizeBytes = this.props.maxFileSize * 1024;

            if (files[0].size < maxFileSizeBytes) {
                this.setState({
                    file: URL.createObjectURL(files[0]),
                    busy: true
                });

                try {
                    await this.props.onChange(files[0]);
                }
                finally {
                    this.setState({ busy: false });
                }
            } else {
                window.alert(`File must be less than ${this.props.maxFileSize} KB. Please choose a different file.`);
            }
        }
    }

    onChange() {
        if (this.props.changeable) {
            this.input.click();
        } else {
            window.alert('This image cannot be changed.');
        }
    }

    onClear() {
        this.setState({ file: null }, () => this.props.onChange(null));
    }

    render() {
        const { classes, label, height, maxFileSize, src, onChange, autoOpen, changeable, removable, error, clearRef, ...rest } = this.props;
        const { file, busy } = this.state;

        return <FormControl {...rest}>
            <InputLabel shrink>{label}</InputLabel>
            <Card className={classes.card}>
                <CardActionArea className={classes.button} disabled={busy} onClick={this.onChange}>
                    {busy && <div className={classes.backdrop}><CircularProgress size={80} /></div>}
                    <CardMedia image={file || src || '/images/no-image.png'}
                        classes={{ root: classes.media }}
                        style={{ height }} />
                    <input type="file"
                        accept="image/*"
                        className={classes.input}
                        onChange={this.onFileSelected}
                        ref={input => this.input = input} />
                </CardActionArea>
                <CardActions>
                    {changeable && <Button disabled={busy} onClick={this.onChange}>Change</Button>}
                    {Boolean(src) && removable && <Button disabled={busy} onClick={this.onClear}>Remove</Button>}
                </CardActions>
            </Card>
            {Boolean(error) && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>;
    }
}

export default withStyles(styles)(ImageSelect);