import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ImageSelect from '../../components/ImageSelect';
import MenuItem from '@material-ui/core/MenuItem';
import BusyButton from '../../components/BusyButton';
import Tags from '../../components/Tags';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors, valueOrDefault } from '../../helpers';
import { actionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    row: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            '&:last-child': {
                marginLeft: theme.spacing(1)
            },
            '&:not(:last-child)': {
                marginRight: theme.spacing(1)
            }
        }
    },
    ...actionsStyle(theme)
});

class UpdateVariantForm extends Component {
    static propTypes = {
        variant: PropTypes.object.isRequired,
        attributes: PropTypes.array.isRequired,
        stockStatuses: PropTypes.array.isRequired,
        readOnly: PropTypes.bool.isRequired,
        onSave: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onAddTag = this.onAddTag.bind(this);
        this.onDeleteTag = this.onDeleteTag.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        const { attributeValues,
            sku,
            partNumber,
            heroImageName,
            heroImageUrl,
            cost,
            price,
            stockLevel,
            stockStatus,
            tags
        } = props.variant;

        const { id: stockStatusId } = stockStatus;

        this.state = {
            attributeValues,
            sku,
            partNumber,
            heroImageName,
            heroImageUrl,
            cost,
            price,
            stockLevel,
            stockStatusId,
            tags,
            errors: {},
            busy: false
        }
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onImageChange = name => async file => {
        if (file != null) {
            const { blobName, blobUrl } = await this.props.blobService.upload('images', file);

            this.setState({
                [name + 'ImageName']: blobName,
                [name + 'ImageUrl']: blobUrl
            });
        }
        else {
            this.setState({
                [name + 'ImageName']: null,
                [name + 'ImageUrl']: null
            });
        }
    }

    onAttributeChange = name => event => {
        const { value } = event.target;

        this.setState(prevState => {
            const attributeValues = prevState.attributeValues;
            attributeValues[name.toLowerCase()] = value;

            return {
                attributeValues
            };
        });
    }

    onAddTag(tag) {
        this.setState(prevState => ({
            tags: prevState.tags.concat(tag)
        }));
    };

    onDeleteTag(tag) {
        this.setState(prevState => {
            const tags = prevState.tags.slice();
            const index = tags.indexOf(tag);

            tags.splice(index, 1);

            return { tags };
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;

        const {
            attributeValues,
            sku,
            partNumber,
            heroImageName,
            cost,
            price,
            stockLevel,
            stockStatusId,
            tags
        } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                attributeValues,
                sku,
                partNumber,
                heroImageName,
                cost: valueOrDefault(cost),
                price: valueOrDefault(price),
                stockLevel,
                stockStatusId,
                tags
            });

            this.setState({ errors: {} });
        } catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors, busy: false
                });
            } else {
                throw error;
            }
        } finally {
            this.setState({ busy: false });
        }
    }

    render() {
        const { classes, attributes, stockStatuses, readOnly } = this.props;

        const {
            attributeValues,
            sku,
            partNumber,
            heroImageUrl,
            cost,
            price,
            stockLevel,
            stockStatusId,
            tags,
            errors,
            busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            {readOnly && <Typography variant="caption" color="error" gutterBottom>WARNING: This product was imported from an external source and should not be modified here.</Typography>}
            <div className={classes.row}>
                <div className={classes.column}>
                    <TextField
                        label="SKU"
                        required
                        margin="normal"
                        value={sku}
                        helperText={errors.sku}
                        error={Boolean(errors.sku)}
                        onChange={this.onChange('sku')} />

                    <TextField
                        label="Part number"
                        margin="normal"
                        value={partNumber}
                        helperText={errors.partNumber}
                        error={Boolean(errors.partNumber)}
                        onChange={this.onChange('partNumber')} />

                    <TextField
                        label="Cost"
                        margin="normal"
                        type="number"
                        value={cost || ''}
                        helperText={errors.cost}
                        error={Boolean(errors.cost)}
                        onChange={this.onChange('cost')} />

                    <TextField
                        label="Price"
                        margin="normal"
                        type="number"
                        value={price || ''}
                        helperText={errors.price}
                        error={Boolean(errors.price)}
                        onChange={this.onChange('price')} />

                    <TextField
                        label="Stock level"
                        required
                        margin="normal"
                        type="number"
                        value={stockLevel}
                        helperText={errors.stockLevel}
                        error={Boolean(errors.stockLevel)}
                        onChange={this.onChange('stockLevel')} />

                    <TextField
                        label="Stock status"
                        select
                        required
                        margin="normal"
                        value={stockStatusId}
                        onChange={this.onChange('stockStatusId')}>
                        {stockStatuses.map(ss => <MenuItem key={ss.id} value={ss.id}>{ss.name}</MenuItem>)}
                    </TextField>

                </div>
                <div className={classes.column}>
                    {attributes.map((a, i) => <TextField key={a}
                        label={a}
                        autoFocus={i === 0}
                        margin="normal"
                        value={attributeValues[a.toLowerCase()] || ''}
                        onChange={this.onAttributeChange(a)} />)}

                    <Tags id="tags"
                        tags={tags}
                        margin="normal"
                        onAdd={this.onAddTag}
                        onDelete={this.onDeleteTag} />

                    <ImageSelect id="heroFile"
                        label="Hero image"
                        height={250}
                        margin="normal"
                        src={heroImageUrl}
                        onChange={this.onImageChange('hero')} />
                </div>
            </div>

            <div className={classes.actions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
            </div>
        </form>;
    }
}

export default withStyles(styles)(UpdateVariantForm);