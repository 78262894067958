import { Container } from 'unstated';

export default class StoreContainer extends Container {
    constructor(storeService) {
        super();

        this.storeService = storeService;
    }

    state = {
        store: null,
        addresses: [],
        tradingPeriods: [],
        features: [],
        categories: [],
        images: []
    };

    async load(storeId) {
        const response = await this.storeService.getById(storeId);

        if (response.ok) {
            this.setState({ store: response.data },
                async () => await Promise.all([
                    this.loadAddresses(),
                    this.loadTradingPeriods(),
                    this.loadFeatures(),
                    this.loadCategories(),
                    this.loadImages()
                ]));
        }

        return response.ok;
    }

    async loadAddresses() {
        const { store } = this.state;
        const response = await this.storeService.getAddresses(store.id);

        if (response.ok) {
            this.setState({ addresses: response.data });
        }

        return response.ok;
    }

    async loadTradingPeriods() {
        const { store } = this.state;
        const response = await this.storeService.getTradingPeriods(store.id);

        if (response.ok) {
            this.setState({ tradingPeriods: response.data });
        }

        return response.ok;
    }

    async loadFeatures() {
        const { store } = this.state;
        const response = await this.storeService.getFeatures(store.id);

        if (response.ok) {
            this.setState({ features: response.data });
        }

        return response.ok;
    }

    async loadCategories() {
        const { store } = this.state;
        const response = await this.storeService.getCategories(store.id);

        if (response.ok) {
            this.setState({ categories: response.data });
        }

        return response.ok;
    }

    async loadImages() {
        const { store } = this.state;
        const response = await this.storeService.getImages(store.id);

        if (response.ok) {
            this.setState({ images: response.data });
        }

        return response.ok;
    }

    async update(changes) {
        const { store } = this.state;
        const response = await this.storeService.update(store.id, {
            ...changes,
            timestamp: store.timestamp
        });

        if (response.ok) {
            this.setState({ store: response.data });
        }

        return response.ok;
    }

    async copy() {
        // Note: Copy has no side-effects on the container's state.
        const { store } = this.state;
        const response = await this.storeService.copy(store.id);

        if (response.ok) {
            return response.data.id;
        }

        return null;
    }

    async createAddress(storeAddress) {
        const { store } = this.state;
        const response = await this.storeService.createAddress(store.id, storeAddress);

        if (response.ok) {
            await this.loadAddresses();
        }

        return response.ok;
    }

    async updateAddress(addressId, changes) {
        const { store } = this.state;
        const addresses = this.state.addresses.slice();
        const address = addresses.find(a => a.id === addressId);

        const response = await this.storeService.updateAddress(store.id,
            addressId, {
                ...changes,
                timestamp: address.timestamp
            });

        if (response.ok) {
            // Reload address and image data as the primary address (including Google place ID) may have changed.
            await this.loadAddresses();
            await this.loadImages();
        }

        return response.ok;
    }

    async deleteAddress(addressId) {
        const { store } = this.state;
        const response = await this.storeService.deleteAddress(store.id, addressId);

        if (response.ok) {
            const addresses = this.state.addresses.slice();
            const index = addresses.findIndex(a => a.id === addressId);

            addresses.splice(index, 1);

            this.setState({ addresses });
        }
    }

    async createTradingPeriod(storeTradingPeriod) {
        const { store } = this.state;
        const response = await this.storeService.createTradingPeriod(store.id, storeTradingPeriod);

        if (response.ok) {
            await this.loadTradingPeriods();
        }

        return response.ok;
    }

    async updateTradingPeriod(tradingPeriodId, changes) {
        const { store } = this.state;
        const tradingPeriods = this.state.tradingPeriods.slice();
        const tradingPeriod = tradingPeriods.find(tp => tp.id === tradingPeriodId);

        const response = await this.storeService.updateTradingPeriod(store.id,
            tradingPeriodId, {
                ...changes,
                timestamp: tradingPeriod.timestamp
            });

        if (response.ok) {
            // Replace old trading period object with new one.
            const index = tradingPeriods.indexOf(tradingPeriod);
            tradingPeriods.splice(index, 1, response.data);

            this.setState({ tradingPeriods });
        }

        return response.ok;
    }

    async deleteTradingPeriod(tradingPeriodId) {
        const { store } = this.state;
        const response = await this.storeService.deleteTradingPeriod(store.id, tradingPeriodId);

        if (response.ok) {
            const tradingPeriods = this.state.tradingPeriods.slice();
            const index = tradingPeriods.findIndex(tp => tp.id === tradingPeriodId);

            tradingPeriods.splice(index, 1);

            this.setState({ tradingPeriods });
        }
    }

    async addFeature(storeFeature, reload) {
        const { store } = this.state;
        const response = await this.storeService.addFeature(store.id, storeFeature);

        if (response.ok) {
            await this.loadFeatures();
        }

        return response.ok;
    }

    async updateFeature(featureId, changes) {
        const { store } = this.state;
        const features = this.state.features.slice();
        const feature = features.find(f => f.id === featureId);

        const response = await this.storeService.updateFeature(store.id,
            featureId, {
                ...changes,
                timestamp: feature.timestamp
            });

        if (response.ok) {
            // Replace old feature object with new one.
            const index = features.indexOf(feature);
            features.splice(index, 1, response.data);

            this.setState({ features });
        }

        return response.ok;
    }

    async removeFeature(featureId) {
        const { store } = this.state;
        const response = await this.storeService.removeFeature(store.id, featureId);

        if (response.ok) {
            const features = this.state.features.slice();
            const index = features.findIndex(f => f.id === featureId);

            features.splice(index, 1);

            this.setState({ features });
        }
    }

    async addCategory(storeCategory) {
        const { store } = this.state;
        const response = await this.storeService.addCategory(store.id, storeCategory);

        if (response.ok) {
            await this.loadCategories();
        }

        return response.ok;
    }

    async removeCategory(categoryId) {
        const { store } = this.state;
        const response = await this.storeService.removeCategory(store.id, categoryId);

        if (response.ok) {
            const categories = this.state.categories.slice();
            const index = categories.findIndex(c => c.id === categoryId);

            categories.splice(index, 1);

            this.setState({ categories });
        }
    }

    async addImage(storeImage, reload) {
        const { store } = this.state;
        const response = await this.storeService.addImage(store.id, storeImage);

        if (response.ok) {
            await this.loadImages();
        }

        return response.ok;
    }

    async updateImage(imageId, changes) {
        const { store } = this.state;
        const images = this.state.images.slice();
        const image = images.find(i => i.id === imageId);

        const response = await this.storeService.updateImage(store.id,
            imageId, {
                ...changes,
                timestamp: image.timestamp
            });

        if (response.ok) {
            // Replace old image object with new one.
            const index = images.indexOf(image);
            images.splice(index, 1, response.data);

            this.setState({ images });
        }

        return response.ok;
    }

    async removeImage(imageId) {
        const { store } = this.state;
        const response = await this.storeService.removeImage(store.id, imageId);

        if (response.ok) {
            const images = this.state.images.slice();
            const index = images.findIndex(i => i.id === imageId);

            images.splice(index, 1);

            this.setState({ images });
        }
    }
}