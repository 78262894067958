import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grow from '@material-ui/core/Grow';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '../../components/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import BusyButton from '../../components/BusyButton';
import Button from '@material-ui/core/Button';
import Tags from '../../components/Tags';
import ImageSelect from '../../components/ImageSelect';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors, hasAddressError, getAddressError } from '../../helpers';
import { drawerActionsStyle } from '../../styles';
import { EMPTY_GUID } from '../../constants';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    listHeading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500
    },
    listOption: {
        paddingLeft: theme.spacing(4)
    },
    ...drawerActionsStyle(theme)
});

class CreateStoreForm extends Component {
    static propTypes = {
        place: PropTypes.object,
        blobService: PropTypes.object.isRequired,
        retailerService: PropTypes.object.isRequired,
        storeTypes: PropTypes.array.isRequired,
        currencies: PropTypes.array.isRequired,
        industryAreas: PropTypes.array.isRequired,
        addressUsages: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            retailerId: EMPTY_GUID,
            storeIsRetailer: false,
            name: '',
            locationalName: '',
            storeTypeId: this.props.storeTypes[0].id,
            industrySectorId: this.props.industryAreas[0].sectors[0].id,
            currencyId: this.props.currencies[0].id,
            description: '',
            address: null,
            addressUsageId: this.props.addressUsages[0].id,
            phoneNumber: '',
            websiteUrl: '',
            tags: [],
            logoImageName: '',
            logoImageUrl: '',
            heroImageName: '',
            heroImageUrl: '',
            externalImagesVisible: true,
            isVisibleToPublic: true,
            errors: {},
            busy: false
        };

        if (props.place) {
            // Patch basic store info from Google place.
            const {
                name,
                address,
                formattedPhoneNumber: phoneNumber,
                website: websiteUrl
            } = props.place;

            this.state = {
                ...this.state,
                name: name || '',
                address,
                phoneNumber: phoneNumber || '',
                websiteUrl: websiteUrl || ''
            };
        }

        this.getRetailerSuggestions = this.getRetailerSuggestions.bind(this);
        this.onAddTag = this.onAddTag.bind(this);
        this.onDeleteTag = this.onDeleteTag.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onCheckedChange = (name, otherState = {}) => event => {
        this.setState({
            [name]: event.target.checked,
            ...otherState
        });
    };

    onAutocompleteSelect = (name, emptyValue = EMPTY_GUID) => option => {
        this.setState({
            [name]: option != null ? option.value : emptyValue
        });
    }

    onImageChange = name => async file => {
        if (file != null) {
            const { blobName, blobUrl } = await this.props.blobService.upload('images', file);

            this.setState({
                [name + 'ImageName']: blobName,
                [name + 'ImageUrl']: blobUrl
            });
        }
        else {
            this.setState({
                [name + 'ImageName']: null,
                [name + 'ImageUrl']: null
            });
        }
    }

    onAddTag(tag) {
        this.setState(prevState => ({
            tags: prevState.tags.concat(tag)
        }));
    };

    onDeleteTag(tag) {
        this.setState(prevState => {
            const tags = prevState.tags.slice();
            const index = tags.indexOf(tag);

            tags.splice(index, 1);

            return { tags };
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;

        const { retailerId,
            storeIsRetailer,
            name,
            locationalName,
            storeTypeId,
            industrySectorId,
            currencyId,
            description,
            address,
            addressUsageId,
            phoneNumber,
            websiteUrl,
            tags,
            logoImageName,
            heroImageName,
            externalImagesVisible,
            isVisibleToPublic } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                retailerId,
                storeIsRetailer,
                name,
                locationalName,
                storeTypeId,
                industrySectorId,
                currencyId,
                description,
                address,
                addressUsageId,
                phoneNumber,
                websiteUrl,
                tags,
                logoImageName,
                heroImageName,
                externalImagesVisible,
                isVisibleToPublic
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors, busy: false
                });
            } else {
                throw error;
            }
        }
    }

    async getRetailerSuggestions(value) {
        const response = await this.props.retailerService.getSuggestions(value);

        if (response.ok) {
            return response.data.map(s => ({
                label: s.name,
                value: s.id
            }));
        }

        return [];
    }

    render() {
        const { classes, storeTypes, industryAreas, currencies, addressUsages, onCancel } = this.props;
        const { storeIsRetailer, name, locationalName, storeTypeId, industrySectorId, currencyId, description, address, addressUsageId, websiteUrl, phoneNumber, tags, logoImageUrl, heroImageUrl, externalImagesVisible, isVisibleToPublic, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <Grow in={!storeIsRetailer} unmountOnExit={true}>
                <Autocomplete
                    autoFocus
                    label="Retailer"
                    required
                    margin="normal"
                    placeholder="Search for a retailer..."
                    helperText={errors.retailerId}
                    error={Boolean(errors.retailerId)}
                    getSuggestions={this.getRetailerSuggestions}
                    onSelect={this.onAutocompleteSelect('retailerId')} />
            </Grow>

            <FormControl>
                <FormControlLabel control={
                    <Switch color="primary"
                        checked={storeIsRetailer}
                        onChange={this.onCheckedChange('storeIsRetailer', { retailerId: EMPTY_GUID })} />}
                    label="This store is an independent retailer" />
                {storeIsRetailer && <FormHelperText>A new retailer will be automatically created for the store</FormHelperText>}
            </FormControl>

            <TextField
                id="name"
                label="Name"
                required
                margin="normal"
                value={name}
                helperText={errors.name}
                error={Boolean(errors.name)}
                onChange={this.onChange('name')} />

            <TextField
                id="locationalName"
                label="Locational name"
                margin="normal"
                value={locationalName}
                helperText={errors.locationalName}
                error={Boolean(errors.locationalName)}
                onChange={this.onChange('locationalName')} />

            <TextField
                id="storeTypeId"
                label="Store type"
                select
                required
                margin="normal"
                value={storeTypeId}
                onChange={this.onChange('storeTypeId')}>
                {storeTypes.map(st => <MenuItem key={st.id} value={st.id}>{st.name}</MenuItem>)}
            </TextField>

            <TextField
                id="industrySectorId"
                label="Industry sector"
                select
                required
                margin="normal"
                value={industrySectorId}
                onChange={this.onChange('industrySectorId')}>
                {industryAreas.map(ia =>
                    [<MenuItem key={ia.id} value={ia.id} className={classes.listHeading} disabled>{ia.name}</MenuItem>].concat(ia.sectors.map(is =>
                        <MenuItem key={is.id} value={is.id} className={classes.listOption}>{is.name}</MenuItem>)))}
            </TextField>

            <TextField
                id="currencyId"
                label="Currency"
                select
                required
                margin="normal"
                value={currencyId}
                onChange={this.onChange('currencyId')}>
                {currencies.map(c => <MenuItem key={c.id} value={c.id}>{c.description} ({c.name})</MenuItem>)}
            </TextField>

            <TextField
                id="description"
                label="Description"
                margin="normal"
                multiline
                rows={5}
                value={description}
                helperText={errors.description}
                error={Boolean(errors.description)}
                onChange={this.onChange('description')} />

            <AddressAutocomplete
                label="Address"
                fullWidth
                margin="normal"
                value={(Boolean(address) ? address.formattedAddress : '') || ''}
                helperText={getAddressError(errors)}
                error={hasAddressError(errors)}
                onChange={address => this.setState({ address })} />

            {Boolean(address) && <TextField
                id="addressUsage"
                label="Address usage"
                select
                required
                margin="normal"
                value={addressUsageId}
                onChange={this.onChange('addressUsageId')}>
                {addressUsages.map(au => <MenuItem key={au.id} value={au.id}>{au.name}</MenuItem>)}
            </TextField>}

            <TextField
                id="phoneNumber"
                label="Phone number"
                margin="normal"
                type="tel"
                value={phoneNumber}
                helperText={errors.phoneNumber}
                error={Boolean(errors.phoneNumber)}
                onChange={this.onChange('phoneNumber')} />

            <TextField
                id="websiteUrl"
                label="Website"
                margin="normal"
                value={websiteUrl}
                helperText={errors.websiteUrl}
                error={Boolean(errors.websiteUrl)}
                onChange={this.onChange('websiteUrl')} />

            <Tags id="tags"
                tags={tags}
                margin="normal"
                onAdd={this.onAddTag}
                onDelete={this.onDeleteTag} />

            <ImageSelect id="logoFile"
                label="Logo image"
                height={200}
                margin="normal"
                src={logoImageUrl}
                onChange={this.onImageChange('logo')} />

            <ImageSelect id="heroFile"
                label="Hero image"
                height={200}
                margin="normal"
                src={heroImageUrl}
                onChange={this.onImageChange('hero')} />

            <FormControl margin="normal">
                <FormControlLabel control={
                    <Switch color="primary"
                        checked={externalImagesVisible}
                        onChange={this.onCheckedChange('externalImagesVisible')} />}
                    label="Show external images" />
                <FormHelperText>External images will {!externalImagesVisible && 'NOT'} be shown in the store's image gallery.</FormHelperText>
            </FormControl>

            <FormControl margin="normal">
                <FormControlLabel control={
                    <Switch color="primary"
                        checked={isVisibleToPublic}
                        onChange={this.onCheckedChange('isVisibleToPublic')} />}
                    label="Show in search results" />
            </FormControl>

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(CreateStoreForm);