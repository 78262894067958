import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import ShoppingLocationContainer from '../../containers/ShoppingLocationContainer';
import LookupsContainer from '../../containers/LookupsContainer';
import Paper from '@material-ui/core/Paper';
import UpdateShoppingLocationForm from './UpdateShoppingLocationForm';
import CreateAddressForm from './CreateAddressForm';
import UpdateAddressForm from './UpdateAddressForm';
import CreateStoreForm from './CreateStoreForm';
import CreateTradingPeriodForm from './CreateTradingPeriodForm';
import UpdateTradingPeriodForm from './UpdateTradingPeriodForm';
import AddFeatureForm from './AddFeatureForm';
import UpdateFeatureForm from './UpdateFeatureForm';
import AddImageForm from './AddImageForm';
import UpdateImageForm from './UpdateImageForm';
import AddressTable from './AddressTable';
import AddressList from './AddressList';
import TradingPeriodTable from './TradingPeriodTable';
import TradingPeriodList from './TradingPeriodList';
import FeatureTable from './FeatureTable';
import FeatureList from './FeatureList';
import StoreTable from './StoreTable';
import StoreList from './StoreList';
import ImageGrid from '../../components/ImageGrid';
import SideDrawer from '../../components/SideDrawer';
import PlaceList from './PlaceList';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { breadcrumbStyle, fabStyle, pageTitleStyle, tabsStyle } from '../../styles';
import { withRouter } from 'react-router-dom';
import { paths } from '../../App';
import { format } from 'date-fns/esm';
import { API_DATE_FORMAT } from '../../constants';
import UpdatePolygonForm from './UpdatePolygonForm';
import storage from 'local-storage-fallback';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 0
    },
    detailsTab: {
        padding: theme.spacing(2)
    },
    addressesTab: {
        flexGrow: 1,
        position: 'relative'
    },
    storesTab: {
        flexGrow: 1,
        position: 'relative'
    },
    hoursTab: {
        flexGrow: 1,
        position: 'relative'
    },
    featuresTab: {
        flexGrow: 1,
        position: 'relative'
    },
    imagesTab: {
        padding: theme.spacing(2),
        flexGrow: 1,
        position: 'relative'
    },
    ...breadcrumbStyle(theme),
    ...fabStyle(theme, theme.spacing(2)),
    ...pageTitleStyle(theme),
    ...tabsStyle(theme)
});

class ShoppingLocation extends Component {
    static propTypes = {
        shoppingLocationId: PropTypes.string.isRequired,
        blobService: PropTypes.object.isRequired,
        retailerService: PropTypes.object.isRequired,
        shoppingLocationService: PropTypes.object.isRequired,
        storeService: PropTypes.object.isRequired,
        storeTypeService: PropTypes.object.isRequired,
        industryAreaService: PropTypes.object.isRequired,
        currencyService: PropTypes.object.isRequired,
        addressUsageService: PropTypes.object.isRequired,
        shoppingLocationTypeService: PropTypes.object.isRequired,
        shoppingLocationFeatureTypeService: PropTypes.object.isRequired,
        tradingPeriodTypeService: PropTypes.object.isRequired,
        tab: PropTypes.string
    };

    static defaultProps = {
        tab: 'details'
    };

    tabs = {
        'details': 'Details',
        'addresses': 'Addresses',
        'polygon': 'Polygon',
        'stores': 'Stores',
        'hours': 'Hours',
        'features': 'Features',
        'images': 'Images'
    };

    constructor(props) {
        super(props);

        const notFound = Object.keys(this.tabs).indexOf(props.tab) < 0;

        this.shoppingLocationContainer = new ShoppingLocationContainer(props.shoppingLocationService, props.storeService);
        this.lookupsContainer = new LookupsContainer(props);

        this.state = {
            drawerTitle: '',
            drawerContent: null,
            notification: null,
            notFound
        };

        this.onTabChange = this.onTabChange.bind(this);
        this.openPlacesDrawer = this.openPlacesDrawer.bind(this);
        this.openCreateAddressDrawer = this.openCreateAddressDrawer.bind(this);
        this.openCreateStoreDrawer = this.openCreateStoreDrawer.bind(this);
        this.openCreateTradingPeriodDrawer = this.openCreateTradingPeriodDrawer.bind(this);
        this.openAddFeatureDrawer = this.openAddFeatureDrawer.bind(this);
        this.openAddImageDrawer = this.openAddImageDrawer.bind(this);
        this.onSelectStore = this.onSelectStore.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.hideNotification = this.hideNotification.bind(this);
    }

    async componentDidMount() {
        const { shoppingLocationId } = this.props;

        await Promise.all([
            this.shoppingLocationContainer.load(shoppingLocationId),
            this.lookupsContainer.loadAddressUsages(),
            this.lookupsContainer.loadCurrencies(),
            this.lookupsContainer.loadIndustryAreas(),
            this.lookupsContainer.loadPlaceTypes(),
            this.lookupsContainer.loadShoppingLocationTypes(),
            this.lookupsContainer.loadStoreTypes(),
            this.lookupsContainer.loadShoppingLocationFeatureTypes(),
            this.lookupsContainer.loadTradingPeriodTypes()
        ]);

        this.setState({ ready: true });
    }

    async updateShoppingLocation(form) {
        const ok = await this.shoppingLocationContainer.update(form);

        if (ok) {
            this.showNotification('Shopping location saved');
        } else {
            this.showNotification('Unable to save! Please try again.');
        }
    }

    async createAddress(form) {
        const created = await this.shoppingLocationContainer.createAddress(form);

        if (created) {
            this.closeDrawer();
        }
    }

    async updateAddress(addressId, form) {
        const ok = await this.shoppingLocationContainer.updateAddress(addressId, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteAddress(address) {
        const confirmed = window.confirm(`Delete address ${address.address.formattedAddress}?`);

        if (confirmed) {
            await this.shoppingLocationContainer.deleteAddress(address.id);
        }
    }

    async updatePolygon(polygon) {
        const ok = await this.shoppingLocationContainer.updatePolygon(polygon);

        if (ok) {
            this.showNotification('Shopping location polygon saved');
        }
    }

    async createStore(form) {
        const response = await this.shoppingLocationContainer.createStore(form);

        if (response.ok) {
            this.props.history.push(`/stores/${response.data.id}`);
        }
    }

    async deleteStore(store) {
        const confirmed = window.confirm(`Delete store ${store.name}?`);

        if (confirmed) {
            await this.shoppingLocationContainer.deleteStore(store.id);
        }
    }

    convertHoursToApiFormat(hours) {
        const { isOpen, openTime, closeTime } = hours;

        return {
            isOpen,
            openTime: format(openTime, 'HH:mm:00'),
            closeTime: format(closeTime, 'HH:mm:00')
        };
    }

    getTradingPeriodFormPayload(form) {
        // Convert the JS dates and times so the API can understand them.
        return {
            ...form,
            startDate: format(form.startDate, API_DATE_FORMAT),
            endDate: form.endDate != null ? format(form.endDate, API_DATE_FORMAT) : null,
            monday: this.convertHoursToApiFormat(form.monday),
            tuesday: this.convertHoursToApiFormat(form.tuesday),
            wednesday: this.convertHoursToApiFormat(form.wednesday),
            thursday: this.convertHoursToApiFormat(form.thursday),
            friday: this.convertHoursToApiFormat(form.friday),
            saturday: this.convertHoursToApiFormat(form.saturday),
            sunday: this.convertHoursToApiFormat(form.sunday)
        };
    }

    async createTradingPeriod(form) {
        const payload = this.getTradingPeriodFormPayload(form);
        const created = await this.shoppingLocationContainer.createTradingPeriod(payload);

        if (created) {
            this.closeDrawer();
        }
    }

    async updateTradingPeriod(tradingPeriodId, form) {
        const payload = this.getTradingPeriodFormPayload(form);
        const ok = await this.shoppingLocationContainer.updateTradingPeriod(tradingPeriodId, payload);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteTradingPeriod(tradingPeriod) {
        const confirmed = window.confirm(`Delete trading period ${tradingPeriod.tradingPeriodType.name}?`);

        if (confirmed) {
            await this.shoppingLocationContainer.deleteTradingPeriod(tradingPeriod.id);
        }
    }

    async addFeature(form) {
        const created = await this.shoppingLocationContainer.addFeature(form);

        if (created) {
            this.closeDrawer();
        }
    }

    async updateFeature(featureId, form) {
        const ok = await this.shoppingLocationContainer.updateFeature(featureId, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async removeFeature(feature) {
        const confirmed = window.confirm(`Remove feature ${feature.shoppingLocationFeatureType.name}?`);

        if (confirmed) {
            await this.shoppingLocationContainer.removeFeature(feature.id);
        }
    }

    addImage(form) {
        return this.shoppingLocationContainer.addImage(form);
    }

    async updateImage(imageId, form) {
        const ok = await this.shoppingLocationContainer.updateImage(imageId, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async removeImage(image) {
        const confirmed = window.confirm(`Remove image ${image.imageName}?`);

        if (confirmed) {
            await this.shoppingLocationContainer.removeImage(image.id);
        }
    }

    onTabChange(_, value) {
        this.props.history.push(`/shopping-locations/${this.props.shoppingLocationId}/${value}`);
    }

    openCreateAddressDrawer() {
        const drawerContent = <CreateAddressForm
            onSave={form => this.createAddress(form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Create address', drawerContent);
    }

    openUpdateAddressDrawer(shoppingLocationAddress) {
        const drawerContent = <UpdateAddressForm
            shoppingLocationAddress={shoppingLocationAddress}
            onSave={form => this.updateAddress(shoppingLocationAddress.id, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit address', drawerContent);
    }

    async openPlacesDrawer() {
        const { shoppingLocationId, shoppingLocationService } = this.props;

        // We intercept the closeDrawer action so we can option the (secondary) create store drawer.
        const originalCloseDrawer = this.closeDrawer;

        this.closeDrawer = () => originalCloseDrawer()
            // Allow first drawer to close before opening second.
            .then(() => setTimeout(() => this.openCreateStoreDrawer(null), 250))
            .then(() => this.closeDrawer = originalCloseDrawer);

        const loadPlaceTypes = async (placeType) => {
            const response = await shoppingLocationService.getPlaces(shoppingLocationId, placeType);
            return response.ok ? response.data : [];
        };

        // We persist the default place type locally for convenience.
        const defaultPlaceType = storage.getItem('DefaultPlaceType') || 'store';

        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <PlaceList
                defaultPlaceType={defaultPlaceType}
                placeTypes={lookups.state.placeTypes}
                loadPlaces={placeType => loadPlaceTypes(placeType)}
                onPlaceTypeChange={placeType => storage.setItem('DefaultPlaceType', placeType)}
                onSelect={place => {
                    this.closeDrawer = originalCloseDrawer;
                    this.onSelectPlace(place.placeId);
                }} />}
        </Subscribe>;

        this.openDrawer('Nearby place suggestions', drawerContent);
    }

    async onSelectPlace(placeId) {
        await this.closeDrawer();

        const { shoppingLocationId, shoppingLocationService } = this.props;
        const response = await shoppingLocationService.getPlace(shoppingLocationId, placeId);
        const place = response.ok ? response.data : null;

        this.openCreateStoreDrawer(place);
    }

    async openCreateStoreDrawer(place) {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <CreateStoreForm
                place={place}
                blobService={this.props.blobService}
                retailerService={this.props.retailerService}
                storeTypes={lookups.state.storeTypes}
                industryAreas={lookups.state.industryAreas}
                currencies={lookups.state.currencies}
                addressUsages={lookups.state.addressUsages}
                onSave={form => this.createStore(form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Create store', drawerContent);
    }

    onSelectStore(store) {
        this.props.history.push(`/stores/${store.id}`);
    }

    openCreateTradingPeriodDrawer() {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <CreateTradingPeriodForm
                tradingPeriodTypes={lookups.state.tradingPeriodTypes}
                onSave={form => this.createTradingPeriod(form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Create trading period', drawerContent);
    }

    openUpdateTradingPeriodDrawer(shoppingLocationTradingPeriod) {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <UpdateTradingPeriodForm
                tradingPeriod={shoppingLocationTradingPeriod}
                tradingPeriodTypes={lookups.state.tradingPeriodTypes}
                onSave={form => this.updateTradingPeriod(shoppingLocationTradingPeriod.id, form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Edit trading period', drawerContent);
    }

    openAddFeatureDrawer() {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <AddFeatureForm
                shoppingLocationFeatureTypes={lookups.state.shoppingLocationFeatureTypes}
                onSave={form => this.addFeature(form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Add feature', drawerContent);
    }

    openUpdateFeatureDrawer(shoppingLocationFeature) {
        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <UpdateFeatureForm
                shoppingLocationFeature={shoppingLocationFeature}
                shoppingLocationFeatureTypes={lookups.state.shoppingLocationFeatureTypes}
                onSave={form => this.updateFeature(shoppingLocationFeature.id, form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Edit feature', drawerContent);
    }

    openAddImageDrawer() {
        const drawerContent = <AddImageForm
            blobService={this.props.blobService}
            onSave={form => this.addImage(form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Add image', drawerContent);
    }

    openUpdateImageDrawer(image) {
        const drawerContent = <UpdateImageForm
            image={image}
            blobService={this.props.blobService}
            onSave={form => this.updateImage(image.id, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit image', drawerContent);
    }

    openDrawer(drawerTitle, drawerContent) {
        this.setState({
            drawerTitle,
            drawerContent
        });
    }

    closeDrawer() {
        return new Promise(res => this.setState({
            drawerTitle: '',
            drawerContent: null
        }, res));
    }

    showNotification(notification) {
        this.setState({ notification });
    }

    hideNotification() {
        this.setState({ notification: null });
    }

    renderTabContents(tab) {
        switch (tab) {
            case 'details':
                return this.renderDetailsTab();
            case 'addresses':
                return this.renderAddressesTab();
            case 'polygon':
                return this.renderPolygonTab();
            case 'stores':
                return this.renderStoresTab();
            case 'hours':
                return this.renderHoursTab();
            case 'features':
                return this.renderFeaturesTab();
            case 'images':
                return this.renderImagesTab();
            default:
                return "No content";
        }
    }

    renderDetailsTab() {
        const { classes, blobService, shoppingLocationService } = this.props;

        return <div className={classes.detailsTab}>
            <Subscribe to={[this.lookupsContainer]}>
                {lookups =>
                    <UpdateShoppingLocationForm
                        shoppingLocation={this.shoppingLocationContainer.state.shoppingLocation}
                        shoppingLocationTypes={lookups.state.shoppingLocationTypes}
                        blobService={blobService}
                        shoppingLocationService={shoppingLocationService}
                        onSave={form => this.updateShoppingLocation(form)} />}
            </Subscribe>
        </div>;
    }

    renderAddressesTab() {
        const { classes } = this.props;

        return <div className={`${classes.addressesTab} ${classes.fabContainer}`}>
            <Hidden smUp implementation="css">
                <AddressList addresses={this.shoppingLocationContainer.state.addresses}
                    onSelect={a => this.openUpdateAddressDrawer(a)}
                    onDelete={a => this.deleteAddress(a)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <AddressTable addresses={this.shoppingLocationContainer.state.addresses}
                    onSelect={a => this.openUpdateAddressDrawer(a)}
                    onDelete={a => this.deleteAddress(a)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateAddressDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderPolygonTab() {
        return <Subscribe to={[this.shoppingLocationContainer]}>
            {slc => {
                const primaryAddress = slc.state.addresses.find(sla => sla.isPrimaryAddress);
                const { polygon } = slc.state.shoppingLocation;

                return Boolean(primaryAddress)
                    ? <UpdatePolygonForm
                        lat={primaryAddress.address.latitude}
                        lng={primaryAddress.address.longitude}
                        polygon={polygon}
                        onSave={p => this.updatePolygon(p)} />
                    : null;
            }}
        </Subscribe>;
    }

    renderStoresTab() {
        const { classes } = this.props;

        return <div className={`${classes.storesTab} ${classes.fabContainer}`}>
            <Hidden smUp implementation="css">
                <StoreList stores={this.shoppingLocationContainer.state.stores}
                    onSelect={this.onSelectStore}
                    onDelete={s => this.deleteStore(s)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <StoreTable stores={this.shoppingLocationContainer.state.stores}
                    onSelect={this.onSelectStore}
                    onDelete={s => this.deleteStore(s)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openPlacesDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderHoursTab() {
        const { classes } = this.props;

        return <div className={`${classes.hoursTab} ${classes.fabContainer}`}>
            <Hidden smUp implementation="css">
                <TradingPeriodList tradingPeriods={this.shoppingLocationContainer.state.tradingPeriods}
                    onSelect={tp => this.openUpdateTradingPeriodDrawer(tp)}
                    onDelete={tp => this.deleteTradingPeriod(tp)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <TradingPeriodTable tradingPeriods={this.shoppingLocationContainer.state.tradingPeriods}
                    onSelect={tp => this.openUpdateTradingPeriodDrawer(tp)}
                    onDelete={tp => this.deleteTradingPeriod(tp)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateTradingPeriodDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderFeaturesTab() {
        const { classes } = this.props;

        return <div className={`${classes.featuresTab} ${classes.fabContainer}`}>
            <Hidden smUp implementation="css">
                <FeatureList features={this.shoppingLocationContainer.state.features}
                    onSelect={f => this.openUpdateFeatureDrawer(f)}
                    onDelete={f => this.removeFeature(f)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <FeatureTable features={this.shoppingLocationContainer.state.features}
                    onSelect={f => this.openUpdateFeatureDrawer(f)}
                    onDelete={f => this.removeFeature(f)} />
            </Hidden>
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openAddFeatureDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderImagesTab() {
        const { classes } = this.props;

        return <div className={`${classes.imagesTab} ${classes.fabContainer}`}>
            <ImageGrid
                images={this.shoppingLocationContainer.state.images}
                onSelect={img => this.openUpdateImageDrawer(img)}
                onDelete={img => this.removeImage(img)} />
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openAddImageDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    render() {
        const { classes, history, width, tab } = this.props;
        const { drawerTitle, drawerContent, notification, notFound } = this.state;
        const isDesktop = isWidthUp('lg', width);

        return <div className={classes.root}>
            {!notFound && <Fragment>
                <Subscribe to={[this.shoppingLocationContainer]}>
                    {slc => slc.state.shoppingLocation && <Fragment>
                        <Typography className={classes.pageTitle} gutterBottom variant="h4">{slc.state.shoppingLocation.name}</Typography>
                        <div className={classes.breadcrumbs}>
                            <Button size="small" onClick={() => history.push(paths.SHOPPING_LOCATIONS)}>Shopping locations</Button> >
                        <span className={classes.breadcrumb}>{slc.state.shoppingLocation.name}</span>
                        </div>
                        <Paper className={classes.tabs}>
                            <AppBar color="secondary" position="static">
                                <Tabs indicatorColor="primary"
                                    centered={isDesktop}
                                    variant={isDesktop ? 'fullWidth' : 'scrollable'}
                                    value={tab}
                                    onChange={this.onTabChange}>
                                    {Object.keys(this.tabs).map(slug =>
                                        <Tab key={slug} label={this.tabs[slug]} value={slug} />)}
                                </Tabs>
                            </AppBar>
                            {this.renderTabContents(tab)}
                        </Paper>
                    </Fragment>}
                </Subscribe>
                <SideDrawer title={drawerTitle}
                    anchor="right"
                    open={Boolean(drawerContent)}
                    onClose={this.closeDrawer}>
                    {drawerContent}
                </SideDrawer>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                    open={Boolean(notification)}
                    autoHideDuration={6000}
                    message={notification}
                    onClose={this.hideNotification} />
            </Fragment>}
            {notFound && "Not found"}
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(ShoppingLocation)));