import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import BusyButton from '../../components/BusyButton';
import Button from '@material-ui/core/Button';
import ImageSelect from '../../components/ImageSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors, hasAddressError, getAddressError } from '../../helpers';
import { drawerActionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class CreateRetailerForm extends Component {
    static propTypes = {
        blobService: PropTypes.object.isRequired,
        retailerTypes: PropTypes.array.isRequired,
        addressUsages: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onLogoChange = this.onLogoChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        name: '',
        retailerTypeId: this.props.retailerTypes[0].id,
        description: '',
        address: null,
        addressUsageId: this.props.addressUsages[0].id,
        phoneNumber: '',
        websiteUrl: '',
        logoImageName: '',
        logoImageUrl: '',
        isVisibleToPublic: true,
        errors: {},
        busy: false
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onLogoChange = async file => {
        if (file != null) {
            const { blobName, blobUrl } = await this.props.blobService.upload('images', file);

            this.setState({
                logoImageName: blobName,
                logoImageUrl: blobUrl
            });
        }
        else {
            this.setState({
                logoImageName: null,
                logoImageUrl: null
            });
        }
    }

    onCheckedChange = name => event => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { name, retailerTypeId, description, address, addressUsageId, phoneNumber, websiteUrl, logoImageName, isVisibleToPublic } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                name,
                retailerTypeId,
                description,
                address,
                addressUsageId,
                phoneNumber,
                websiteUrl,
                logoImageName,
                isVisibleToPublic
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors, busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, retailerTypes, addressUsages, onCancel } = this.props;
        const { name, retailerTypeId, description, address, addressUsageId, phoneNumber, websiteUrl, logoImageUrl, isVisibleToPublic, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <TextField
                autoFocus
                id="name"
                label="Name"
                required
                margin="normal"
                value={name}
                helperText={errors.name}
                error={Boolean(errors.name)}
                onChange={this.onChange('name')} />

            <TextField
                id="retailerTypeId"
                label="Retailer type"
                select
                required
                margin="normal"
                value={retailerTypeId}
                onChange={this.onChange('retailerTypeId')}>
                {retailerTypes.map(rt => <MenuItem key={rt.id} value={rt.id}>{rt.name}</MenuItem>)}
            </TextField>

            <TextField
                id="description"
                label="Description"
                margin="normal"
                multiline
                rows={5}
                value={description}
                helperText={errors.description}
                error={Boolean(errors.description)}
                onChange={this.onChange('description')} />

            <AddressAutocomplete
                label="Address"
                required
                fullWidth
                margin="normal"
                value={(Boolean(address) ? address.formattedAddress : '') || ''}
                helperText={getAddressError(errors)}
                error={hasAddressError(errors)}
                onChange={address => this.setState({ address })} />

            <TextField
                id="addressUsage"
                label="Address usage"
                select
                required
                margin="normal"
                value={addressUsageId}
                onChange={this.onChange('addressUsageId')}>
                {addressUsages.map(au => <MenuItem key={au.id} value={au.id}>{au.name}</MenuItem>)}
            </TextField>

            <TextField
                id="phoneNumber"
                label="Phone number"
                margin="normal"
                type="tel"
                value={phoneNumber}
                helperText={errors.phoneNumber}
                error={Boolean(errors.phoneNumber)}
                onChange={this.onChange('phoneNumber')} />

            <TextField
                id="websiteUrl"
                label="Website"
                margin="normal"
                value={websiteUrl}
                helperText={errors.websiteUrl}
                error={Boolean(errors.websiteUrl)}
                onChange={this.onChange('websiteUrl')} />

            <ImageSelect id="logoFile"
                label="Logo image"
                height={200}
                margin="normal"
                src={logoImageUrl}
                onChange={this.onLogoChange} />

            <FormControl margin="normal">
                <FormControlLabel control={
                    <Switch color="primary"
                        checked={isVisibleToPublic}
                        onChange={this.onCheckedChange('isVisibleToPublic')} />}
                    label="Show stores in search results" />
            </FormControl>

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(CreateRetailerForm);