import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableView from '../../components/TableView';

export default class CategoryTable extends Component {
    static propTypes = {
        categories: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { categories, onSelect, onDelete } = this.props;

        const columns = [{
            name: 'Category',
            value: c => c.name
        }];

        return <TableView columns={columns}
            rows={categories}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}