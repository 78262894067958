import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableView from '../../components/TableView';

export default class AddressTable extends Component {
    static propTypes = {
        addresses: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { addresses, onSelect, onDelete } = this.props;

        const columns = [{
            name: 'Address',
            value: a => a.address.formattedAddress
        }, {
            name: 'Usage',
            value: a => a.addressUsage.name
        }];

        return <TableView columns={columns}
            rows={addresses}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}