import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tags from '../../components/Tags';
import Button from '@material-ui/core/Button';
import BusyButton from '../../components/BusyButton';
import { readErrors } from '../../helpers';
import { drawerActionsStyle } from '../../styles';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class UpdateBrandsForm extends Component {
    static propTypes = {
        brands: PropTypes.array.isRequired,
        brandService: PropTypes.object.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            brands: props.brands.slice(0),
            errors: {},
            busy: false
        };

        this.getBrandSuggestions = this.getBrandSuggestions.bind(this);
        this.onAddBrand = this.onAddBrand.bind(this);
        this.onDeleteBrand = this.onDeleteBrand.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async getBrandSuggestions(value) {
        const response = await this.props.brandService.getSuggestions(value);

        if (response.ok) {
            return response.data.map(s => s.name);
        }

        return [];
    }

    onAddBrand(brand) {
        this.setState(prevState => ({
            brands: prevState.brands.concat(brand)
        }));
    };

    onDeleteBrand(brand) {
        this.setState(prevState => {
            const brands = prevState.brands.slice();
            const index = brands.indexOf(brand);

            brands.splice(index, 1);

            return { brands };
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { brands } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                brands
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, onCancel } = this.props;
        const { brands, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <Tags id="brands"
                label="Brands"
                autoFocus
                placeholder="Add a new brand"
                tags={brands}
                hyphenate={false}
                margin="normal"
                getSuggestions={this.getBrandSuggestions}
                onAdd={this.onAddBrand}
                onDelete={this.onDeleteBrand} />

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Close</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(UpdateBrandsForm);