import ApiService from './ApiService';
import axios from 'axios';

export default class SearchService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async createOrUpdateIndices() {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/search`;

        const response = await axios.post(url,
            { indices: ['stores', 'products'] }, // List of indices to (re)create.
            { headers });

        return {
            ok: response.status === 202, // 202 - Accepted
        };
    }
}