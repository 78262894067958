import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import BusyButton from '../components/BusyButton';
import { GoogleLogin } from 'react-google-login';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { paths } from '../App';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    card: {
        padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        }
    },
    media: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: 70
    },
    content: {
        textAlign: 'center'
    },
    actions: {
        display: 'flex',
        justifyContent: 'center'
    },
    error: {
        color: theme.palette.error.main
    }
});

class SignIn extends Component {
    static propTypes = {
        app: PropTypes.object.isRequired,
        authService: PropTypes.object.isRequired,
        googleClientId: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.onGoogleSignInSuccess = this.onGoogleSignInSuccess.bind(this);
        this.onGoogleSignInFailure = this.onGoogleSignInFailure.bind(this);
    }

    state = {
        error: null,
        busy: false
    };

    async onGoogleSignInSuccess(e) {
        const { authService } = this.props;
        const response = await authService.signInExternal(e.tokenId, 'google');

        if (response.status === 200) {
            const { accessToken, refreshToken } = response.data;
            const { app, history, location } = this.props;
            const { state } = location;
            const from = state != null ? state.from : paths.HOME;

            app.signIn(accessToken, refreshToken).then(() => history.push(from));
        } else {
            this.setState({
                error: 'There was a problem signing you in. Please try again.',
                busy: false
            });
        }
    }

    onGoogleSignInFailure(e) {
        this.setState({
            error: 'There was a problem signing you in. Please try again.',
            busy: false
        });
    }

    render() {
        const { googleClientId, classes } = this.props;
        const { error, busy } = this.state;

        return <div className={classes.root}>
            <Card elevation={3} className={classes.card}>
                <CardMedia className={classes.media} image="/images/mavitra-logo-black.png" title="Mavitra" />
                <CardContent className={classes.content}>
                    <p>Mavitra administration portal. Authorised users only.</p>
                    {Boolean(error) && <p className={classes.error}>{error}</p>}
                </CardContent>
                <CardActions className={classes.actions}>
                    <GoogleLogin clientId={googleClientId}
                        onRequest={() => this.setState({ busy: true })}
                        onSuccess={this.onGoogleSignInSuccess}
                        onFailure={this.onGoogleSignInFailure}
                        render={e => <BusyButton busy={busy} color="primary" variant="contained" onClick={e.onClick}>Sign in</BusyButton>} />
                </CardActions>
            </Card>
        </div>;
    }
}

export default withRouter(withStyles(styles)(SignIn));