import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableView from '../../components/TableView';
import DoneIcon from '@material-ui/icons/Done';

export default class StoreTable extends Component {
    static propTypes = {
        stores: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    render() {
        const { stores, onSelect, onDelete } = this.props;

        const columns = [{
            name: 'Name',
            value: s => s.locationalName || s.name
        }, {
            name: 'Description',
            value: s => s.description
        }, {
            name: 'Shopping location',
            value: s => s.shoppingLocation != null ? s.shoppingLocation.name : null
        }, {
            name: 'Type',
            value: s => s.storeType.name
        }, {
            name: 'Searchable',
            value: s => s.isVisibleToPublic ? <DoneIcon /> : null
        }];

        return <TableView columns={columns}
            rows={stores}
            onSelect={onSelect}
            onDelete={onDelete} />
    }
}