import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '../../components/Autocomplete';
import Tags from '../../components/Tags';
import ImageSelect from '../../components/ImageSelect';
import BusyButton from '../../components/BusyButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors, valueOrDefault } from '../../helpers';
import { actionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    row: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            '&:last-child': {
                marginLeft: theme.spacing(1)
            },
            '&:not(:last-child)': {
                marginRight: theme.spacing(1)
            }
        }
    },
    ...actionsStyle(theme)
});

class UpdateProductForm extends Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        blobService: PropTypes.object.isRequired,
        brandService: PropTypes.object.isRequired,
        stockStatuses: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.getBrandSuggestions = this.getBrandSuggestions.bind(this);
        this.onAddTag = this.onAddTag.bind(this);
        this.onDeleteTag = this.onDeleteTag.bind(this);
        this.onAddCategory = this.onAddCategory.bind(this);
        this.onDeleteCategory = this.onDeleteCategory.bind(this);
        this.onAddAttribute = this.onAddAttribute.bind(this);
        this.onDeleteAttribute = this.onDeleteAttribute.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        const { name,
            description,
            brand,
            sku,
            partNumber,
            heroImageName,
            heroImageUrl,
            cost,
            price,
            stockLevel,
            stockStatus,
            categories,
            attributes,
            tags
        } = props.product;

        const { id: stockStatusId } = stockStatus;

        this.state = {
            name,
            description,
            brand,
            partNumber,
            heroImageName,
            heroImageUrl,
            sku,
            cost,
            price,
            stockLevel,
            stockStatusId,
            categories,
            attributes,
            tags,
            errors: {},
            busy: false
        }
    }

    onAutocompleteSelect = name => option => {
        this.setState({
            [name]: option != null ? option.value : null
        });
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onImageChange = name => async file => {
        if (file != null) {
            const { blobName, blobUrl } = await this.props.blobService.upload('images', file);

            this.setState({
                [name + 'ImageName']: blobName,
                [name + 'ImageUrl']: blobUrl
            });
        }
        else {
            this.setState({
                [name + 'ImageName']: null,
                [name + 'ImageUrl']: null
            });
        }
    }

    onAddTag(tag) {
        this.setState(prevState => ({
            tags: prevState.tags.concat(tag)
        }));
    };

    onDeleteTag(tag) {
        this.setState(prevState => {
            const tags = prevState.tags.slice();
            const index = tags.indexOf(tag);

            tags.splice(index, 1);

            return { tags };
        });
    };

    onAddCategory(category) {
        this.setState(prevState => ({
            categories: prevState.categories.concat(category)
        }));
    };

    onDeleteCategory(category) {
        this.setState(prevState => {
            const categories = prevState.categories.slice();
            const index = categories.indexOf(category);

            categories.splice(index, 1);

            return { categories };
        });
    };

    onAddAttribute(attribute) {
        this.setState(prevState => ({
            attributes: prevState.attributes.concat(attribute)
        }));
    };

    onDeleteAttribute(attribute) {
        this.setState(prevState => {
            const attributes = prevState.attributes.slice();
            const index = attributes.indexOf(attribute);

            attributes.splice(index, 1);

            return { attributes };
        });
    };

    async getBrandSuggestions(value) {
        const response = await this.props.brandService.getSuggestions(value);

        if (response.ok) {
            return response.data.map(s => ({
                label: s.name,
                value: s.name // We save brand name directly on product (there's no FK to Brand).
            }));
        }

        return [];
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;

        const {
            name,
            description,
            brand,
            sku,
            partNumber,
            heroImageName,
            cost,
            price,
            stockLevel,
            stockStatusId,
            categories,
            attributes,
            tags
        } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                name,
                description,
                brand,
                sku,
                partNumber,
                heroImageName,
                cost: valueOrDefault(cost),
                price: valueOrDefault(price),
                stockLevel,
                stockStatusId,
                categories,
                attributes,
                tags
            });

            this.setState({ errors: {} });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({ errors });
            } else {
                throw error;
            }
        }
        finally {
            this.setState({ busy: false });
        }
    }

    render() {
        const { classes, product, stockStatuses } = this.props;
        const { isExternal: readOnly } = product;

        const { name,
            description,
            brand,
            sku,
            partNumber,
            heroImageUrl,
            cost,
            price,
            stockLevel,
            stockStatusId,
            categories,
            attributes,
            tags,
            errors,
            busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            {readOnly && <Typography variant="caption" color="error" gutterBottom>WARNING: This product was imported from an external source and should not be modified here.</Typography>}
            <div className={classes.row}>
                <div className={classes.column}>
                    <TextField
                        id="name"
                        label="Name"
                        required
                        margin="normal"
                        value={name}
                        helperText={errors.name}
                        error={Boolean(errors.name)}
                        onChange={this.onChange('name')} />

                    <TextField
                        id="description"
                        label="Description"
                        margin="normal"
                        multiline
                        rows={5}
                        value={description}
                        helperText={errors.description}
                        error={Boolean(errors.description)}
                        onChange={this.onChange('description')} />

                    <Autocomplete
                        label="Brand"
                        margin="normal"
                        placeholder="Search for a brand..."
                        initialValue={brand}
                        helperText={errors.brandId}
                        error={Boolean(errors.brandId)}
                        getSuggestions={this.getBrandSuggestions}
                        onChange={value => this.setState({ brand: value })}
                        onSelect={this.onAutocompleteSelect('brandId')} />

                    <Tags id="categories"
                        label="Categories"
                        placeholder="Add a new category"
                        tags={categories}
                        hyphenate={false}
                        margin="normal"
                        onAdd={this.onAddCategory}
                        onDelete={this.onDeleteCategory} />

                    <Tags id="attributes"
                        label="Attributes"
                        placeholder="Add a new attribute"
                        tags={attributes}
                        hyphenate={false}
                        margin="normal"
                        onAdd={this.onAddAttribute}
                        onDelete={this.onDeleteAttribute} />

                    <Tags id="tags"
                        tags={tags}
                        margin="normal"
                        onAdd={this.onAddTag}
                        onDelete={this.onDeleteTag} />
                </div>
                <div className={classes.column}>
                    <TextField
                        label="SKU"
                        margin="normal"
                        value={sku}
                        helperText={errors.sku}
                        error={Boolean(errors.sku)}
                        onChange={this.onChange('sku')} />

                    <TextField
                        label="Part number"
                        margin="normal"
                        value={partNumber}
                        helperText={errors.partNumber}
                        error={Boolean(errors.partNumber)}
                        onChange={this.onChange('partNumber')} />

                    <TextField
                        label="Cost"
                        margin="normal"
                        type="number"
                        value={cost || ''}
                        helperText={errors.cost}
                        error={Boolean(errors.cost)}
                        onChange={this.onChange('cost')} />

                    <TextField
                        label="Price"
                        margin="normal"
                        type="number"
                        value={price || ''}
                        helperText={errors.price}
                        error={Boolean(errors.price)}
                        onChange={this.onChange('price')} />

                    <TextField
                        label="Stock level"
                        required
                        margin="normal"
                        type="number"
                        value={stockLevel}
                        helperText={errors.stockLevel}
                        error={Boolean(errors.stockLevel)}
                        onChange={this.onChange('stockLevel')} />

                    <TextField
                        label="Stock status"
                        select
                        required
                        margin="normal"
                        value={stockStatusId}
                        onChange={this.onChange('stockStatusId')}>
                        {stockStatuses.map(ss => <MenuItem key={ss.id} value={ss.id}>{ss.name}</MenuItem>)}
                    </TextField>

                    <ImageSelect id="heroFile"
                        label="Hero image"
                        height={250}
                        margin="normal"
                        src={heroImageUrl}
                        onChange={this.onImageChange('hero')} />
                </div>
            </div>

            <div className={classes.actions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
            </div>
        </form>;
    }
}

export default withStyles(styles)(UpdateProductForm);