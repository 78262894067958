import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import withStyles from '@material-ui/core/styles/withStyles';
import Pagination from '../../components/Pagination';
import RetailersContainer from '../../containers/RetailersContainer';
import { Subscribe } from 'unstated';
import { actionsColumnStyle, tableRowStyle } from '../../styles';

const styles = theme => ({
    root: {
        width: '100%'
    },
    center: {
        textAlign: 'center'
    },
    ...tableRowStyle(theme),
    ...actionsColumnStyle(theme)
});

class RetailerTable extends Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
    }

    onDelete(event, retailer) {
        event.stopPropagation();
        this.props.onDelete(retailer);
    }

    render() {
        const { classes, onSelect } = this.props;

        return <Paper className={classes.root}>
            <Subscribe to={[RetailersContainer]}>
                {rc => <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell className={classes.center}>Searchable</TableCell>
                            <TableCell className={classes.actionsColumn} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rc.state.list.map(r =>
                            <TableRow key={r.id} className={classes.tableRow} hover onClick={() => onSelect(r)}>
                                <TableCell>{r.name}</TableCell>
                                <TableCell>{r.description}</TableCell>
                                <TableCell>{r.retailerType.name}</TableCell>
                                <TableCell className={classes.center}>
                                    {r.isVisibleToPublic && <DoneIcon />}
                                </TableCell>
                                <TableCell className={classes.actionsColumn}>
                                    <IconButton onClick={e => this.onDelete(e, r)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <Pagination colSpan={5}
                                pageNumber={rc.state.pageNumber}
                                pageSize={rc.state.pageSize}
                                totalCount={rc.state.totalCount}
                                onChange={({ pageNumber, pageSize }) => rc.loadPage(pageNumber, pageSize)} />
                        </TableRow>
                    </TableFooter>
                </Table>}
            </Subscribe>
        </Paper>;
    }
}

export default withStyles(styles)(RetailerTable);