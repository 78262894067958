import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import ProductContainer from '../../containers/ProductContainer';
import LookupsContainer from '../../containers/LookupsContainer';
import UpdateProductForm from './UpdateProductForm';
import AddImageForm from './AddImageForm';
import UpdateImageForm from './UpdateImageForm';
import CreateVariantForm from './CreateVariantForm';
import VariantTable from './VariantTable';
import VariantList from './VariantList';
import SideDrawer from '../../components/SideDrawer';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImageGrid from '../../components/ImageGrid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import AddIcon from '@material-ui/icons/Add';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { breadcrumbStyle, fabStyle, pageTitleStyle, tabsStyle } from '../../styles';
import { withRouter } from 'react-router-dom';
import { paths } from '../../App';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 0
    },
    detailsTab: {
        padding: theme.spacing(2)
    },
    imagesTab: {
        padding: theme.spacing(2),
        flexGrow: 1,
        position: 'relative'
    },
    variantsTab: {
        flexGrow: 1,
        position: 'relative'
    },
    ...breadcrumbStyle(theme),
    ...fabStyle(theme, theme.spacing(2)),
    ...pageTitleStyle(theme),
    ...tabsStyle(theme)
});

class Product extends Component {
    static propTypes = {
        productId: PropTypes.string.isRequired,
        blobService: PropTypes.object.isRequired,
        productService: PropTypes.object.isRequired,
        brandService: PropTypes.object.isRequired,
        stockStatusService: PropTypes.object.isRequired,
        tab: PropTypes.string
    };

    static defaultProps = {
        tab: 'details'
    };

    tabs = {
        'details': 'Details',
        'images': 'Images',
        'variants': 'Variants'
    };

    constructor(props) {
        super(props);

        const notFound = Object.keys(this.tabs).indexOf(props.tab) < 0;

        this.productContainer = new ProductContainer(props.productService);
        this.lookupsContainer = new LookupsContainer(props);

        this.state = {
            drawerTitle: '',
            drawerContent: null,
            notification: null,
            notFound,
            ready: false
        };

        this.onTabChange = this.onTabChange.bind(this);
        this.openAddImageDrawer = this.openAddImageDrawer.bind(this);
        this.openCreateVariantDrawer = this.openCreateVariantDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.hideNotification = this.hideNotification.bind(this);
    }

    async componentDidMount() {
        const { productId } = this.props;

        await Promise.all([
            this.productContainer.load(productId),
            this.lookupsContainer.loadStockStatuses()
        ]);

        this.setState({ ready: true });
    }

    async updateProduct(form) {
        const ok = await this.productContainer.update(form);

        if (ok) {
            this.showNotification('Product saved');
        } else {
            this.showNotification('Unable to save! Please try again.');
        }
    }

    addImage(form) {
        return this.productContainer.addImage(form);
    }

    async updateImage(imageId, form) {
        const ok = await this.productContainer.updateImage(imageId, form);

        if (ok) {
            this.closeDrawer();
        }
    }

    async removeImage(image) {
        const confirmed = window.confirm(`Remove image ${image.imageName}?`);

        if (confirmed) {
            await this.productContainer.removeImage(image.id);
        }
    }

    async createVariant(form) {
        const created = await this.productContainer.createVariant(form);

        if (created) {
            this.closeDrawer();
        }
    }

    async deleteVariant(variant) {
        const confirmed = window.confirm(`Delete variant ${variant.name} ${variant.value}?`);

        if (confirmed) {
            await this.productContainer.deleteVariant(variant.id);
        }
    }

    openAddImageDrawer() {
        const drawerContent = <AddImageForm
            blobService={this.props.blobService}
            onSave={form => this.addImage(form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Add image', drawerContent);
    }

    openUpdateImageDrawer(image) {
        const drawerContent = <UpdateImageForm
            image={image}
            blobService={this.props.blobService}
            onSave={form => this.updateImage(image.id, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit image', drawerContent);
    }

    openCreateVariantDrawer() {
        const { product } = this.productContainer.state;
        const { attributes } = product;

        const drawerContent = <Subscribe to={[this.lookupsContainer]}>
            {lookups => <CreateVariantForm
                attributes={attributes}
                stockStatuses={lookups.state.stockStatuses}
                onSave={form => this.createVariant(form)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.openDrawer('Create variant', drawerContent);
    }

    onSelectVariant(variant) {
        const { product } = this.productContainer.state;
        this.props.history.push(`/products/${product.id}/variants/${variant.id}`);
    }

    onTabChange(_, value) {
        this.props.history.push(`/products/${this.props.productId}/${value}`);
    }

    showNotification(notification) {
        this.setState({ notification });
    }

    hideNotification() {
        this.setState({ notification: null });
    }

    openDrawer(drawerTitle, drawerContent) {
        this.setState({
            drawerTitle,
            drawerContent
        });
    }

    closeDrawer() {
        this.setState({
            drawerTitle: '',
            drawerContent: null
        });
    }

    renderTabContents(tab) {
        switch (tab) {
            case 'details':
                return this.renderDetailsTab();
            case 'images':
                return this.renderImagesTab();
            case 'variants':
                return this.renderVariantsTab();
            default:
                return "No content";
        }
    }

    renderDetailsTab() {
        const { classes, brandService, blobService } = this.props;

        return <div className={classes.detailsTab}>
            <Subscribe to={[this.lookupsContainer]}>
                {lookups =>
                    <UpdateProductForm
                        product={this.productContainer.state.product}
                        brandService={brandService}
                        blobService={blobService}
                        stockStatuses={lookups.state.stockStatuses}
                        onSave={form => this.updateProduct(form)} />}
            </Subscribe>
        </div>;
    }

    renderImagesTab() {
        const { classes } = this.props;

        return <div className={`${classes.imagesTab} ${classes.fabContainer}`}>
            <ImageGrid
                images={this.productContainer.state.images}
                onSelect={img => this.openUpdateImageDrawer(img)}
                onDelete={img => this.removeImage(img)} />
            {this.state.ready && <Fab color="primary" className={classes.fab} onClick={this.openAddImageDrawer}>
                <AddIcon />
            </Fab>}
        </div>;
    }

    renderVariantsTab() {
        const { classes } = this.props;

        return <div className={classes.variantsTab}>
            <Hidden smUp implementation="css">
                <VariantList variants={this.productContainer.state.variants}
                    onSelect={v => this.onSelectVariant(v)}
                    onDelete={v => this.deleteVariant(v)} />
            </Hidden>
            <Hidden xsDown implementation="css">
                <VariantTable product={this.productContainer.state.product}
                    variants={this.productContainer.state.variants}
                    onSelect={v => this.onSelectVariant(v)}
                    onDelete={v => this.deleteVariant(v)} />
            </Hidden>
            {this.state.ready && this.productContainer.state.product.attributes.length > 0 &&
                <Fab color="primary" className={classes.fab} onClick={this.openCreateVariantDrawer}>
                    <AddIcon />
                </Fab>}
        </div>;
    }

    render() {
        const { classes, history, width, tab } = this.props;
        const { drawerTitle, drawerContent, notification, notFound } = this.state;
        const isDesktop = isWidthUp('lg', width);

        return <div className={classes.root}>
            {!notFound && <Fragment>
                <Subscribe to={[this.productContainer]}>
                    {pc => pc.state.product && <Fragment>
                        <Typography className={classes.pageTitle} gutterBottom variant="h4">{pc.state.product.name}</Typography>
                        <div className={classes.breadcrumbs}>
                            <Button size="small" onClick={() => history.push(paths.PRODUCTS)}>Products</Button> >
                        <span className={classes.breadcrumb}>{pc.state.product.name}</span>
                        </div>
                        <Paper className={classes.tabs}>
                            <AppBar color="secondary" position="static">
                                <Tabs indicatorColor="primary"
                                    centered={isDesktop}
                                    variant={isDesktop ? 'fullWidth' : 'scrollable'}
                                    value={tab}
                                    onChange={this.onTabChange}>
                                    {Object.keys(this.tabs).map(slug =>
                                        <Tab key={slug} label={this.tabs[slug]} value={slug} />)}
                                </Tabs>
                            </AppBar>
                            {this.renderTabContents(tab)}
                        </Paper>
                    </Fragment>}
                </Subscribe>
                <SideDrawer title={drawerTitle}
                    anchor="right"
                    open={Boolean(drawerContent)}
                    onClose={this.closeDrawer}>
                    {drawerContent}
                </SideDrawer>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                    open={Boolean(notification)}
                    autoHideDuration={6000}
                    message={notification}
                    onClose={this.hideNotification} />
            </Fragment>}
            {notFound && "Not found"}
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(Product)));