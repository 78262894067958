import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import BusyButton from '../../components/BusyButton';
import { withStyles } from '@material-ui/core';
import { readErrors } from '../../helpers';
import { drawerActionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    selectListItem: {
        padding: theme.spacing(2)
    },
    ...drawerActionsStyle(theme)
});

class UpdateFeatureForm extends Component {
    static propTypes = {
        storeFeature: PropTypes.object.isRequired,
        storeFeatureTypes: PropTypes.array.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        const { storeFeatureType, notes } = props.storeFeature;
        const { id: storeFeatureTypeId } = storeFeatureType;

        this.state = {
            storeFeatureTypeId,
            notes,
            errors: {},
            busy: false
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { storeFeatureTypeId, notes } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                storeFeatureTypeId,
                notes
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, storeFeatureTypes, onCancel } = this.props;
        const { storeFeatureTypeId, notes, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <TextField
                autoFocus
                id="storeFeatureTypeId"
                label="Feature type"
                select
                required
                margin="normal"
                value={storeFeatureTypeId}
                onChange={this.onChange('storeFeatureTypeId')}>
                {storeFeatureTypes.map(sft => <MenuItem  className={classes.selectListItem} key={sft.id} value={sft.id}>
                    <ListItemText primary={sft.name} secondary={sft.description} />
                </MenuItem>)}
            </TextField>

            <TextField
                id="notes"
                label="Notes"
                margin="normal"
                multiline
                rows={5}
                value={notes}
                helperText={errors.notes}
                error={Boolean(errors.notes)}
                onChange={this.onChange('notes')} />

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(UpdateFeatureForm);