import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ShoppingLocationsContainer from '../../containers/ShoppingLocationsContainer';
import LookupsContainer from '../../containers/LookupsContainer';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import ShoppingLocationList from './ShoppingLocationList';
import ShoppingLocationTable from './ShoppingLocationTable';
import SideDrawer from '../../components/SideDrawer';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CreateShoppingLocationForm from './CreateShoppingLocationForm';
import { Provider, Subscribe } from 'unstated';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { fabStyle, pageTitleStyle, searchBoxStyle } from '../../styles';
import Paper from '@material-ui/core/Paper';
import SearchInput from '../../components/SearchInput';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    title: {
        flex: 1.5
    },
    ...searchBoxStyle(theme),
    ...pageTitleStyle(theme),
    ...fabStyle(theme)
});

class ShoppingLocations extends Component {
    static propTypes = {
        query: PropTypes.string,
        shoppingLocationService: PropTypes.object.isRequired,
        shoppingLocationTypeService: PropTypes.object.isRequired,
        blobService: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.shoppingLocationsContainer = new ShoppingLocationsContainer(props.shoppingLocationService);
        this.lookupsContainer = new LookupsContainer(props);

        this.onSelectShoppingLocation = this.onSelectShoppingLocation.bind(this);
        this.openCreateDrawer = this.openCreateDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    state = {
        drawerContent: null,
        ready: false
    };

    async componentDidMount() {
        const { query } = this.props;

        await this.shoppingLocationsContainer.setState({ query });

        await Promise.all([
            this.shoppingLocationsContainer.load(),
            this.lookupsContainer.loadShoppingLocationTypes()
        ]);

        this.setState({ ready: true });
    }

    async onSelectShoppingLocation(shoppingLocation) {
        this.props.history.push(`/shopping-locations/${shoppingLocation.id}`);
    }

    async createShoppingLocation(form, reload) {
        const { ok } = await this.shoppingLocationsContainer.create(form, reload);

        if (ok) {
            this.closeDrawer();
        }
    }

    async deleteShoppingLocation(shoppingLocation) {
        const confirmed = window.confirm(`Delete shopping location ${shoppingLocation.name}?`);

        if (confirmed) {
            await this.shoppingLocationsContainer.delete(shoppingLocation.id);
        }
    }

    openCreateDrawer() {
        const reloadAfterCreate = isWidthUp('sm', this.props.width);

        const drawerContent = <Subscribe to={[LookupsContainer]}>
            {lookups => <CreateShoppingLocationForm
                blobService={this.props.blobService}
                shoppingLocationService={this.props.shoppingLocationService}
                shoppingLocationTypes={lookups.state.shoppingLocationTypes}
                onSave={form => this.createShoppingLocation(form, reloadAfterCreate)}
                onCancel={this.closeDrawer} />}
        </Subscribe>;

        this.setState({ drawerContent });
    }

    closeDrawer() {
        this.setState({ drawerContent: null });
    }

    render() {
        const { classes, query } = this.props;
        const { drawerContent, ready } = this.state;

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classNames(classes.pageTitle, classes.title)} variant="h4">Shopping locations</Typography>
                <Paper className={classes.searchBox}>
                    <SearchInput
                        value={query}
                        onSearch={query => this.props.history.push(`/shopping-locations?query=${query}`)}
                        onClear={() => this.props.history.push('/shopping-locations')} />
                </Paper>
            </div>
            <Provider inject={[this.shoppingLocationsContainer, this.lookupsContainer]}>
                <Hidden smUp implementation="css">
                    <ShoppingLocationList onSelect={this.onSelectShoppingLocation}
                        onDelete={sl => this.deleteShoppingLocation(sl)} />
                </Hidden>
                <Hidden xsDown implementation="css">
                    <ShoppingLocationTable onSelect={this.onSelectShoppingLocation}
                        onDelete={sl => this.deleteShoppingLocation(sl)} />
                </Hidden>
                <SideDrawer title="Create shopping location"
                    anchor="right"
                    open={Boolean(drawerContent)}
                    onClose={this.closeDrawer}>
                    {drawerContent}
                </SideDrawer>
                {ready && <Fab color="primary" className={classes.fab} onClick={this.openCreateDrawer}>
                    <AddIcon />
                </Fab>}
            </Provider>
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(ShoppingLocations)));